import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export function useAssets() {
	const { items, archivedItems, archivationLoading, loading, assetLoading, commentsLoading } = useSelector(
		(store: RootState) => store.assets,
	);

	return {
		items,
		archivedItems,
		loading,
		commentsLoading,
		assetLoading,
		archivationLoading,
	};
}
