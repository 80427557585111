import { FC, useEffect, useState } from 'react';
import styles from './ChangesHistoryBlock.module.scss';
import classNames from 'classnames';
import IChangesHistoryBlock from './IChangesHistoryBlock';
import { ColoredTag } from '../../primitives';
import {
	EntityTypes,
	IChangeHistoryEntry,
	IHistoryGroupedByDateTime,
	IIdName,
} from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../utils/helpers/constants/app-routes';
import {
	getEntityTypeName,
	groupByUniqueEntity,
	groupHistoryEntriesByDatetime,
	renderHistoryTextByType,
	sortByDateDesc,
} from '../../../utils/helpers/common';
import parse from 'html-react-parser';
import { useCompany } from '../../../utils/helpers/hooks/useCompany';
import { AppQueryParams } from '../../../utils/helpers/constants';

const ChangesHistoryBlock: FC<IChangesHistoryBlock> = ({ className, changes, entitiesMode }) => {
	const { info: companyInfo } = useCompany();
	const navigate = useNavigate();
	const [historyToDisplay, setHistoryToDisplay] = useState<IHistoryGroupedByDateTime>();

	const getEntityTitleTag = (entity: IIdName, type: string) => {
		if (!entity) return null;

		switch (type) {
			case EntityTypes.CompanyControl:
				return (
					<ColoredTag
						className={styles['title-tag']}
						tagId={entity.id}
						text={entity.displayId || ''}
						withoutBackground
						asLink
						onClickHandler={() =>
							navigate(`${AppUrls.controls}?${AppQueryParams.item}=${entity.id}`)
						}
					/>
				);
			case EntityTypes.CompanyPolicy:
				return (
					<ColoredTag
						className={styles['title-tag']}
						tagId={entity.id}
						text={entity.name}
						withoutBackground
						asLink
						onClickHandler={() =>
							navigate(`${AppUrls.policies}?${AppQueryParams.item}=${entity.id}`)
						}
					/>
				);
			case EntityTypes.Asset:
				return (
					<ColoredTag
						className={styles['title-tag']}
						tagId={entity.id}
						text={entity.name}
						withoutBackground
						asLink
						onClickHandler={() =>
							navigate(`${AppUrls.assets}?${AppQueryParams.item}=${entity.id}`)
						}
					/>
				);
		}
	};

	const renderChangesText = (changeEntry: IChangeHistoryEntry) => {
		const { text, variables } = changeEntry.action || {};
		let newText = text;

		const getChangeText = () => {
			variables.forEach((variable) => {
				newText = renderHistoryTextByType(newText, variable, styles.highlighted);
			});

			return parse(newText);
		};

		return <div className={styles['change-text']}>{getChangeText()}</div>;
	};

	const renderGroupedHistory = () => {
		const getResetTitle = (changeEntry: IChangeHistoryEntry) => {
			const { text } = changeEntry.action || {};

			if (text.includes('reset')) return <span className={styles.type}>Company Reset</span>;
		};

		return (
			historyToDisplay &&
			Object.entries(historyToDisplay).map(([date, entries]) => {
				const groupedByEntities = groupByUniqueEntity(entries);

				return Object.entries(groupedByEntities).map(([key, historyRecords]) => {
					return (
						<div key={historyRecords[0].id} className={styles['change-history-group']}>
							<div className={styles['group-title']}>
								<div className={styles.entity}>
									{historyRecords[0].entityType &&
										historyRecords[0].entity?.id ? (
										<>
											<span className={styles.type}>
												{getEntityTypeName(historyRecords[0].entityType)}
											</span>

											{getEntityTitleTag(
												historyRecords[0].entity,
												historyRecords[0].entityType,
											)}
										</>
									) : (
										getResetTitle(historyRecords[0])
									)}
								</div>

								<div className={styles.date}>{date}</div>
							</div>

							{historyRecords.map((entry) => (
								<div
									key={entry.id}
									className={styles['change-history-item']}
								>
									<div
										className={classNames(
											styles.text,
											entitiesMode ? styles['with-entities'] : '',
										)}
									>
										{renderChangesText(entry)}
									</div>
								</div>
							))}
						</div>
					);
				});
			})
		);
	};

	const renderRegularHistory = () => {
		return (
			historyToDisplay &&
			Object.entries(historyToDisplay).map(([date, entries]) => {
				return (
					<div key={entries[0].id} className={styles['change-history-group']}>
						<div className={styles['group-title']}>
							<div className={classNames(styles.date, styles['in-regular'])}>
								{date}
							</div>
						</div>

						{entries.map((entry) => (
							<div
								key={entry.id}
								className={styles['change-history-item']}
							>
								<div className={styles.text}>{renderChangesText(entry)}</div>
							</div>
						))}
					</div>
				);
			})
		);
	};

	const renderHistory = () => {
		if (entitiesMode) return renderGroupedHistory();
		return renderRegularHistory();
	};

	useEffect(() => {
		if (changes?.length) {
			const sorted = sortByDateDesc(changes, 'createdAt');

			const groupedHistory = groupHistoryEntriesByDatetime(
				sorted,
				companyInfo?.locationTimeZone,
			);
			setHistoryToDisplay(groupedHistory || []);
		}
	}, [changes, companyInfo?.locationTimeZone]);

	return (
		<div className={classNames(styles['change-history-block'], className)}>
			{changes?.length ? (
				renderHistory()
			) : (
				<p className={styles.empty}>No changes to display</p>
			)}
		</div>
	);
};

export default ChangesHistoryBlock;
