import { FC } from 'react';
import styles from './Frameworks.module.scss';
import { LoadingSkeleton } from '../../components';

const FrameworksSkeleton: FC = () => {
	return (
		<div className={styles['frameworks-skeleton']}>
			<LoadingSkeleton className={styles.framework} height={252} />
			<LoadingSkeleton className={styles.framework} height={252} />
			<LoadingSkeleton className={styles.framework} height={252} />
			<LoadingSkeleton className={styles.framework} height={252} />
		</div>
	);
};

export default FrameworksSkeleton;
