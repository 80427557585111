import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import {
	getOwnerOptions,
	getTagOptions,
	processTableData,
	sortControlsByMissedData,
} from '../../../../utils/helpers/common';
import styles from './ControlsTable.module.scss';
import { getControlColumns } from './utils';
import EditControl from '../../edit-panels/control/EditControl';
import {
	usePagination,
	useUser,
	useDevice,
	useTags,
	useCompanyUsers
} from '../../../../utils/helpers/hooks';
import { IControl } from '../../../../services/store/slices/controls.slice';
import { Alert } from '../../../';
import {
	BasicPanel,
	HoverTooltip,
	OwnerFilter,
	SearchFilter,
	StatusFilter,
	Table,
	TableFilters,
	TableSkeleton,
	TagsFilter,
} from '../../../primitives';
import classNames from 'classnames';
import { AppQueryParams, ControlStatusOptions } from '../../../../utils/helpers/constants';
import IPartialTable from '../common/IPartialTable';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';

const ControlsTable: FC<IPartialTable<IControl>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();

	const { items: companyUsers } = useCompanyUsers();
	const { items: tagItems } = useTags();
	const { info: userInfo, isAdmin } = useUser();

	const [processedData, setProcessedData] = useState(data);
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentPageData, setCurrentPageData] = useState<IControl[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [controlUpdateResult, setControlUpdateResult] = useState<CommonResultStatusType>('');
	const [updatedControlId, setUpdatedControlId] = useState<string | null>(null);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IControl) => true,
		statusFilter: (dataRecord: IControl) => true,
		ownerFilter: (dataRecord: IControl) => true,
		tagsFilter: (dataRecord: IControl) => true,
	});

	const tableColumnsConfig = useMemo(
		() => getControlColumns(device, tagItems || []),
		[device, tagItems],
	);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => {
			navigate(`${AppUrls.controls}?${AppQueryParams.item}=${id}`);
		},
		[navigate],
	);

	const onControlUpdateSuccess = () => {
		setUpdatedControlId(showItemId || '');
		setControlUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.controls}`);
	};
	const onControlUpdateError = () => setControlUpdateResult('error');

	const alerts = (
		<>
			<Alert
				uniqueKey={'control-success'}
				show={controlUpdateResult === 'success'}
				type="success"
				message="Control updated!"
				clearActionStatus={() => setControlUpdateResult('')}
			/>
			<Alert
				uniqueKey={'control-error'}
				show={controlUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating control"
				clearActionStatus={() => setControlUpdateResult('')}
			/>
		</>
	);

	const editDrawer = (
		<EditControl
			controlId={showItemId || ''}
			onUpdateSuccess={onControlUpdateSuccess}
			onUpdateError={onControlUpdateError}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(`${AppUrls.controls}`);
			}}
			open={openDrawer}
		/>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					placeholder="Search control..."
					setFilters={setCurrentFilters}
					properties={['name', 'controlId']}
				/>

				<StatusFilter options={ControlStatusOptions} setFilters={setCurrentFilters} />

				{tagItems?.length ? (
					<TagsFilter setFilters={setCurrentFilters} options={getTagOptions(tagItems)} />
				) : null}

				{isAdmin && (
					<OwnerFilter
						options={getOwnerOptions(companyUsers, userInfo?.id)}
						setFilters={setCurrentFilters}
					/>
				)}
			</Fragment>
		);

		return <TableFilters disabled={dataLoading || !data.length} leftSection={leftSection} />;
	}, [tagItems, isAdmin, companyUsers, userInfo?.id, dataLoading, data.length]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="controls"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					onSortChange,
					onRowClickHandler,
					successHighlightedRows: [
						data?.find((control) => control.id === updatedControlId)?.id || '',
					],
					emptyResultMessage:
						!dataLoading && !data.length
							? `No controls to display yet.${isAdmin ? '' : " You will see the controls once they're assigned to you."}`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data,
			dataLoading,
			isAdmin,
			onRowClickHandler,
			onSortChange,
			tableColumnsConfig,
			updatedControlId,
		],
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const processedData: IControl[] = processTableData(data, currentFilters, currentSort);

		let dataToDisplay = [...processedData];

		if (!currentSort.property || !currentSort.direction)
			dataToDisplay = sortControlsByMissedData(processedData);

		setProcessedData(dataToDisplay);

		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (updatedControlId) {
			goToPageByDataEntryProperty(updatedControlId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedControlId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [goToPageByDataEntryProperty, updatedControlId]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={8} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{editDrawer}

			{alerts}

			<HoverTooltip tooltipId="tooltip-alert-info" place="bottom-start" />
		</>
	);
};

export default ControlsTable;
