import { Link } from "react-router-dom";
import iconNavigationAccent from '../../assets/images/icon-navigation-accent.svg';
import iconLink from '../../assets/images/icon-link.svg';
import iconLock from '../../assets/images/icon-lock.svg';
import { getPublicResourcePath, ITrustHub, ITrustHubResource } from "../../services/store/slices/trust-hub.slice";
import { useCallback } from "react";
import { useTrustHubToken } from "../../utils/helpers/hooks/useTrustHubToken";
import { useAppDispatch } from "../../services/store";
import { handleWithTryCatch } from "../../utils/helpers/errors";

export interface OverviewResourcesProps {
  showSeeAll: boolean;
  trustHub: ITrustHub | null;
  setCustomActiveTab: (tab: string) => void;
  setShareModalOpen: (isOpen: boolean) => void;
  setTokenExpiredModalOpen: (isOpen: boolean) => void;
  setTokenExpiredResource: (resource: ITrustHubResource | null) => void;
}

export const OverviewResources = ({ showSeeAll, trustHub, setShareModalOpen, setCustomActiveTab, setTokenExpiredResource, setTokenExpiredModalOpen }: OverviewResourcesProps) => {
  const dispatch = useAppDispatch();
  const { token, parsedToken } = useTrustHubToken();

  const onView = useCallback(async (resource: ITrustHubResource) => {
    handleWithTryCatch(
      async () => {
        const pathResponse = await dispatch(getPublicResourcePath(resource.id, token || ''));
        if (pathResponse.path) {
          window.open(pathResponse.path, '_blank');
        }
      },
      (message) => {
        if (message === 'Invalid token' || 'Token is expired') {
          setTokenExpiredModalOpen(true);
          setTokenExpiredResource(resource);
        }
      },
    );
  }, [dispatch, setTokenExpiredModalOpen, setTokenExpiredResource, token]);

  return (
    <div className="p-6 bg-white rounded-2xl border border-levander-75">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-semibold">Resources</h3>
        {showSeeAll && (
          <Link
            to="#"
            className="font-semibold text-levander-300 text-xs flex gap-2 items-center"
            onClick={() => {
              setCustomActiveTab('resources');
              setTimeout(() => setCustomActiveTab(''), 100);
            }}
          >
            See all resources
            <img
              className="w-[12px] h-[12px]"
              src={iconNavigationAccent}
              alt="Navigation icon"
            />
          </Link>
        )}
      </div>
      <ul className="divide-y divide-blueberry-50">
        {trustHub?.resources?.map((resource) => (
          <li key={resource.id} className="flex gap-4 justify-between py-3 text-blueberry-200 text-xs">
            <span className="inline-flex gap-2 items-center">
              <img src={iconLink}
                alt="Link icon"
              />
              {resource.name}
            </span>
            <a
              href="#qq"
              onClick={() => {
                if (parsedToken?.trustHubAccessRequestDto?.includes(resource.id)) {
                  onView(resource);
                  return;
                }
                setShareModalOpen(true);
              }}
              className="font-semibold text-levander-300 flex gap-1 items-center cursor-pointer"
            >
              {
                parsedToken?.trustHubAccessRequestDto?.includes(resource.id) ?
                  'View' : (
                    <>
                      Request access
                      <img
                        src={iconLock}
                        alt="Lock icon"
                      />
                    </>
                  )
              }
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}