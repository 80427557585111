import React, { FC, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import { useScrollableSetting } from '../../../utils/helpers/hooks';
import { IModal } from './IModal';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { AnimatedScreenWrapper } from '../../../utils/helpers/hocs/transition/AnimatedScreenWrapper';

export const Modal: FC<IModal> = ({ children, open, setOpen }) => {
	const portalsElement = useRef(document.getElementById('portals'));

	useScrollableSetting(open, document.body);

	const onEscapeClickHandler = React.useCallback(
		(event: KeyboardEvent) => {
			if (event.key && event.key.toLocaleLowerCase() === 'escape') {
				event.preventDefault();

				setOpen(false);
			}
		},
		[setOpen],
	);

	useEffect(() => {
		if (document && open) {
			document.addEventListener('keydown', onEscapeClickHandler);
		}

		return () => document.removeEventListener('keydown', onEscapeClickHandler);
	}, [open, onEscapeClickHandler]);

	return (
		<>
			{portalsElement.current &&
				createPortal(
					<AnimatePresence mode="wait">
						{open && (
							<AnimatedScreenWrapper outTransitionDuration={0.2}>
								<div
									className={classNames(
										styles.modal,
										open ? styles.open : styles.close,
									)}
								>
									<div className={styles['modal-content']}>{children}</div>
								</div>
							</AnimatedScreenWrapper>
						)}
					</AnimatePresence>,
					portalsElement.current,
				)}
		</>
	);
};
