import React, { FC } from 'react';
import styles from './Pagination.module.scss';
import { IPagination } from './IPagination';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

export const Pagination: FC<IPagination> = ({
	className,
	pageCount,
	currentPage,
	currentPageSize,
	initialPageSize,
	onPageChangeHandler,
	onPageSizeChangeHandler,
}) => {
	const onPageChange = (selected: { selected: number }) => {
		onPageChangeHandler(selected.selected + 1);
	};

	return (
		<div className={classNames(styles['pagination-wrap'], className)}>
			<select
				className={styles['page-size']}
				onChange={(e) => onPageSizeChangeHandler(Number(e.target.value))}
				defaultValue={currentPageSize}
			>
				{initialPageSize && <option value={initialPageSize}>{initialPageSize}</option>}
				<option value="25">25</option>
				<option value="50">50</option>
				<option value="100">100</option>
			</select>
			<ReactPaginate
				forcePage={currentPage && currentPage - 1}
				onPageChange={onPageChange}
				nextLabel={<div className={styles.next} />}
				previousLabel={<div className={styles.previous} />}
				pageCount={pageCount}
				pageClassName={styles['page-item']}
				pageLinkClassName={styles['page-link']}
				breakClassName={styles['page-item']}
				breakLinkClassName={classNames(styles['page-link'], styles.break)}
				containerClassName={styles['pagination']}
				renderOnZeroPageCount={null}
				disabledClassName={styles.disabled}
				activeLinkClassName={styles.active}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
			/>
		</div>
	);
};
