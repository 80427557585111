import { FC, useState } from 'react';
import styles from './ModalAddPolicies.module.scss';
import { Modal, BasicPanel } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import { IIdName } from '../../../../utils/types';
import { sortObjByNameAsc } from '../../../../utils/helpers/common';
import IModalAddPolicies from './IModalAddPolicies';
import { usePolicies } from '../../../../utils/helpers/hooks';
import AddPoliciesTable from '../../tables/add-policies-table/AddPoliciesTable';

const ModalAddPolicies: FC<IModalAddPolicies> = ({
	open,
	setOpen,
	onNewAdded,
	entityPolicyIds,
	setEntityPolicies,
}) => {
	const { items: policyItems } = usePolicies();

	const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
	const submitIsDisabled = !selectedPolicies.length;

	const onAdd = () => {
		const selected = selectedPolicies.map((id) => {
			return {
				id,
				name: policyItems?.find((policy) => policy.id === id)?.name,
			} as IIdName;
		});

		const newPolicies = entityPolicyIds.length
			? sortObjByNameAsc([...entityPolicyIds, ...selected])
			: selected;

		onNewAdded(selected);
		setEntityPolicies(newPolicies);
		setSelectedPolicies([]);
		setOpen(false);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button width={136} type="button" negative onClick={() => setOpen(false)}>
					Cancel
				</Button>

				<Button width={136} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add Selected
				</Button>
			</ActionsBlock>
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Policies</h2>

					<div className={styles['table-wrap']}>
						<AddPoliciesTable
							data={
								policyItems
									?.filter(
										(policy) =>
											!entityPolicyIds?.some(
												(entityPolicy) => entityPolicy.id === policy.id,
											),
									)
									.map((policy) => ({
										id: policy.id,
										name: policy.name,
									})) || []
							}
							selectedRows={selectedPolicies}
							setSelectedRows={setSelectedPolicies}
						/>
					</div>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddPolicies;
