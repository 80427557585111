import { useLocation } from 'react-router-dom';
import { TrustHubToken } from '../common/trust-hub-token';
import { AppQueryParams } from '../constants';

export const useTrustHubToken = () => {
	const location = useLocation();

	const tokenFromURL = new URLSearchParams(location.search).get(AppQueryParams.token);
	if (tokenFromURL) {
		TrustHubToken.set(tokenFromURL);
	}
	const token = TrustHubToken.get();
	const parsedToken = TrustHubToken.parse();

	return {
		token,
		parsedToken,
	};
};
