import { FC, useCallback } from 'react';
import styles from './ModalRequestIsSent.module.scss';
import { Modal, BasicPanel } from '../../../primitives';
import { Button } from '../../../primitives';
import IModalRequestIsSent from './IModalRequestIsSent';

const ModalRequestIsSent: FC<IModalRequestIsSent> = ({
	open,
	setOpen,
	companyName,
}) => {
	const onClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Request is sent</h2>

				<p className={styles.text}>
					You will receive email notification once {companyName} approves the request
				</p>

				<Button
					type="button"
					className='w-full'
					negative
					onClick={onClose}
				>
					Ok
				</Button>
			</BasicPanel>
		</Modal>
	);
};

export default ModalRequestIsSent;
