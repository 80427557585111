import { IControl } from "../../services/store/slices/controls.slice";
import { OverviewControlItemFull } from "./OverviewControlItemFull";

export interface OverviewControlGroupFullProps {
  items: IControl[];
}

export const OverviewControlGroupFull = ({
  items,
}: OverviewControlGroupFullProps) => {

  return (
    <div className="rounded-2xl">
      <div className="flex justify-between gap-4 px-7 py-3 border-b-[1px] border-blueberry-50">
        <div className='text-xs text-blueberry-200'>
          Control name
        </div>
        <div className='text-xs text-blueberry-200'>
          Status
        </div>
      </div>
      <ul>
        {items.map((item) => (
          <OverviewControlItemFull key={item.id} controlName={item.name!} control={item.trustHubDefaultStatement!} />
        ))}
      </ul>
    </div>
  )
};