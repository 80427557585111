import React, { FC } from 'react';
import styles from './ActionButton.module.scss';
import { IActionButton } from './IActionButton';
import classNames from 'classnames';

export const ActionButton: FC<IActionButton> = React.memo(
	({ type, className, onClick, tooltipId, tooltipContent }) => {
		return (
			<span
				{...(tooltipId ? { 'data-tooltip-id': tooltipId } : {})}
				{...(tooltipContent ? { 'data-tooltip-content': tooltipContent } : {})}
				className={classNames(styles['action-button'], styles[type], className)}
				onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
					e.stopPropagation();
					if (onClick) onClick();
				}}
			></span>
		);
	},
);
