import React, { FC } from 'react';
import { IControlsFilter } from './IControlsFilter';
import { ISelectOption } from '../../../../utils/types';
import { MultiSelectFilter } from '../';

export const ControlsFilter: FC<IControlsFilter> = ({
	options,
	placeholder,
	setFilters,
	propertyName,
}) => {
	const filterKey = 'controlsFilter';
	const currentPropertyName = propertyName || 'controls';

	const onMultiSelectValueChange = (selectedOptions: ISelectOption[]) => {
		if (!selectedOptions.length) {
			setFilters((prev: any) => ({
				...prev,
				[filterKey]: (dataRecord: any) => true,
			}));
			return;
		}

		setFilters((prev: any) => ({
			...prev,
			[filterKey]: (dataRecord: any) => {
				const arrayOfValues = dataRecord[currentPropertyName];

				return selectedOptions.some((v) => {
					if (arrayOfValues && arrayOfValues[0] && arrayOfValues[0].id) {
						return arrayOfValues.some((av: any) => av.id === v.value);
					}

					return arrayOfValues.includes(v.value);
				});
			},
		}));
	};

	return (
		<MultiSelectFilter
			placeholder={placeholder || 'Controls'}
			options={options || []}
			onChange={onMultiSelectValueChange}
			isTagStyledList={false}
			withSearch
		/>
	);
};
