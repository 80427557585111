import React, { FC } from 'react';
import styles from './CompanyProfile.module.scss';
import { DataList, LoadingSkeleton } from '../../components';
import { DataListItem } from '../../components';

const TrustHubSkeleton: FC = () => {
	const userInfo = (
		<DataList className={styles['company-info']}>

			<div className="no-desc mt-8 mb-5 flex items-center flex-col">
				<LoadingSkeleton containerClassName={styles.skeleton} height={20} />
			</div>

			<DataListItem
				label="Status"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Last update"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
		</DataList>
	);

	return <div className={styles['company-skeleton']}>{userInfo}</div>;
};

export default TrustHubSkeleton;
