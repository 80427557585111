import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Tab.module.scss';
import { ITabItem } from './ITab';

export const Tab: FC<ITabItem> = ({ children, className, isActive, onClick }) => {
	return (
		<div
			className={classNames(styles.tab, className, isActive ? styles.active : '')}
			onClick={onClick}
		>
			{children}
		</div>
	);
};
