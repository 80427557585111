import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { CommonResultStatusType, IKeyValuePair, ISortProps } from '../../../../../utils/types';
import {
	Table,
	TableFilters,
	BasicPanel,
	TableSkeleton,
	ControlsFilter,
	SearchFilter,
	Drawer,
	HoverTooltip,
	Loader,
} from '../../../../primitives';
import styles from './ArchivedPoliciesTable.module.scss';
import { getGSPolicyColumns } from './utils';
import {
	usePagination,
	useDevice,
	useControls,
	useUser,
	usePolicies
} from '../../../../../utils/helpers/hooks';
import { IPolicy, unarchivePolicy } from '../../../../../services/store/slices/policies.slice';
import classNames from 'classnames';
import IPartialTable from '../../common/IPartialTable';
import { getControlOptions, processTableData } from '../../../../../utils/helpers/common';
import ArchivedPolicyDetails from '../../../edit-panels/policy/archived-details/ArchivedPolicyDetails';
import { ActionButton } from '../../../../primitives';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../services/store';
import { Alert } from '../../../../';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../../../../utils/helpers/constants';

const ArchivedPoliciesTable: FC<
	IPartialTable<IPolicy> & { onPolicyUnarchived: (policyId: string) => void }
> = ({ data, dataLoading, showItemId, onPolicyUnarchived }) => {
	const device = useDevice();
	const { isAdmin } = useUser();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { items: controlItems } = useControls();
	const { policyLoading } = usePolicies();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IPolicy[]>([]);
	const [isProcessing, setIsProcessing] = useState(true);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [unarchiveResult, setUnarchiveResult] = useState<CommonResultStatusType>('');
	const [policyToUnarchive, setPolicyToUnarchive] = useState<string | null>(null);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData, 8);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IPolicy) => true,
		frameworkFilter: (dataRecord: IPolicy) => true,
		controlsFilter: (dataRecord: IPolicy) => true,
	});

	const tableColumnsConfig = useMemo(() => getGSPolicyColumns(device), [device]);
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => {
			return navigate(`${AppUrls.policies}?${AppQueryParams.item}=${id}`);
		},
		[navigate],
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					placeholder="Search policy..."
					setFilters={setCurrentFilters}
					properties={['name']}
				/>

				<ControlsFilter
					options={getControlOptions(controlItems || [])}
					setFilters={setCurrentFilters}
				/>
			</Fragment>
		);

		return <TableFilters disabled={dataLoading || !data.length} leftSection={leftSection} />;
	}, [controlItems, data.length, dataLoading]);

	const onReturnToLibrary = useCallback(
		(policyId: string) => {
			setPolicyToUnarchive(policyId);

			handleWithTryCatch(
				async () => {
					await dispatch(unarchivePolicy(policyId));
					setUnarchiveResult('success');
					onPolicyUnarchived(policyId);
				},
				undefined,
				() => setUnarchiveResult('error'),
			);
		},
		[dispatch, onPolicyUnarchived],
	);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="archived-policies"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					...(isAdmin && {
						rowActions: [
							...(!policyLoading
								? [
									{
										actionComponent: (
											<ActionButton
												tooltipId={'tooltip-return'}
												tooltipContent={'Return to Company Library'}
												type="return"
											/>
										),
										actionHandler: (id: string) => onReturnToLibrary(id),
									},
								]
								: [
									{
										actionComponent: (
											<Loader
												className={styles.loader}
												thin
												maxHeight={15}
												maxWidth={15}
											/>
										),
										showHandler: (id: string) => policyToUnarchive === id,
									},
								]),
						],
					}),
					actionsDisplayForRowsId: [policyToUnarchive || ''],
					bodyMaxHeight: 'calc(100vh - 370px)',
					rowClassName: styles.row,
					onRowClickHandler,
					onSortChange,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No policies to display yet. You will see the policies once they're archived.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			tableColumnsConfig,
			isAdmin,
			policyLoading,
			onRowClickHandler,
			onSortChange,
			dataLoading,
			data.length,
			onReturnToLibrary,
			policyToUnarchive,
		],
	);

	const alerts = (
		<Alert
			uniqueKey={'unarchived-error'}
			show={unarchiveResult === 'error'}
			type="error"
			message="Error while policy unarchive."
			clearActionStatus={() => setUnarchiveResult('')}
		/>
	);

	const detailsDrawer = (
		<>
			<Drawer
				title="Policy Details"
				open={openDrawer}
				onCloseClickHandler={() => {
					setOpenDrawer(false);
					navigate(AppUrls.policies);
				}}
			>
				{showItemId ? (
					<ArchivedPolicyDetails
						policyId={showItemId}
						onUnarchive={(policyId: string) => {
							onPolicyUnarchived(policyId);
							setOpenDrawer(false);
						}}
					/>
				) : null}
			</Drawer>
		</>
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IPolicy[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{detailsDrawer}

			{alerts}

			<HoverTooltip tooltipId="tooltip-return" place="top-end" />
		</>
	);
};

export default ArchivedPoliciesTable;
