import React, { FC } from 'react';
import { IError } from './IError';

import styles from './Error.module.scss';
import { joinClassNames } from '../../../utils/helpers/common/joinClassNames';

export const Error: FC<IError> = React.memo(({ className, message, disabled, ...rest }) => {
	return (
		<div
			className={joinClassNames(styles.error, className, disabled ? styles.disabled : '')}
			{...rest}
		>
			{message}
		</div>
	);
});
