import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	ActionButton,
	Button,
	HoverTooltip,
	SearchFilter,
	Table,
	TableFilters,
} from '../../../primitives';
import { getBriefPoliciesColumns } from './utils';
import styles from './ManagePoliciesTable.module.scss';
import { IIdName, IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import {} from '../../../primitives';
import IManagePoliciesTable from './IManagePoliciesTable';
import { IPolicy } from '../../../../services/store/slices/policies.slice';
import ModalAddPolicies from '../../modals/modal-add-policies/ModalAddPolicies';

const ManagePoliciesTable: FC<IManagePoliciesTable> = ({
	className,
	data,
	bodyMaxHeight = 360,
	entityPolicyIds,
	setEntityPolicyIds,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IPolicy[]>([]);
	const [highlightedPolicies, setHighlightedPolicies] = useState<string[]>([]);

	const [addPoliciesModalOpen, setAddPoliciesModalOpen] = useState(false);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IPolicy) => true,
	});

	const modals = (
		<ModalAddPolicies
			open={addPoliciesModalOpen}
			setOpen={setAddPoliciesModalOpen}
			entityPolicyIds={entityPolicyIds}
			setEntityPolicies={setEntityPolicyIds}
			onNewAdded={(newItems: IIdName[]) => {
				setHighlightedPolicies(newItems.map((item) => item.id));
			}}
		/>
	);

	const tableFilters = useMemo(() => {
		const onRemoveClick = () => {
			setEntityPolicyIds(
				entityPolicyIds.filter((policy) => !selectedRows.includes(policy.id)),
			);
			setSelectedRows([]);
		};

		const leftSection = (
			<>
				<SearchFilter
					placeholder="Search policy..."
					className={styles.search}
					properties={['name', 'type']}
					setFilters={setCurrentFilters}
					disabled={!data.length}
				/>

				{showDeleteBtn ? (
					<Button
						type="button"
						className={styles.remove}
						negative
						width={157}
						onClick={onRemoveClick}
					>
						Remove Selected
					</Button>
				) : null}
			</>
		);

		const rightSection = (
			<>
				<Button
					className={styles.add}
					negative
					width={105}
					type="button"
					onClick={() => setAddPoliciesModalOpen(true)}
				>
					Add Policies
				</Button>
			</>
		);

		return (
			<TableFilters
				className={styles.filters}
				leftSection={data.length ? leftSection : null}
				rightSection={rightSection}
			/>
		);
	}, [data, entityPolicyIds, selectedRows, setEntityPolicyIds, showDeleteBtn]);

	const rowActions = useMemo(() => {
		return [
			{
				actionComponent: (
					<ActionButton
						className={styles['action-button']}
						tooltipId={'tooltip-remove-policy'}
						tooltipContent={'Remove from this tag'}
						type="cancel"
					/>
				),
				actionHandler: (id: string) => {
					setEntityPolicyIds(entityPolicyIds.filter((item) => item.id !== id));
				},
			},
		];
	}, [entityPolicyIds, setEntityPolicyIds]);

	const mainTable = (
		<Table
			tableKey="manage-policies"
			data={dataToDisplay}
			columns={getBriefPoliciesColumns()}
			options={{
				rowActions,
				rowCheckbox: true,
				rowClassName: styles.row,
				onSortChange,
				emptyResultMessage: !data.length
					? 'No policies to display.'
					: 'No matches found. Please try another search query.',
				emptyResultMessageType: !data.length ? 'common' : 'search',
				bodyMaxHeight,
				small: true,
				scrollFullRightPosition: true,
				selectedRows,
				setSelectedRows,
				successHighlightedRows: highlightedPolicies,
			}}
		/>
	);

	useEffect(() => {
		if (data.length) {
			const proccessedData: IPolicy[] = processTableData(data, currentFilters, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [currentFilters, currentSort, data]);

	useEffect(() => {
		setShowDeleteBtn(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		if (!highlightedPolicies.length) return;

		const delay = setTimeout(() => {
			setHighlightedPolicies([]);
			clearTimeout(delay);
		}, 2000);
	}, [highlightedPolicies]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{!data.length ? (
				<p className={styles.empty}>No policies are included in this tag yet.</p>
			) : null}

			{tableFilters}

			{data.length ? (
				<>
					{mainTable}

					<HoverTooltip tooltipId="tooltip-remove-policy" place="top-end" />
				</>
			) : null}

			{modals}
		</div>
	);
};

export default ManagePoliciesTable;
