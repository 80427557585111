import axios from 'axios';
import { PUBLIC_TRUST_HUB, TRUST_HUB } from './api.path';
import { IFilePreassignedData } from '../../utils/types';
import {
	IShareTrustHubLinkDto,
	ITrustHub,
	ITrustHubFrameworksEvaluations,
	ITrustHubRequestAccessDto,
	ITrustHubResource,
	TrustHubAccessRequestResource,
	TrustHubAccessRequests,
} from '../store/slices/trust-hub.slice';

const trustHubApi = {
	getTrustHub: (): Promise<ITrustHub> => {
		return axios.get(`${TRUST_HUB}`);
	},
	getResources: (): Promise<ITrustHubResource[]> => {
		return axios.get(`${TRUST_HUB}/resources`);
	},
	getFrameworksEvaluations: (): Promise<ITrustHubFrameworksEvaluations[]> => {
		return axios.get(`${TRUST_HUB}/frameworks-evaluations`);
	},
	runFrameworksEvaluationForCompany: (companyId: number): Promise<{ success: boolean }> => {
		return axios.post(`${TRUST_HUB}/run-frameworks-evaluation-for-company`, { companyId });
	},
	getPublicTrustHub: (companySlug: string): Promise<ITrustHub> => {
		return axios.get(`${PUBLIC_TRUST_HUB}/${companySlug}`);
	},
	updateTrustHub: (data: ITrustHub): Promise<ITrustHub> => {
		return axios.put(`${TRUST_HUB}`, data);
	},
	updateResourceName: (resourceId: number, name: string): Promise<ITrustHubResource> => {
		return axios.put(`${TRUST_HUB}/resources/${resourceId}`, { name });
	},
	createTruestHub: (data: Partial<ITrustHub>): Promise<ITrustHub> => {
		return axios.post(`${TRUST_HUB}`, data);
	},
	shareTrustHub: (data: IShareTrustHubLinkDto): Promise<void> => {
		return axios.post(`${TRUST_HUB}/share`, data);
	},
	requestAccess: (companySlug: string, data: ITrustHubRequestAccessDto): Promise<void> => {
		return axios.post(`${PUBLIC_TRUST_HUB}/request-access/${companySlug}`, data);
	},
	getPreassignedUrlForPolicy: (fileName: string): Promise<IFilePreassignedData> => {
		return axios.get(`${TRUST_HUB}/preassign-url?fileName=${fileName}`);
	},
	createResourceFile: (fileId: number): Promise<ITrustHubResource> => {
		return axios.post(`${TRUST_HUB}/resources/${fileId}`);
	},
	deleteResourceFile: (resourceId: number): Promise<void> => {
		return axios.delete(`${TRUST_HUB}/resources/${resourceId}`);
	},
	massDeleteResourceFiles: (resourceIds: string[]): Promise<void> => {
		return axios.delete(`${TRUST_HUB}/resources`, { data: { resourceIds } });
	},
	getRequestAccessItems: (): Promise<ITrustHubRequestAccessDto[]> => {
		return axios.get(`${TRUST_HUB}/request-access-items`);
	},
	getPendingTrustHubRequestAccessItems: (): Promise<ITrustHubRequestAccessDto[]> => {
		return axios.get(`${TRUST_HUB}/pending-request-access-items`);
	},
	getReviewedTrustHubRequestAccessItems: (): Promise<ITrustHubRequestAccessDto[]> => {
		return axios.get(`${TRUST_HUB}/reviewed-request-access-items`);
	},
	getTrustHubRequestAccessItem: (id: number): Promise<TrustHubAccessRequests> => {
		return axios.get(`${TRUST_HUB}/request-access-items/${id}`);
	},
	updateTrustHubRequestAccessItem: (
		id: string,
		data: {
			trustHubAccessRequestResources: TrustHubAccessRequestResource[];
		},
	) => {
		return axios.put(`${TRUST_HUB}/request-access-items/${id}`, data);
	},
	getPublicResourcePath: (resourceId: number, token: string): Promise<{ path: string }> => {
		return axios.post(`${PUBLIC_TRUST_HUB}/resources/${resourceId}`, { token });
	},

	// changePassword: (data: IPasswordChangeData) => {
	// 	return axios.post(CHANGE_PASSWORD, {
	// 		previousPassword: data.oldPassword,
	// 		proposedPassword: data.newPassword,
	// 	});
	// },
	// getUserUpdates: (): Promise<IChangeHistoryEntryBasic[]> => {
	// 	return axios.get(HISTORY);
	// },
};

export default trustHubApi;
