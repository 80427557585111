import { FC, useCallback, useEffect, useState } from 'react';
import { getTrustHubFrameworksColumns } from './utils';
import { processTableData } from '../../../../utils/helpers/common';
import ISelectedTrustHubFrameworkTable from './ISelectedTrustHubFrameworksTable';
import { ActionButton, Table } from '../../../primitives';
import { IFramework } from '../../../../services/store/slices/frameworks.slice';
import { useCompany } from '../../../../utils/helpers/hooks';
import ModalDeleteTrustHubFramework from '../../modals/modal-delete-trust-hub-framework/ModalDeleteTrustHubFramework';

const SelectedTrustHubFrameworksTable: FC<ISelectedTrustHubFrameworkTable> = ({
	className,
	data,
	selectedRows,
	frameWorksEvaluations,
	setSelectedRows,
	setSelectedFrameworks,
	bodyMaxHeight = 360,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IFramework[]>([]);
	const { info } = useCompany();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [frameworksToDelete, setFrameworksToDelete] = useState<IFramework | undefined>(undefined);

	const onSetFrameworkToDelete = useCallback((id: string) => {
		setDeleteModalOpen(true);
		setFrameworksToDelete(dataToDisplay.find((item) => parseInt(item.id!) === parseInt(id)));
	}, [dataToDisplay]);

	const onFrameworkDelete = useCallback(() => {
		if (frameworksToDelete) {
			const updatedData = dataToDisplay.filter((item) => parseInt(item.id!) !== parseInt(frameworksToDelete.id!));
			setDataToDisplay(updatedData);
			setDeleteModalOpen(false);
			setSelectedFrameworks(updatedData);
		}
	}, [dataToDisplay, frameworksToDelete, setSelectedFrameworks]);

	useEffect(() => {
		if (data.length) {
			const dataToDisplay = data.map((item) => {
				const evaluation = frameWorksEvaluations?.find((evaluation) => parseInt(evaluation.frameworkTo.id!) === parseInt(item.id!));
				return {
					id: item.id,
					name: evaluation?.frameworkTo.name,
					compliance: evaluation?.percentage,
					evaluationDate: evaluation?.evaluatedAt,
				}
			});

			const processedData: IFramework[] = processTableData(dataToDisplay, {}, {});

			setDataToDisplay(processedData);
		}
	}, [frameWorksEvaluations, data]);

	return (
		<div className={className}>
			<Table
				tableKey="add-control"
				data={dataToDisplay}
				columns={getTrustHubFrameworksColumns(frameWorksEvaluations, info)}
				options={{
					rowCheckbox: false,
					emptyResultMessage: 'No frameworks to display.',
					emptyResultMessageType: !data.length ? 'common' : 'search',
					bodyMaxHeight,
					scrollFullRightPosition: true,
					selectedRows,
					setSelectedRows,
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Delete Resource Name'}
									type="cancel"
								/>
							),
							actionHandler: (id: string) => {
								onSetFrameworkToDelete(id);
							}
						}
					],
				}}
			/>
			<ModalDeleteTrustHubFramework
				open={deleteModalOpen}
				setOpen={setDeleteModalOpen}
				selectedFramework={frameworksToDelete}
				onDelete={onFrameworkDelete}
			/>
		</div>
	);
};

export default SelectedTrustHubFrameworksTable;
