import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FrameworksDescription } from '../../../utils/helpers/constants/frameworks-description';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import frameworksApi from '../../api/frameworks.api';
import { getFrameworkShortName, getLogoForFramework } from '../../../utils/helpers/common';
import { RootState } from '..';
import { getCompanyInfo } from './company.slice';

export interface ICrosswalkEvaluationResult {
	file: string;
	percentage: number;
	date: Date;
}

export interface IFrameworksSlice {
	loading?: boolean;
}

export interface IFrameworkBasic {
	id?: number;
	name?: string;
	description?: string;
	revisionNumber?: string;
}

export interface IFramework {
	id?: string;
	displayName?: string;
	name?: string;
	description?: string;
	logo?: string;
	evaluationEnabled?: boolean;
	isPrimary?: boolean;
	isActive?: boolean;
}

export interface IFrameworks extends IFrameworksSlice {
	items: IFramework[] | null;
}

const initialState: IFrameworks = {
	loading: false,
	items: null,
};

export const frameworksSlice = createSlice({
	name: 'frameworks',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setFrameworks: (state, { payload }: PayloadAction<IFramework[]>) => {
			state.items = payload;
		},
	},
});

export const getFrameworks = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	dispatch(loading());

	try {
		const companyInfo = getState().company.info;

		const frameworks = await frameworksApi.getFrameworks();
		let transformedFrameworks: IFramework[] = [];

		if (frameworks) {
			const mappedFrameworks: IFramework[] = frameworks.map((framework: IFrameworkBasic) => ({
				id: framework.id?.toString(),
				displayName: getFrameworkShortName(framework.name),
				name: framework.name,
				description: framework.description,
				logo: getLogoForFramework(framework.name),
				evaluationEnabled: true,
				isPrimary: companyInfo?.primaryFrameworkId === framework.id?.toString(),
				isActive: true,
			}));

			transformedFrameworks.push(...mappedFrameworks);
			transformedFrameworks.push(
				...FrameworksDescription.map((framework, idx) => ({
					id: `${frameworks.length * 100 + idx}`,
					...framework,
				})),
			);

			if (companyInfo?.primaryFrameworkId) {
				//put primary framework on top
				const primaryFramework = mappedFrameworks.find(
					(framework) => companyInfo?.primaryFrameworkId?.toString() === framework.id,
				);

				if (primaryFramework) {
					transformedFrameworks = transformedFrameworks.filter(
						(framework) => framework.id !== primaryFramework.id,
					);

					transformedFrameworks.unshift(primaryFramework);
				}
			}
			dispatch(setFrameworks(transformedFrameworks));
		}
	} catch (error: any) {
		dispatch(setFrameworks([]));
		throw new CommonResponseError('Error while getting frameworks.');
	} finally {
		dispatch(loaded());
	}
};

export const makeCrosswalkEvaluation =
	(idFrom: string, idTo: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const result = await frameworksApi.makeCrosswalkEvaluation(idFrom, idTo);

			if (!result.file)
				throw new CommonResponseError('Error while making crosswalk evaluation.');

			return result.file;
		} catch (error: any) {
			throw new CommonResponseError('Error while making crosswalk evaluation.');
		} finally {
			dispatch(loaded());
		}
	};

export const getPrimaryFrameworkReport = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	dispatch(loading());

	try {
		const companyInfo = getState().company.info;

		const result = await frameworksApi.getPrimaryFrameworkReport();

		if (!result.file)
			throw new CommonResponseError('Error while getting primary framework report.');

		await dispatch(getCompanyInfo(companyInfo?.id));
		return result.file;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting primary framework report.');
	} finally {
		dispatch(loaded());
	}
};

export const { loading, loaded, setFrameworks } = frameworksSlice.actions;
export default frameworksSlice.reducer;
