import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../../utils/types';
import {
	ControlsFilter,
	SearchFilter,
	OwnerFilter,
	Table,
	TableFilters,
	TableSkeleton,
	HoverTooltip,
	SelectedNumber,
} from '../../../../primitives';
import styles from './CompanyPoliciesTable.module.scss';
import { getCompanyPolicyColumns } from './utils';
import {
	usePagination,
	useUser,
	useDevice,
	useControls,
	useTags,
	useCompanyUsers
} from '../../../../../utils/helpers/hooks';
import { Alert, AssignBlock, BasicPanel } from '../../../../';
import { IPolicy, assignPolicies } from '../../../../../services/store/slices/policies.slice';
import EditPolicy from '../../../edit-panels/policy/edit/EditPolicy';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../services/store';
import classNames from 'classnames';
import IPartialTable from '../../common/IPartialTable';
import {
	getOwnerOptions,
	getControlOptions,
	processTableData,
} from '../../../../../utils/helpers/common';
import { AppQueryParams, CommonStatuses } from '../../../../../utils/helpers/constants';
import DownloadPolicy from '../../../action-buttons/download-policy/DownloadPolicy';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../../utils/helpers/constants/app-routes';

const CompanyPoliciesTable: FC<
	IPartialTable<IPolicy> & {
		externalyUpdatedPolicyId: string;
		setExternalyUpdatedPolicyId: (id: string) => void;
	}
> = ({ data, dataLoading, showItemId, externalyUpdatedPolicyId, setExternalyUpdatedPolicyId }) => {
	const dispatch = useAppDispatch();
	const device = useDevice();
	const navigate = useNavigate();
	const { items: companyUsers } = useCompanyUsers();
	const { isAdmin, info: userInfo } = useUser();
	const { items: controlItems } = useControls();
	const { items: tagItems } = useTags();

	const [processedData, setProcessedData] = useState(data);
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentPageData, setCurrentPageData] = useState<IPolicy[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [updateResult, setUpdateResult] = useState<CommonResultStatusType>('');
	const [assignmentResult, setAssignmentResult] = useState<CommonResultStatusType>('');
	const [assignIsProcessing, setAssignIsProcessing] = useState(false);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showAssignBlock, setShowAssignBlock] = useState(false);
	const [updatedPolicyId, setUpdatedPolicyId] = useState<string | null>('');
	const [downloadingPolicyId, setDownloadingPolicyId] = useState<string | null>('');

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const tableColumnsConfig = useMemo(
		() => getCompanyPolicyColumns(device, tagItems),
		[device, tagItems],
	);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IPolicy) => true,
		frameworkFilter: (dataRecord: IPolicy) => true,
		controlsFilter: (dataRecord: IPolicy) => true,
	});

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.policies}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const onPolicyUpdateSuccess = () => {
		setUpdatedPolicyId(showItemId || '');
		setUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.policies}`);
	};

	const onPolicyUpdateBeforeDownloadSuccess = () => {
		setUpdateResult('success');
	};

	const onPolicyUpdateError = () => {
		setUpdateResult('error');
	};

	const assignSelectedPolicies = (ownerId: string) => {
		setAssignIsProcessing(true);

		handleWithTryCatch(
			async () => {
				const owner = ownerId === 'unassigned' ? null : ownerId;
				await dispatch(assignPolicies(owner, selectedRows));

				setAssignmentResult('success');
				setAssignIsProcessing(false);
			},
			undefined,
			() => {
				setAssignmentResult('error');
				setAssignIsProcessing(false);
			},
		);
	};

	useEffect(() => {
		if (assignmentResult === 'success') {
			const delay = setTimeout(() => {
				setAssignmentResult('');
				setSelectedRows([]);
				clearTimeout(delay);
			}, 2000);
		}
	}, [assignmentResult]);

	const alerts = (
		<>
			<Alert
				uniqueKey={'assign-success'}
				show={assignmentResult === 'success'}
				type="success"
				message="Policy owner updated"
				clearActionStatus={() => setAssignmentResult('')}
			/>
			<Alert
				uniqueKey={'assign-error'}
				show={assignmentResult === 'error'}
				type="error"
				message="Error occurred while updating policy owner"
				clearActionStatus={() => setAssignmentResult('')}
			/>
			<Alert
				uniqueKey={'policy-success'}
				show={updateResult === 'success'}
				type="success"
				message="Policy updated!"
				clearActionStatus={() => setUpdateResult('')}
			/>
			<Alert
				uniqueKey={'policy-error'}
				show={updateResult === 'error'}
				type="error"
				message="Error occurred while updating policy"
				clearActionStatus={() => setUpdateResult('')}
			/>
		</>
	);

	const editDrawer = (
		<EditPolicy
			open={openDrawer}
			policyId={showItemId || ''}
			onUpdateSuccess={onPolicyUpdateSuccess}
			onUpdateBeforeDownloadSuccess={onPolicyUpdateBeforeDownloadSuccess}
			onUpdateError={onPolicyUpdateError}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(AppUrls.policies);
			}}
		/>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					placeholder="Search policy..."
					setFilters={setCurrentFilters}
					properties={['name']}
				/>

				{isAdmin ? (
					<OwnerFilter
						options={getOwnerOptions(companyUsers, userInfo?.id)}
						setFilters={setCurrentFilters}
					/>
				) : null}

				<ControlsFilter
					options={getControlOptions(controlItems || [])}
					setFilters={setCurrentFilters}
				/>
			</Fragment>
		);

		return <TableFilters disabled={dataLoading || !data.length} leftSection={leftSection} />;
	}, [controlItems, data.length, dataLoading, userInfo?.id, companyUsers, isAdmin]);

	const mainTable = useMemo(() => {
		const getHighlightedRows = () => {
			if (assignmentResult === 'success') return selectedRows;
			if (updatedPolicyId) return [updatedPolicyId];

			return [];
		};

		return (
			<Table
				tableKey="company-policies"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					rowActions: [
						{
							actionComponent: (
								<DownloadPolicy
									tooltipId={'tooltip-download'}
									tooltipContent={'Download PDF'}
									setDownloadingPolicyId={setDownloadingPolicyId}
								/>
							),
						},
					],
					actionsDisplayForRowsId: [downloadingPolicyId || ''],
					selectedRows,
					setSelectedRows,
					bodyMaxHeight: 'calc(100vh - 370px)',
					rowCheckbox: isAdmin,
					onSortChange,
					onRowClickHandler,
					successHighlightedRows: getHighlightedRows(),
					emptyResultMessage:
						!dataLoading && !data.length
							? `No policies to display yet. You can copy a policy from GS Library tab.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		);
	}, [
		assignmentResult,
		currentPageData,
		data.length,
		dataLoading,
		downloadingPolicyId,
		isAdmin,
		onRowClickHandler,
		onSortChange,
		selectedRows,
		tableColumnsConfig,
		updatedPolicyId,
	]);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (externalyUpdatedPolicyId) {
			setUpdatedPolicyId(externalyUpdatedPolicyId);
			setExternalyUpdatedPolicyId('');
		}
	}, [externalyUpdatedPolicyId, setExternalyUpdatedPolicyId]);

	useEffect(() => {
		if (updatedPolicyId && data.find((p) => p.id === updatedPolicyId)) {
			goToPageByDataEntryProperty(updatedPolicyId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedPolicyId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedPolicyId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setShowAssignBlock(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const processedData: IPolicy[] = processTableData(data, currentFilters, currentSort)

		const sortPoliciesByStatus = () => {
			const dataWithPendingStatus = processedData.filter(
				(dataRecord) => dataRecord.status === CommonStatuses['pending-review'],
			);

			const restData = processedData.filter(
				(dataRecord) => dataRecord.status !== CommonStatuses['pending-review'],
			);

			return [...dataWithPendingStatus, ...restData];
		};

		setProcessedData(sortPoliciesByStatus());
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (assignmentResult === 'success' && selectedRows.length) {
			goToPageByDataEntryProperty(selectedRows[0], 'id');
		}
	}, [assignmentResult, goToPageByDataEntryProperty, selectedRows]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				<SelectedNumber number={selectedRows.length} />

				{!showAssignBlock ? tableFilters : null}

				<AssignBlock
					loading={assignIsProcessing}
					show={showAssignBlock}
					onAssign={assignSelectedPolicies}
				/>

				{isProcessing || dataLoading ? (
					<TableSkeleton rowCheckbox columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			<HoverTooltip tooltipId="tooltip-download" place="top-end" />

			{editDrawer}

			{alerts}
		</>
	);
};

export default CompanyPoliciesTable;
