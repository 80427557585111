import React, { FC, useState } from 'react';
import ICommentsBlock from './ICommentsBlock';
import { Button, Loader, Textarea } from '../../primitives';
import styles from './CommentsBlock.module.scss';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import { useAppDispatch } from '../../../services/store';
import classNames from 'classnames';
import { CommonResultStatusType, ICreateComment } from '../../../utils/types';
import { Alert } from '../../';
import { dateInMDYTimeFormat, userNameFirstLetters } from '../../../utils/helpers/common';
import { useCompany } from '../../../utils/helpers/hooks';
import { sanitize } from 'dompurify';

const CommentsBlock: FC<ICommentsBlock> = ({
	className,
	entityId,
	entityType,
	comments,
	addDispatchHandler,
	onAddSuccess,
	isViewMode,
	commentsLoading,
}) => {
	const { info: companyInfo } = useCompany();
	const dispatch = useAppDispatch();
	const [addCommentIsActive, setAddCommentIsActive] = useState(false);
	const [newComment, setNewComment] = useState('');

	const [addCommentResult, setAddCommentResult] = useState<CommonResultStatusType>('');

	const onCommentChange = (value: string) => {
		if (value) setAddCommentIsActive(true);
		else setAddCommentIsActive(false);

		setNewComment(value);
	};

	const onAddComment = () => {
		if (entityId) {
			handleWithTryCatch(
				async () => {
					const commentText = sanitize(newComment);

					const comment = {
						entityId,
						entityType,
						text: commentText,
					} as ICreateComment;

					await dispatch(addDispatchHandler(comment));

					setAddCommentResult('success');
					setNewComment('');
					setAddCommentIsActive(false);
					if (onAddSuccess) onAddSuccess(comment);
				},
				undefined,
				() => setAddCommentResult('error'),
			);
		}
	};

	const alerts = (
		<>
			<Alert
				uniqueKey={'comments-success'}
				show={addCommentResult === 'success' && !commentsLoading}
				type="success"
				message="Comment added!"
				clearActionStatus={() => setAddCommentResult('')}
			/>
			<Alert
				uniqueKey={'comments-error'}
				show={addCommentResult === 'error'}
				type="error"
				message="Error occurred while adding comment"
				clearActionStatus={() => setAddCommentResult('')}
			/>
		</>
	);

	return (
		<div className={classNames(styles['comments-block'], className)}>
			<div className={styles.comments}>
				{comments?.length
					? comments.map((comment) => (
							<div key={comment.createdAt?.toString()} className={styles.comment}>
								<div className={styles.top}>
									<div className={styles.avatar}>
										{userNameFirstLetters(
											comment.user?.split(' ')[0],
											comment.user?.split(' ')[1],
										)}
									</div>
									<div className={styles.name}>{comment.user}</div>
									<div className={styles.date}>
										{dateInMDYTimeFormat(
											comment.createdAt,
											companyInfo?.locationTimeZone,
										)}
									</div>
								</div>

								<div className={styles.bottom}>{comment.text}</div>
							</div>
						))
					: null}
			</div>

			{!isViewMode ? (
				<>
					<Textarea
						disabled={commentsLoading}
						name="comment"
						value={newComment}
						onValueChange={onCommentChange}
						placeholder="Enter comment here"
						asInput
					/>

					<Button
						small
						negative
						width={90}
						className={styles['add-comment']}
						type="button"
						disabled={!addCommentIsActive || commentsLoading}
						onClick={onAddComment}
					>
						{commentsLoading ? (
							<Loader className={styles.loader} thin maxHeight={15} maxWidth={15} />
						) : (
							'Comment'
						)}
					</Button>

					{alerts}
				</>
			) : null}

			{!comments?.length && isViewMode ? (
				<p className={styles.empty}>No comments to display</p>
			) : null}
		</div>
	);
};

export default CommentsBlock;
