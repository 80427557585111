import { SetStateAction, Dispatch, useEffect, useMemo, useState } from 'react';
import { Button, FileInfo, FilePicker, ProgressBar } from '../../../../primitives';
import { IFilePreassignedData, IFileUploadResult } from '../../../../../utils/types';
import { SUPPORTED_TRUST_HUB_RESOURCES_TYPES } from '../../../../../utils/helpers/constants';
import { createResourceFile, getResources, ITrustHubResource } from '../../../../../services/store/slices/trust-hub.slice';
import ResourcesTable from './ResourcesTable';
import { useAppDispatch } from '../../../../../services/store';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { joinClassNames } from '../../../../../utils/helpers/common/joinClassNames';

interface ResourcesBlockProps {
	setResourcesIds: (ids?: number[]) => void;
	getPreassignedUrlForPolicyCall: (fileName: string) => () => Promise<IFilePreassignedData>;
	predefinedResources: ITrustHubResource[] | null,
	onUpdateSuccess: (newMessage: string) => void;
	onUpdateError: (newMessage: string) => void;
	onDeleteSelectedResources: () => void;
	selectedResources: string[];
	setSelectedResources: Dispatch<SetStateAction<string[]>>
}

export const ResourcesBlock = ({
	setResourcesIds,
	getPreassignedUrlForPolicyCall,
	predefinedResources,
	onUpdateSuccess,
	onUpdateError,
	onDeleteSelectedResources,
	selectedResources,
	setSelectedResources
}: ResourcesBlockProps) => {
	const dispatch = useAppDispatch();
	const [filesProgress, setFilesProgress] = useState<IFileUploadResult[]>([]);
	const [uploadedFileIds, setUploadedFileIds] = useState<number[]>([]);
	const [acceptedFiles, setAcceptedFiles] = useState<File[] | null>(null);
	const [resources, setResources] = useState<ITrustHubResource[] | null>(predefinedResources);
	const [filesLoading, setFilesLoading] = useState(false);

	useEffect(() => {
		if (
				uploadedFileIds?.length &&
				acceptedFiles?.length &&
				uploadedFileIds.length === acceptedFiles.length
			) {
			handleWithTryCatch(async () => {
				setFilesLoading(true);
				const promises = uploadedFileIds.map(async (fileId) => dispatch(createResourceFile(fileId)));
				const promisesResources = await Promise.all(promises);
				setResources((prevResources) => {
					return [
						...(prevResources || []),
						...promisesResources,
					];
				});
				setAcceptedFiles(null);
				setUploadedFileIds([]);
				await dispatch(getResources(true));
				onUpdateSuccess('Trust Hub resources uploaded successfully');
			}, undefined, () => {
				setFilesLoading(false);
				onUpdateError('Trust Hub resources upload failed');
			})
		}
	}, [dispatch, onUpdateError, onUpdateSuccess, uploadedFileIds, acceptedFiles]);

	useEffect(() => {
		setResourcesIds(resources?.map((resource) => resource.id));
	}, [uploadedFileIds, setResourcesIds, resources]);

	useEffect(() => {
		if (resources?.length) setFilesLoading(true);
		else setFilesLoading(false);
	}, [resources?.length]);

	const renderProgress = useMemo(() => {
		if (!acceptedFiles?.length) return null;

		const calculateGeneralProgress = () => {
			if (filesProgress?.length === 1) {
				return filesProgress[0].progress;
			}

			const result =
				filesProgress?.reduce((acc, file) => {
					return acc + file.progress;
				}, 0) || 0;

			return Math.round(result / filesProgress?.length);
		};

		const generalProgress = calculateGeneralProgress();

		const progressBlock = (
			<div className="my-10">
				<ProgressBar progressPercent={generalProgress} />
				<div className="mt-2 flex justify-between">
					<div className="text-xs">Uploading Files</div>
					<div className="text-xs">{generalProgress}%</div>
				</div>
			</div>
		);

		return generalProgress < 100 ? progressBlock : null;
	}, [filesProgress, acceptedFiles]);

	const renderTable = useMemo(() => {
		return (
			!!resources?.length &&
			<ResourcesTable
				data={resources}
				selectedResources={selectedResources}
				setResources={setResources}
				onNewResultError={onUpdateError}
				onNewResultSuccess={onUpdateSuccess}
				setSelectedResources={setSelectedResources}
				onDeleteSelectedResources={onDeleteSelectedResources}
			/>
		);
	}, [resources, onUpdateError, onUpdateSuccess, selectedResources, setSelectedResources, onDeleteSelectedResources]);

	const renderActions = useMemo(() => {
		return (
			<div>
				<div className="pt-6 flex justify-between items-center mb-5 gap-3">
					{resources?.length ? <div className="text-sm text-blueberry-100">Upload the documents that you’d like to be presented on Trust Hub</div> : (
						<div className="text-sm text-blueberry-100">
							You don’t have any resources uploaded to this hub. Click “Upload” to upload a file.
						</div>
					)}
					{!selectedResources?.length ? (
						<FilePicker
							acceptExtensions={SUPPORTED_TRUST_HUB_RESOURCES_TYPES}
							setAcceptedFiles={setAcceptedFiles}
							showDropZone={false}
							buttonText="Upload"
							buttonClassName="min-w-[82px]"
						/>
					) : (
						<div>
							<Button
								className={joinClassNames("min-w-[135px]")}
								type='button'
								negative
								onClick={onDeleteSelectedResources}
							>Delete Selected</Button>
						</div>
					)}
				</div>
				{acceptedFiles?.map((file) => (
					<FileInfo
						key={file.name}
						file={file}
						setPolicyFiles={setAcceptedFiles}
						setUploadedFileIds={setUploadedFileIds}
						setFilesProgress={setFilesProgress}
						getPreassignedUrlForPolicyCall={getPreassignedUrlForPolicyCall}
					/>
				))}
				{filesLoading && renderProgress}
			</div>
		);
	}, [acceptedFiles, filesLoading, getPreassignedUrlForPolicyCall, onDeleteSelectedResources, renderProgress, resources?.length, selectedResources.length]);

	return (
		<div>
			{renderActions}
			{renderTable}
		</div>
	);
};
