import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Table, TableSkeleton, Tabs } from '../../components';
import { getPendingAccessRequestsColumns, getReviewedAccessRequestsColumns } from './utils';
import { useAppDispatch } from '../../services/store';
import { useTrustHub, useAPIUpdateResult, useCompany } from '../../utils/helpers/hooks';
import { ISortProps } from '../../utils/types';
import { getPendingTrustHubRequestAccessItems, getReviewedTrustHubRequestAccessItems, ITrustHubRequestAccessDto } from '../../services/store/slices/trust-hub.slice';
import { processTableData } from '../../utils/helpers/common';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import EditTrustHubAccessResource from './EditTrustHubAccessResource';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../utils/helpers/constants';

export const TrustHubAccessRequests = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { info: companyInfo } = useCompany();
	const { trustHubRequestAccessItems, reviewedTrustHubRequestAccessItems, loading } = useTrustHub();
	const [processedData, setProcessedData] = useState(trustHubRequestAccessItems);
	const [processedReviewedData, setProcessedReviewedData] = useState(reviewedTrustHubRequestAccessItems);
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [openEditTrustHubAccessResource, setOpenEditTrustHubAccessResource] = useState(false);

	const { result, onNewResultSuccess, onNewResultError, onResultClear } = useAPIUpdateResult();

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(trustHubAccessRequestId: string) => {
			navigate(`${AppUrls.trustHubAccessRequests}?${AppQueryParams.item}=${trustHubAccessRequestId}`);
		},
		[navigate],
	);

	const navigateToItem = useMemo(() => {
		if (!loading && trustHubRequestAccessItems) {
			const userIdFromUrl = new URLSearchParams(location.search).get(AppQueryParams.item);

			return userIdFromUrl;
		}
	}, [trustHubRequestAccessItems, loading, location.search]);

	useEffect(() => {
		if (navigateToItem) {
			setOpenEditTrustHubAccessResource(true);
		}
	}, [navigateToItem]);

	useEffect(() => {
		if (!trustHubRequestAccessItems) return;
		setIsProcessing(true);

		const processedData: ITrustHubRequestAccessDto[] = processTableData(trustHubRequestAccessItems, {}, currentSort);

		setProcessedData(processedData);
		setIsProcessing(false);
	}, [currentSort, trustHubRequestAccessItems]);

	useEffect(() => {
		if (!reviewedTrustHubRequestAccessItems) return;
		setIsProcessing(true);

		const processedData: ITrustHubRequestAccessDto[] = processTableData(reviewedTrustHubRequestAccessItems, {}, currentSort);

		setProcessedReviewedData(processedData);
		setIsProcessing(false);
	}, [currentSort, reviewedTrustHubRequestAccessItems]);

	useEffect(() => {
		handleWithTryCatch(async () => {
			await dispatch(getPendingTrustHubRequestAccessItems(true));
			await dispatch(getReviewedTrustHubRequestAccessItems(true));
		});
	}, [dispatch]);


	const renderContent = useMemo(() => {
		return (
			<Table
				tableKey="pending-requests"
				data={processedData || []}
				columns={getPendingAccessRequestsColumns(companyInfo)}
				options={{
					emptyResultMessage: 'No pending requests to display yet.',
					emptyResultMessageType: 'common',
					extraSmall: true,
					noScroll: true,
					onSortChange,
					onRowClickHandler,
				}}
			/>
		);
	}, [companyInfo, processedData, onSortChange, onRowClickHandler]);

	const renderReviewedContent = useMemo(() => {
		return (
			<Table
				tableKey="reviewed-requests"
				data={processedReviewedData || []}
				columns={getReviewedAccessRequestsColumns(companyInfo)}
				options={{
					emptyResultMessage: 'No reviewed requests to display yet.',
					emptyResultMessageType: 'common',
					extraSmall: true,
					noScroll: true,
					onSortChange,
					onRowClickHandler,
				}}
			/>
		);
	}, [companyInfo, processedReviewedData, onSortChange, onRowClickHandler]);

	const Pending = useMemo(() => {
		return (
			<div className='bg-white rounded-s-2xl py-4 border-levender-50 border-[1px]'>
				{isProcessing || loading ? (
					<TableSkeleton extraSmall rowsNumber={5} columns={getPendingAccessRequestsColumns(companyInfo)} />
				) : (
					renderContent
				)}
			</div>
		);
	}, [isProcessing, loading, renderContent, companyInfo]);

	const Reviewed = useMemo(() => {
		return (
			<div className='bg-white rounded-s-2xl py-4 border-levender-50 border-[1px]'>
				{isProcessing || loading ? (
					<TableSkeleton extraSmall rowsNumber={5} columns={getPendingAccessRequestsColumns(companyInfo)} />
				) : (
					renderReviewedContent
				)}
			</div>
		);
	}, [isProcessing, loading, renderReviewedContent, companyInfo]);

	return (
		<div className="min-h-screen w-full flex flex-col gap-5">
			<h2 className='!mb-0'>Policies</h2>

			<main className="flex-1">
				<div className="flex justify-between">
					<Tabs
						tabs={[
							{
								key: 'pending',
								text: `Pending (${trustHubRequestAccessItems?.length || 0})`,
								component: Pending,
							},
							{
								key: 'reviewed',
								text: `Reviewed (${reviewedTrustHubRequestAccessItems?.length || 0})`,
								component: Reviewed,
							}
						]}
					/>
				</div>
			</main>
			<EditTrustHubAccessResource
				open={openEditTrustHubAccessResource}
				trustHubRequestAccessItemId={navigateToItem || ''}
				closeHandler={() => {
					setOpenEditTrustHubAccessResource(false)
					navigate(`${AppUrls.trustHubAccessRequests}`);
				}}
				onUpdateError={onNewResultError}
				onUpdateSuccess={onNewResultSuccess}
			/>
			<Alert
				uniqueKey={'trust-hub-results'}
				show={!!result.status}
				type={result.status}
				message={result.message}
				clearActionStatus={() => onResultClear()}
			/>
		</div>
	);
};
