import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	usePolicies,
	useUser,
	useGetPolicies,
	useControls
} from '../../utils/helpers/hooks';
import CompanyPoliciesTable from '../../components/partials/tables/policies/company-policies/CompanyPoliciesTable';
import { AppQueryParams } from '../../utils/helpers/constants';
import GSPoliciesTable from '../../components/partials/tables/policies/gs-policies/GSPoliciesTable';
import ArchivedPoliciesTable from '../../components/partials/tables/policies/archived-policies/ArchivedPoliciesTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import { Alert, Tabs } from '../../components';
import { CommonResultStatusType } from '../../utils/types';

const Policies = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { info: userInfo, isAdmin } = useUser();
	const {
		items: policiesItems,
		baseItems: basePolicyItems,
		archivedItems: archivedPolicyItems,
		archivationLoading,
		loading: policiesLoading,
	} = usePolicies();

	const { loading: controlsLoading } = useControls();

	const [dataLoading, setDataLoading] = useState(true);

	const [companyPoliciesLoading, setCompanyPoliciesLoading] = useState(true);
	const [basePoliciesLoading, setBasePoliciesLoading] = useState(true);
	const [archivedPoliciesLoading, setArchivedPoliciesLoading] = useState(true);

	const [activeTab, setActiveTab] = useState('companyLibrary');
	const [externalyUpdatedPolicyId, setExternalyUpdatedPolicyId] = useState('');
	const [unarchiveResult, setUnarchiveResult] = useState<CommonResultStatusType>('');
	const [copyResult, setCopyResult] = useState<CommonResultStatusType>('');

	useEffect(() => {
		setDataLoading(
			!!(
				companyPoliciesLoading ||
				basePoliciesLoading ||
				archivedPoliciesLoading ||
				controlsLoading ||
				policiesLoading
			),
		);
	}, [
		archivedPoliciesLoading,
		basePoliciesLoading,
		companyPoliciesLoading,
		controlsLoading,
		policiesLoading,
	]);

	const navigateToItem = useMemo(() => {
		if (
			!dataLoading &&
			policiesItems &&
			archivedPolicyItems &&
			basePolicyItems &&
			userInfo?.id
		) {
			const urlParams = new URLSearchParams(location.search);
			const policyIdFromUrl = urlParams.get(AppQueryParams.item) || '';
			const policyTypeFromUrl = urlParams.get(AppQueryParams.type);

			if (archivationLoading) return navigate(AppUrls.policies);

			if (policyTypeFromUrl) {
				if (policyTypeFromUrl === 'gs') {
					const policy = basePolicyItems?.find((item) => item.id === policyIdFromUrl);

					if (policy) {
						setActiveTab('gsLibrary');

						return {
							id: policyIdFromUrl,
							type: policyTypeFromUrl,
						};
					}
				}
			}

			if (policyIdFromUrl) {
				const policy = policiesItems?.find((item) => item.id === policyIdFromUrl);

				if (policy) {
					if (!isAdmin && policy?.owner?.id !== userInfo?.id)
						return navigate(AppUrls.policies);

					setActiveTab('companyLibrary');

					return {
						id: policyIdFromUrl,
						archived: false,
					};
				} else {
					const archivedPolicy = archivedPolicyItems?.find(
						(item) => item.id === policyIdFromUrl,
					);

					if (archivedPolicy) {
						if (!isAdmin && archivedPolicy?.owner?.id !== userInfo?.id)
							return navigate(AppUrls.policies);

						setActiveTab('archived');

						return {
							id: policyIdFromUrl,
							archived: true,
						};
					}
				}
			}
		}
	}, [
		archivationLoading,
		archivedPolicyItems,
		basePolicyItems,
		dataLoading,
		isAdmin,
		location.search,
		navigate,
		policiesItems,
		userInfo?.id,
	]);

	const onBasePolicyCopied = useCallback(async (newPolicyId: string) => {
		if (newPolicyId) {
			setCompanyPoliciesLoading(true);
			setActiveTab('companyLibrary');
			setCopyResult('success');
			setExternalyUpdatedPolicyId(newPolicyId);
			setCompanyPoliciesLoading(false);
		}
	}, []);

	const onPolicyUnarchived = useCallback(async (policyId: string) => {
		if (policyId) {
			setUnarchiveResult('success');
			setCompanyPoliciesLoading(true);
			setActiveTab('companyLibrary');
			setExternalyUpdatedPolicyId(policyId);
			setCompanyPoliciesLoading(false);
		}
	}, []);

	const archivedPolicies = useMemo(
		() =>
			isAdmin
				? archivedPolicyItems || []
				: archivedPolicyItems?.filter((policy) => policy.owner?.id === userInfo?.id) || [],
		[archivedPolicyItems, isAdmin, userInfo?.id],
	);

	const gsLibraryPolicies = useMemo(() => basePolicyItems || [], [basePolicyItems]);

	const companyLibraryPolicies = useMemo(
		() =>
			isAdmin
				? policiesItems || []
				: policiesItems?.filter((policy) => policy.owner?.id === userInfo?.id) || [],
		[isAdmin, policiesItems, userInfo?.id],
	);

	const companyLibrary = useMemo(
		() => (
			<CompanyPoliciesTable
				data={companyLibraryPolicies}
				dataLoading={dataLoading || companyPoliciesLoading || archivationLoading}
				externalyUpdatedPolicyId={externalyUpdatedPolicyId}
				setExternalyUpdatedPolicyId={setExternalyUpdatedPolicyId}
				showItemId={navigateToItem && !navigateToItem?.archived ? navigateToItem?.id : ''}
			/>
		),
		[
			companyLibraryPolicies,
			companyPoliciesLoading,
			externalyUpdatedPolicyId,
			navigateToItem,
			archivationLoading,
			dataLoading,
		],
	);

	const gsLibrary = useMemo(
		() => (
			<GSPoliciesTable
				data={gsLibraryPolicies}
				dataLoading={basePoliciesLoading}
				onBasePolicyCopied={onBasePolicyCopied}
				showItemId={
					navigateToItem && navigateToItem?.type === 'gs' ? navigateToItem?.id : ''
				}
			/>
		),
		[gsLibraryPolicies, basePoliciesLoading, onBasePolicyCopied, navigateToItem],
	);

	const archived = useMemo(
		() => (
			<ArchivedPoliciesTable
				data={archivedPolicies}
				dataLoading={archivedPoliciesLoading}
				onPolicyUnarchived={onPolicyUnarchived}
				showItemId={navigateToItem && navigateToItem?.archived ? navigateToItem?.id : ''}
			/>
		),
		[archivedPolicies, archivedPoliciesLoading, onPolicyUnarchived, navigateToItem],
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'unarchived-success'}
				show={unarchiveResult === 'success'}
				type="success"
				message="Policy unarchived."
				clearActionStatus={() => setUnarchiveResult('')}
			/>
			<Alert
				uniqueKey={'copy-success'}
				show={copyResult === 'success'}
				type="success"
				message="Policy copied."
				clearActionStatus={() => setCopyResult('')}
			/>
		</>
	);

	useGetPolicies();

	useEffect(() => {
		if (policiesItems) setCompanyPoliciesLoading(false);
	}, [policiesItems]);

	useEffect(() => {
		if (basePolicyItems) setBasePoliciesLoading(false);
	}, [basePolicyItems]);

	useEffect(() => {
		if (archivedPolicyItems) setArchivedPoliciesLoading(false);
	}, [archivedPolicyItems]);

	return (
		<>
			<h2>Policies</h2>

			<Tabs
				customActiveTab={activeTab}
				setCustomActiveTab={setActiveTab}
				tabs={[
					{
						key: 'companyLibrary',
						text: `Company Library (${companyLibraryPolicies?.length})`,
						component: companyLibrary,
					},
					{
						key: 'gsLibrary',
						text: 'GS Library',
						component: gsLibrary,
					},
					{
						key: 'archived',
						text: 'Archived',
						component: archived,
					},
				]}
			/>

			{alerts}
		</>
	);
};

export default Policies;
