import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import slugify from "slugify";
import { IControl } from "../../services/store/slices/controls.slice";
import { CollapsiblePanel, SectionTitle } from "../../components";
import { OverviewControlGroupFull } from "./OverviewControlGroupFull";

export interface OverviewControlsBoxFullProps {
  frameworksLoading: boolean;
  groupedControlItems?: {
    [key: string]: {
      enhancedFamilyName: string;
      items: IControl[];
    };
  }
}

export const OverviewControlsBoxFull = ({ frameworksLoading, groupedControlItems }: OverviewControlsBoxFullProps) => {
  const location = useLocation();
  const [collapseAll, setCollapseAll] = useState<number | boolean>(false);

  const slugifyTitle = (title: string) => slugify(title.toLowerCase());
  const urlTitle = location.hash.replace('#', '');
  return (
    <div className="p-6 bg-white rounded-2xl border border-levander-75">
      <div className='flex justify-between items-center'>
        <h3 className="font-semibold mb-4">Controls</h3>
        <Link
          to="#"
          className="font-semibold text-levander-300 text-xs flex gap-2 items-center"
          onClick={() => setCollapseAll(new Date().getTime())}
        >
          Collapse all
        </Link>
      </div>
      <div className="flex flex-col">
        {
          frameworksLoading ?
            <>Loading...</> :
            groupedControlItems && Object.values(groupedControlItems).map((group) => (
              <CollapsiblePanel
                isOpened={!collapseAll || slugifyTitle(group.enhancedFamilyName) === urlTitle}
                trigger={
                  <SectionTitle className='!my-0'>{group.enhancedFamilyName}</SectionTitle>
                }
                animateFirstIsOpen={false}
                id={slugifyTitle(group.enhancedFamilyName)}
              >
                <OverviewControlGroupFull
                  items={group.items}
                />
              </CollapsiblePanel>
            ))
        }
      </div>
    </div>
  );
}