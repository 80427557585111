import { FC, useEffect, useState } from 'react';
import IAssetDetails from './IAssetDetails';
import styles from './AssetDetails.module.scss';
import TagsAndControlsBlock from '../../../tags-and-controls-block/TagsAndControlsBlock';
import {
	Error,
	CollapsiblePanel,
	DataList,
	DataListItem,
	SectionTitle,
} from '../../../../primitives';
import {
	AssetCriticalityDisplayName,
	AssetDispositionDisplayName,
	AssetSensitivityDisplayName,
	AssetTypeDisplayName,
} from '../../../../../utils/helpers/constants';
import ChangesHistoryBlock from '../../../changes-history-block/ChangesHistoryBlock';
import CommentsBlock from '../../../comments-block/CommentsBlock';
import {
	IAsset,
	addCommentToAsset,
	getAssetById,
} from '../../../../../services/store/slices/assets.slice';
import { useAssets } from '../../../../../utils/helpers/hooks';
import { EntityTypes } from '../../../../../utils/types';
import { useAppDispatch } from '../../../../../services/store';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import AssetDetailsSkeleton from './AssetDetailsSkeleton';

const AssetDetails: FC<IAssetDetails> = ({ assetId }) => {
	const dispatch = useAppDispatch();
	const { commentsLoading, assetLoading } = useAssets();

	const [error, setError] = useState<string | null>(null);
	const [currentAsset, setCurrentAsset] = useState<IAsset | null>();

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<DataList>
					<DataListItem label="Asset Name" labelWidth={128} text={currentAsset?.name} />
					<DataListItem
						label="Asset Description"
						labelWidth={128}
						text={currentAsset?.description || '-'}
					/>
					<DataListItem
						label="Asset Details"
						labelWidth={128}
						text={currentAsset?.details || '-'}
					/>
					<DataListItem
						label="Owner"
						labelWidth={128}
						text={currentAsset?.owner?.fullName || 'Unassigned'}
					/>
					<DataListItem
						label="Type"
						labelWidth={128}
						text={
							AssetTypeDisplayName[
							currentAsset?.type as keyof typeof AssetTypeDisplayName
							]
						}
					/>
					<DataListItem
						label="Disposition"
						labelWidth={128}
						text={
							AssetDispositionDisplayName[
							currentAsset?.disposition as keyof typeof AssetDispositionDisplayName
							]
						}
					/>
					<DataListItem
						label="Criticality"
						labelWidth={128}
						text={
							AssetCriticalityDisplayName[
							currentAsset?.criticality as keyof typeof AssetCriticalityDisplayName
							]
						}
					/>
					<DataListItem
						label="Sensitivity"
						labelWidth={128}
						text={
							AssetSensitivityDisplayName[
							currentAsset?.sensitivity as keyof typeof AssetSensitivityDisplayName
							]
						}
					/>
				</DataList>
			</CollapsiblePanel>
		);
	};

	const renderTagsAndControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Tags and Controls</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<TagsAndControlsBlock
					entityTagIds={currentAsset?.tags || []}
					entityControlIds={currentAsset?.companyControls || []}
					isViewMode
				/>
			</CollapsiblePanel>
		);
	};

	const renderChangesHistory = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>History</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<ChangesHistoryBlock changes={currentAsset?.history} />
			</CollapsiblePanel>
		);
	};

	const renderComments = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Comments</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<CommentsBlock
					entityId={currentAsset?.id}
					entityType={EntityTypes.Asset}
					comments={currentAsset?.comments}
					addDispatchHandler={addCommentToAsset}
					commentsLoading={commentsLoading}
				/>
			</CollapsiblePanel>
		);
	};

	useEffect(() => {
		if (assetId) setError('');
	}, [assetId]);

	useEffect(() => {
		if (assetId && !currentAsset && !error) {
			handleWithTryCatch(async () => {
				const asset = await dispatch(getAssetById(assetId));

				//@ts-ignore
				setCurrentAsset(asset);
			}, setError);
		}
	}, [assetId, currentAsset, dispatch, error]);

	return (
		<div className={styles['asset-details']}>
			{assetLoading ? <AssetDetailsSkeleton /> : null}

			{!assetLoading && !error ? (
				<>
					{renderDetails()}

					{renderTagsAndControls()}

					{renderComments()}

					{renderChangesHistory()}
				</>
			) : null}

			{error ? <Error message={error} /> : null}
		</div>
	);
};

export default AssetDetails;
