import React, { FC, cloneElement, useState } from 'react';
import { ISortProps } from '../../../utils/types';
import styles from './Table.module.scss';
import { Checkbox } from '../form/checkbox/Checkbox';
import { IRowAction, ITable, ITableColumn } from './ITable';
import classNames from 'classnames';
import { Scrollbar } from '../scrollbar/Scrollbar';

export const Table: FC<ITable> = ({ tableKey: key, data, columns, options, className }) => {
	const [sort, setSort] = useState<ISortProps>({
		property: '',
		direction: '',
	});

	const {
		successHighlightedRows,
		errorHighlightedRows,
		selectedRows,
		setSelectedRows,
		onSortChange: onSortChangeHandler,
		onRowClickHandler,
		emptyResultMessageType,
		emptyResultMessage,
		rowCheckbox,
		small,
		extraSmall,
		scrollFullRightPosition,
		noScroll,
		smallScrollbar,
		bodyMaxHeight,
		bodyMinHeight,
		rowClassName,
		rowActions,
		actionsDisplayForRowsId,
		rowCustomKeyProperty,
		disabledIds
	} = options || {};

	const onSortChange = (columnKey: string) => {
		const getDirection = () => {
			if (sort?.direction === 'asc') return 'desc';
			if (sort?.direction === 'desc') return '';
			return 'asc';
		};

		setSort({ property: columnKey, direction: getDirection() });
		if (onSortChangeHandler)
			onSortChangeHandler({ property: columnKey, direction: getDirection() });
	};

	const onRowCheckboxChange = (rowId: string) => {
		if (setSelectedRows)
			setSelectedRows((prev: string[]) => {
				if (prev.includes(rowId)) {
					return prev.filter((id) => id !== rowId) as string[];
				}
				return [...prev, rowId] as string[];
			});
	};

	const onAllCheckboxChange = (e: any) => {
		const isChecked = e.target.checked;
		const allowedToCheck = data.filter((row) => !disabledIds?.includes(row.id));

		if (setSelectedRows) {
			if (isChecked) {
				setSelectedRows((prev) => [...prev, ...allowedToCheck.map((row) => row.id)] as string[]);
			} else {
				setSelectedRows((prev) => {
					return prev.filter((id) => !data.map((row) => row.id).includes(id)) as string[];
				});
			}
		}
	};

	const onEditTrigger = (id: string) => {
		if (onRowClickHandler) onRowClickHandler(id);
	};

	const renderSorting = (columnKey: string) => {
		return (
			<div className={styles['sort-directions']} onClick={() => onSortChange(columnKey)}>
				<span
					className={classNames(
						styles['sort-direction'],
						styles.asc,
						sort?.property === columnKey && sort?.direction === 'asc'
							? styles.active
							: '',
					)}
				></span>
				<span
					className={classNames(
						styles['sort-direction'],
						styles.desc,
						sort?.property === columnKey && sort?.direction === 'desc'
							? styles.active
							: '',
					)}
				></span>
			</div>
		);
	};

	const renderEmptyState = () => {
		return (
			<div className={styles['empty-message']}>
				<span
					className={classNames(
						styles.message,
						emptyResultMessageType ? styles[emptyResultMessageType] : '',
					)}
				>
					{emptyResultMessage}
				</span>
			</div>
		);
	};

	const headerSection = (
		<div className={styles.thead}>
			{data.length && rowCheckbox ? (
				<div className={classNames(styles.th, styles['with-checkbox'])}>
					<Checkbox
						id={`all-${key}`}
						checked={
							!!(
								data.length &&
								data.every((dataRecord) => selectedRows?.includes(dataRecord.id))
							)
						}
						onChange={onAllCheckboxChange}
					/>
				</div>
			) : null}

			{columns.map((column) => (
				<div className={styles.th} key={column.key} style={{ width: column.width }}>
					{column.header}

					{data.length && column.sorting ? renderSorting(column.key) : null}
				</div>
			))}
		</div>
	);

	return (
		<div
			className={classNames(
				className,
				styles.table,
				small ? styles.small : '',
				extraSmall ? styles['extra-small'] : '',
				scrollFullRightPosition ? styles['scroll-right-0'] : '',
				noScroll ? styles['no-scroll'] : '',
			)}
		>
			{headerSection}

			<Scrollbar small={smallScrollbar} hide={noScroll}>
				<div
					className={styles.body}
					style={{
						...(bodyMaxHeight && { maxHeight: bodyMaxHeight }),
						...(bodyMinHeight && { minHeight: bodyMinHeight }),
					}}
				>
					{data.length ? (
						<>
							{data.map((row) => {
								return (
									<div
										key={row[rowCustomKeyProperty || 'id']}
										className={classNames(
											styles.tr,
											rowClassName,
											rowCheckbox ? styles['with-checkbox'] : '',
											successHighlightedRows?.includes(row.id)
												? styles['highlighted-success']
												: '',
											errorHighlightedRows?.includes(row.id)
												? styles['highlighted-error']
												: '',
											selectedRows?.includes(row.id) ? styles.active : '',
										)}
										onClick={(e) => {
											const target = e.target as HTMLElement;
											const isCheckbox =
												target.tagName.toLowerCase() === 'input' ||
												target.tagName.toLowerCase() === 'label';
											if (!isCheckbox) onEditTrigger(row.id);
										}}
									>
										{rowCheckbox ? (
											<div
												className={classNames(
													styles.td,
													styles['with-checkbox'],
												)}
											>
												<Checkbox
													id={`${key}-${row.id}`}
													checked={selectedRows?.includes(row.id)}
													onChange={() => onRowCheckboxChange(row.id)}
													disabled={
														disabledIds?.includes(row.id) ||
														errorHighlightedRows?.includes(row.id)
													}
												/>
											</div>
										) : null}

										{columns.map((column: ITableColumn) => {
											return (
												<div
													className={styles.td}
													style={{ width: column.width }}
													key={column.key}
												>
													{!!column.columnRender
														? column.columnRender(row[column.key], row)
														: row[column.key]}
												</div>
											);
										})}

										{rowActions ? (
											<div
												className={classNames(
													styles['row-actions'],
													actionsDisplayForRowsId?.includes(row.id)
														? styles.display
														: '',
												)}
											>
												{rowActions.map((action: IRowAction) => {
													if (!action.actionComponent) return null;
													if (
														action.showHandler &&
														!action.showHandler(row.id)
													)
														return null;

													const ClonedAction = cloneElement(
														action.actionComponent as React.ReactElement<
															any,
															| string
															| React.JSXElementConstructor<any>
														>,
														{
															entityData: row,
															onClick: (e: MouseEvent) => {
																if (action.actionHandler)
																	action.actionHandler(row.id);
															},
														},
													);

													return ClonedAction;
												})}
											</div>
										) : null}
									</div>
								);
							})}
						</>
					) : (
						renderEmptyState()
					)}
				</div>
			</Scrollbar>
		</div>
	);
};
