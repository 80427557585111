import { FC, SetStateAction, Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType } from '../../../../../utils/types';
import {
	ActionButton,
	Table,
	TableSkeleton,
} from '../../../../primitives';
import { getTrustHubResourcesColumns } from './utils';
import { useAppDispatch } from '../../../../../services/store';
import {
	processTableData,
} from '../../../../../utils/helpers/common';
import IPartialTable from '../../../tables/common/IPartialTable';
import { ITrustHubResource } from '../../../../../services/store/slices/trust-hub.slice';

type ResourcesTableProps = IPartialTable<ITrustHubResource> & {
	selectedResources: string[],
	setResources: Dispatch<SetStateAction<ITrustHubResource[] | null>>,
	setSelectedResources: Dispatch<SetStateAction<string[]>>
	onNewResultSuccess: (message: string) => void,
	onNewResultError: (message: string) => void,
	onDeleteSelectedResources: () => void,
};

const ResourcesTable: FC<ResourcesTableProps> = ({
	data,
	dataLoading,
	setResources,
	selectedResources,
	setSelectedResources,
	onNewResultSuccess,
	onNewResultError,
	onDeleteSelectedResources,
}) => {
	const dispatch = useAppDispatch();

	const [processedData, setProcessedData] = useState(data);
	const [isProcessing, setIsProcessing] = useState(true);
	const [assignmentResult, setAssignmentResult] = useState<CommonResultStatusType>('');
	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });

	const tableColumnsConfig = useMemo(() => getTrustHubResourcesColumns(
		data,
		dispatch,
		setResources,
		onNewResultSuccess,
		onNewResultError,
	), [data, dispatch, onNewResultError, onNewResultSuccess, setResources]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	useEffect(() => {
		if (assignmentResult === 'success') {
			const delay = setTimeout(() => {
				setAssignmentResult('');
				setSelectedResources([]);
				clearTimeout(delay);
			}, 2000);
		}
	}, [assignmentResult, setSelectedResources]);

	const mainTable = useMemo(() => {
		const getHighlightedRows = () => {
			if (assignmentResult === 'success') return selectedResources;

			return [];
		};

		return (
			<Table
				tableKey="resources-table"
				data={processedData}
				columns={tableColumnsConfig}
				options={{
					rowCheckbox: true,
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Edit Resource Name'}
									type="pencil"
								/>
							),
							actionHandler: (id: string) => {
								const dataClone = structuredClone(data) as ITrustHubResource[];
								const resourceIndex = dataClone.findIndex((resource) => resource.id === Number(id));
								if (resourceIndex !== -1) {
									const resource = dataClone[resourceIndex];
									dataClone[resourceIndex] = { ...resource, editName: true };
									setResources([...dataClone]);
								}
							}
						},
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Delete Resource Name'}
									type="cancel"
								/>
							),
							actionHandler: (id: string) => {
								setSelectedResources([id]);
								onDeleteSelectedResources()
							}
						}
					],
					selectedRows: selectedResources,
					setSelectedRows: setSelectedResources,
					bodyMaxHeight: 'calc(100vh - 370px)',
					onSortChange,
					successHighlightedRows: getHighlightedRows(),
					emptyResultMessage: `Upload the documents that you’d like to be presented on Trust Hub.`,
					emptyResultMessageType: 'common',
				}}
			/>
		);
	}, [assignmentResult, data, onDeleteSelectedResources, onSortChange, processedData, selectedResources, setResources, setSelectedResources, tableColumnsConfig]);

	useEffect(() => {
		setIsProcessing(true);

		const processedData: ITrustHubResource[] = processTableData(data, {}, currentSort);

		setProcessedData(processedData);
		setIsProcessing(false);
	}, [currentSort, data]);

	return (
		<div>
			{isProcessing || dataLoading ? (
				<TableSkeleton rowCheckbox columns={tableColumnsConfig} />
			) : (
				mainTable
			)}
		</div>
	);
};

export default ResourcesTable;
