import React, { FC } from 'react';
import styles from './CompanyProfile.module.scss';
import { DataList, LoadingSkeleton } from '../../components';
import { DataListItem } from '../../components';
import classNames from 'classnames';

const CompanyProfileSkeleton: FC = () => {
	const userInfo = (
		<DataList className={styles['company-info']}>
			<LoadingSkeleton
				containerClassName={classNames(styles.skeleton, styles.logo)}
				height={80}
				width={170}
			/>

			<DataListItem
				label="Name"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Location"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Industry"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
		</DataList>
	);

	return <div className={styles['company-skeleton']}>{userInfo}</div>;
};

export default CompanyProfileSkeleton;
