import iconCheck from '../../assets/images/icon-check.svg';

export interface OverviewControlItemFullProps {
  controlName: string;
  control: string;
}

export const OverviewControlItemFull = ({ controlName, control }: OverviewControlItemFullProps) => {
  return (
    <li className="flex justify-between py-4 px-7 items-center text-xs gap-4 border-b-[1px] border-blueberry-50 last:border-b-0">
      <div>
        <div className='text-sm font-semibold mb-2'>{controlName}</div>
        <div className="text-sm inline-block">{control}</div>
      </div>
      <span className="bg-leaf-75 inline-flex items-center gap-1 rounded py-[2px] px-[8px] text-sm text-blueberry-500">
        <img className="w-[10px] h-[10px]" src={iconCheck} alt="Check icon" />
        Implemented
      </span>
    </li>
  );
};