import { FC } from 'react';
import styles from './EditPolicy.module.scss';
import classNames from 'classnames';

import {
	LoadingSkeleton,
	CommentsSkeleton,
	TagsSkeleton,
	HistorySkeleton,
} from '../../../../primitives';

const EditPolicySkeleton: FC = () => {
	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={60} />

					<LoadingSkeleton height={60} />
				</div>

				<div className={classNames(styles['input-group'], styles.trio)}>
					<LoadingSkeleton height={60} />

					<div className={styles['input-group']}>
						<LoadingSkeleton height={60} />

						<LoadingSkeleton height={60} />
					</div>
				</div>

				<LoadingSkeleton containerClassName={styles.input} height={60} />

				<div className={styles.section}>
					<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

					<LoadingSkeleton containerClassName={styles.input} height={108} />
					<LoadingSkeleton containerClassName={styles.input} height={108} />
					<LoadingSkeleton containerClassName={styles.input} height={108} />
				</div>

				<TagsSkeleton />
				<CommentsSkeleton />
				<HistorySkeleton />
			</div>
		</div>
	);

	return <div className={styles['edit-policy-skeleton']}>{editForm}</div>;
};

export default EditPolicySkeleton;
