import React, { FC, MutableRefObject, RefObject, useCallback, useEffect, useState } from 'react';
import styles from './Verify.module.scss';
import {
	IVerifyMfaCode,
	setIsMFACodeNotRequired,
	verifyMFACode,
} from '../../services/store/slices/authorization.slice';
import { useAppDispatch } from '../../services/store';
import { ActionsBlock, BasicPanel, Button } from '../../components';
import { Error } from '../../components';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { useForm } from 'react-hook-form';
import { useAuthorization } from '../../utils/helpers/hooks';
import { sanitizeData } from '../../utils/helpers/common';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import { VerifyInputs } from './VerifyInputs';

export const Verify: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { loading: authLoading, userIdForSRP, loginSession } = useAuthorization();
	const { handleSubmit } = useForm();

	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [error, setError] = useState('');

	const [formData, setFormData] = useState<IVerifyMfaCode>({
		userIdForSRP: userIdForSRP!,
		loginSession: loginSession!,
		authenticatorCode: '',
	});

	const setAuthenticatorCode = useCallback(
		(elRefs: MutableRefObject<RefObject<HTMLInputElement>[]>) => {
			setFormData((prev) => ({
				...prev,
				authenticatorCode: elRefs.current.map((input) => input.current!.value).join(''),
			}));
		},
		[],
	);

	const onFormSubmitHandler = useCallback(async () => {
		if (submitDisabled) return;

		const sanitizedData = sanitizeData(formData);

		handleWithTryCatch(() => dispatch(verifyMFACode(sanitizedData)), setError);
	}, [dispatch, formData, submitDisabled]);

	const handleEnter = useCallback(() => {
		if (submitDisabled) return;

		onFormSubmitHandler();
	}, [submitDisabled, onFormSubmitHandler]);

	const onBackClick = useCallback(() => {
		dispatch(setIsMFACodeNotRequired());
		navigate(AppUrls.login);
	}, [dispatch, navigate]);

	useEffect(() => {
		if (!userIdForSRP || !loginSession) {
			navigate(AppUrls.login);
		}
	}, [userIdForSRP, loginSession, navigate]);

	const loginForm = (
		<form className={styles.form} noValidate onSubmit={handleSubmit(onFormSubmitHandler)}>
			<p>
				To keep your account secure we verify your identity <br />
				Please enter the code generated by your authenticator app
			</p>
			<VerifyInputs
				error={error}
				handleEnter={handleEnter}
				setAuthenticatorCode={setAuthenticatorCode}
				setSubmitDisabled={setSubmitDisabled}
			/>

			{error ? <Error className={styles.error} message={error} /> : null}

			<ActionsBlock className={styles.actions}>
				<Button disabled={submitDisabled || authLoading} type="submit">
					Log In
				</Button>
			</ActionsBlock>
		</form>
	);

	return (
		<BasicPanel
			withBackNavigation
			className={styles.verify}
			withLogo
			title="2-Step Verification"
			onBackClick={onBackClick}
		>
			{loginForm}
		</BasicPanel>
	);
};
