export type CommonResultStatusType = 'success' | 'error' | '';

export interface IFormItemProps {
	error?: string;
	errorClassName?: string;
	className?: string;
	disabled?: boolean;
	withErrorStyle?: boolean;
}

export interface IFilters {
	search?: string;
	filters?: IKeyValuePair;
}

export interface IKeyValuePair {
	[key: string]: any;
}

export interface ISortProps {
	property?: string;
	direction?: 'asc' | 'desc' | '';
}

export interface ITableDataProcessingParameters {
	sort?: ISortProps;
	filters?: IKeyValuePair;
}

export interface ISelectOption {
	label: string;
	value: string;
	metaData?: IKeyValuePair;
	groupName?: string;
}

export interface IAWSPasswordRequirements {
	MinimumLength: number;
	RequireLowercase: boolean;
	RequireNumbers: boolean;
	RequireSymbols: boolean;
	RequireUppercase: boolean;
	TemporaryPasswordValidityDays: number;
}

export interface IPasswordRequirements {
	minimumLength: number;
	requireLowercase: boolean;
	requireUppercase: boolean;
	requireNumbers: boolean;
	requireSymbols: boolean;
}

export enum RoleType {
	shareholder = 'shareholder',
	clientAdmin = 'client-admin',
	gsAdmin = 'gs-admin',
}

export interface ICreateComment {
	text: string;
	entityType: string;
	entityId: string;
}

export interface IComment {
	user?: string;
	createdAt?: Date;
	text?: string;
}

export interface IVariable {
	type: string; //'Text' | 'Time' | 'Numeric'
	value?: string[];
}

export interface ICapturedValues {
	text: string;
	values: { [variableId: string]: IVariable };
}

export interface IChangeAction {
	text: string;
	variables: IHistoryVariable[];
}

export interface IChangeHistoryEntryBasic {
	id: number;
	entityId: number;
	entityOwnerId?: number;
	createdById?: string;
	entityType: string; //EntityTypes
	createdAt: Date;
	action: IChangeAction;
}

export interface IChangeHistoryEntry extends Omit<IChangeHistoryEntryBasic, 'entityId'> {
	entity: IIdName;
}

export interface IFileUploadResult {
	name: string;
	progress: number;
	isSuccess: boolean;
}

export interface IIdName {
	id: string;
	displayId?: string;
	name: string;
	type?: string;
}

export interface IGSPost {
	id: string;
	title: string;
	content: string;
	label: string;
	image: string;
}

export interface IHistoryGroupedByDateTime {
	[key: string]: IChangeHistoryEntry[];
}

export interface IHistoryVariable {
	type: string;
	value: string;
}

export enum EntityTypes {
	CompanyControl = 'CompanyControl',
	CompanyPolicy = 'CompanyPolicy',
	Asset = 'Asset',
}

export enum TrustHubStatus {
	published = 'published',
	notPublished = 'not-published',
}

export enum TrustHubAccessRequestStatus {
	pending = 'pending',
	reviewed = 'reviewed',
}

export interface IFilePreassignedData {
	file: any;
	signedUrl: string;
}
