import React from 'react';
import styles from './UserProfile.module.scss';
import { AppUrls } from '../../../utils/helpers/constants/app-routes';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useUser, useCompany } from '../../../utils/helpers/hooks';
import { getUserRoleDisplay, userNameFirstLetters } from '../../../utils/helpers/common';

const UserProfile = () => {
	const location = useLocation();
	const { info: userInfo } = useUser();
	const { info: companyInfo } = useCompany();
	const isActive = location.pathname === AppUrls.profile;

	return userInfo ? (
		<Link
			to={AppUrls.profile}
			className={classNames(styles['user-profile'], isActive ? styles.active : '')}
		>
			<div className={styles['user-block']}>
				<div className={styles.avatar}>
					{userNameFirstLetters(userInfo?.firstName, userInfo?.lastName)}
				</div>

				<div className={styles.info}>
					<div
						className={styles.name}
					>{`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}</div>

					{userInfo?.role && companyInfo?.name ? (
						<div className={styles.title}>
							{companyInfo?.name} {getUserRoleDisplay(userInfo?.role)}
						</div>
					) : null}
				</div>
			</div>
		</Link>
	) : null;
};

export default UserProfile;
