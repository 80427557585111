import React, { FC } from 'react';
import classNames from 'classnames';
import { ILoader } from './ILoader';
import { Spinner } from '../spinner';

import styles from './Loader.module.scss';

export const Loader: FC<ILoader> = React.memo(
	({
		className,
		minWidth,
		maxWidth,
		minHeight,
		maxHeight,
		thin,
		color,
		transparentColor,
		wide,
		...rest
	}) => {
		const inlineStyles = {
			minWidth,
			maxWidth,
			minHeight,
			maxHeight,
		};

		const spinnerInlineStyles =
			color && transparentColor
				? {
						borderColor: color,
						borderTopColor: transparentColor,
					}
				: {};

		return (
			<div
				style={inlineStyles}
				className={classNames(styles.loader, className, wide && styles.wide)}
				{...rest}
			>
				<Spinner
					style={spinnerInlineStyles}
					thin={thin}
					width={maxWidth}
					height={maxHeight}
				/>
			</div>
		);
	},
);
