import { FC, useCallback } from 'react';
import styles from './ModalShareTrustHub.module.scss';
import { Modal, BasicPanel, Loader, Textarea, Error } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import IModalShareTrustHub from './IModalShareTrustHub';
import { useAppDispatch } from '../../../../services/store';
import { useCompanyUsers, useCopyToClipboard } from '../../../../utils/helpers/hooks';
import { useForm } from 'react-hook-form';
import { IShareTrustHubLinkDto, shareTrustHub } from '../../../../services/store/slices/trust-hub.slice';
import { sanitizeData } from '../../../../utils/helpers/common';
import { MultiValueInput } from '../../../primitives/form/multi-value-input';
import { emailValidationPattern } from '../../../../utils/helpers/common/form';

const ModalShareTrustHub: FC<IModalShareTrustHub> = ({
	open,
	linkToCopy,
	onShareError,
	onShareSuccess,
	onCopySuccess,
	setOpen,
}) => {
	const dispatch = useAppDispatch();
	const { loading } = useCompanyUsers();
	const { copyToClipboard } = useCopyToClipboard({ linkToCopy, onCopySuccess });

	const {
		handleSubmit,
		register,
		setValue,
		formState,
		clearErrors,
		reset,
		watch,
		trigger,
	} = useForm({
		mode: 'onSubmit',
		defaultValues: {
			// We need ``email`` for validation purposes, it will be removed before sending the request
			email: '',
			emails: '',
			message: '',
			trustHubLink: linkToCopy,
		} as Partial<IShareTrustHubLinkDto>,
	});
	const { errors, isValid, isDirty, touchedFields } = formState;
	const emails = watch('emails');
	const email = watch('email');

	const onFormSubmitHandler = useCallback(async (data: Partial<IShareTrustHubLinkDto>, e: any) => {
		if (!isValid || Object.keys(errors).length) return;

		const emailsLength = data.emails?.split(',').filter((email) => email).length || 0;

		if (!emailsLength) {
			onShareError('Please add at least one email');
			return;
		}

		if (emailsLength > 5) {
			onShareError('Up to 5 people at a time');
			return;
		}

		// Remove ``email`` field before sending the request
		delete data.email;

		const sanitizedData = sanitizeData(data) as Partial<IShareTrustHubLinkDto>;

		try {
			await dispatch(shareTrustHub(sanitizedData as IShareTrustHubLinkDto));
			onShareSuccess('Trust Hub shared successfully');
		} catch (error) {
			onShareError("Error sharing Trust Hub");
		} finally {
			clearErrors();
			reset();
			setOpen(false);

		}
	}, [clearErrors, dispatch, errors, isValid, onShareError, onShareSuccess, reset, setOpen]);

	const onClose = useCallback(() => {
		reset();
		clearErrors();
		setOpen(false);
	}, [clearErrors, reset, setOpen]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={'w-[712px]'}>
				<h2 className={styles.title}>Share Trust Hub link?</h2>

				<form onSubmit={handleSubmit(onFormSubmitHandler)}>
					<MultiValueInput
						{...register('email', {
							required: false,
							...emailValidationPattern(),
						})}
						currentValue={email || ''}
						values={emails || ''}
						onChange={(e) => setValue('email', e.target.value)}
						onSetValues={(values) => {
							setValue('emails', values)
							trigger('emails')
						}}
						withErrorStyle={!!errors.emails}
						error={isDirty && !!errors.email ? 'Please enter email' : ''}
						className="flex-1 mt-6"
						type="text"
						label="Add people to send the link to"
						placeholder="Enter email"
						isValid={isValid}
						touchedFields={touchedFields}
					/>
					<input
						type="hidden"
						{...register('emails', {
							required: true,
						})}
					/>
					{isDirty && !isValid && !emails ? (
						<Error disabled={false} message={'Please add at least one email'} />
					) : null}
					<Textarea
						{...register('message')}
						onValueChange={(value) => setValue('message', value)}
						placeholder="Write your message"
						className='mt-6'
						label="Message"
						maxLength={750}
					/>

					<ActionsBlock>
						<Button
							type="button"
							width={160}
							negative
							onClick={onClose}
						>
							Cancel
						</Button>

						<div className='flex gap-4'>
							<Button type="button" width={136} negative onClick={copyToClipboard}>
								Copy link
							</Button>
							<Button
								type="submit"
								width={160}
								disabled={!isValid}
							>
								{loading ? (
									<Loader
										color={'#fcedf0'}
										transparentColor="transparent"
										thin
										maxHeight={14}
										maxWidth={14}
									/>
								) : (
									'Share now'
								)}
							</Button>
						</div>
					</ActionsBlock>
				</form>
			</BasicPanel>
		</Modal>
	);
};

export default ModalShareTrustHub;
