import { useEffect } from 'react';
import { IKeyValuePair } from '../../types';

//for using React Widgets and React Hook Forms together
export function useFormItemValues(
	setValue: any,
	values: IKeyValuePair,
) {
	useEffect(() => {
		for (const [key, value] of Object.entries(values)) {
			setValue(key, value);
		}
	}, [setValue, values]);
}
