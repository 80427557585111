import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	CollapsiblePanel,
	Drawer,
	SectionTitle,
	Scrollbar,
	ActionsBlock,
	Button,
	Loader,
	Error,
} from '../../../primitives';
import { IEditTrustHub } from './IEditTrustHub';
import { useForm } from 'react-hook-form';
import { sanitizeData } from '../../../../utils/helpers/common';
import styles from './EditTrustHub.module.scss';
import { EditPrivacyPolicy } from './privacy-policy';
import { ResourcesBlock } from './resources-block';
import { FrameworksBlock } from './frameworks-block';
import ChangesHistoryBlock from '../../changes-history-block/ChangesHistoryBlock';
import {
	useTrustHub,
	useLeaveWarning,
	useCompany,
	useTrustHubLinkToCopy
} from '../../../../utils/helpers/hooks';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import { useAppDispatch } from '../../../../services/store';
import { EditTrustHubSkeleton } from './EditTrustHubSkeleton';
import ModalDeleteTrustHubResource from '../../modals/modal-delete-trust-hub-resource/ModalDeleteTrustHubResource';
import { TrustHubStatus } from '../../../../utils/types';
import { EditTrustHubDetails } from './details';
import ModalShareTrustHub from '../../modals/modal-share-trust-hub/ModalShareTrustHub';
import { createTrustHub, getTrustHub, ICreateTrustHub, ITrustHub, updateTrustHub } from '../../../../services/store/slices/trust-hub.slice';
import { getPreassignedUrlForPolicy } from '../../../../services/store/slices/company.slice';
import { Tooltip } from 'react-tooltip';

export const EditTrustHub: FC<IEditTrustHub> = ({
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { item: trustHub, loading, updateLoading, createLoading, frameWorksEvaluations, resources } = useTrustHub();
	const { info: companyInfo } = useCompany();
	const {
		handleSubmit,
		register,
		setValue,
		formState,
		clearErrors,
		reset,
		trigger,
		getValues,
		watch,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			id: trustHub?.id,
			email: trustHub?.email,
			status: trustHub?.status || TrustHubStatus.notPublished,
			description: trustHub?.description,
			privacyPolicyId: trustHub?.privacyPolicyId ?? null,
			resourcesIds: resources?.map(r => r.id) ?? [],
			frameworks: trustHub?.frameworks || [],
		} as Partial<ITrustHub>
	});
	const { errors, isValid } = formState;
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [selectedResources, setSelectedResources] = useState<string[]>([]);
	const [deleteResourcesModalOpen, setDeleteResourcesModalOpen] = useState(false);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const { linkToCopy } = useTrustHubLinkToCopy(window.location.origin, companyInfo?.slug);
	const status = watch('status');
	const email = watch('email');
	const description = watch('description');
	const frameworks = watch('frameworks');
	const privacyPolicyId = watch('privacyPolicyId');
	const resourcesIds = watch('resourcesIds');

	const canBeViewedOrShared = useMemo(() => !updateLoading && !createLoading && trustHub?.status === TrustHubStatus.published && trustHub?.id, [createLoading, trustHub, updateLoading]);

	const isSubmitDisabled = useMemo(() => {
		return (updateLoading || createLoading || !isValid) && !trustHub?.status;
	}, [createLoading, isValid, trustHub?.status, updateLoading]);

	useLeaveWarning(showBrowserLeaveWarning);

	const namesToDelete = useMemo(() => {
		return (selectedResources?.map((id) => trustHub?.resources?.find(r => r.id === parseInt(id))?.name) || []) as string[];
	}, [selectedResources, trustHub]);

	const onDeleteSelectedResources = useCallback(() => {
		setDeleteResourcesModalOpen(true);
	}, [setDeleteResourcesModalOpen]);

	const onShareTrustHub = useCallback(() => {
		setShareModalOpen(true);
	}, [setShareModalOpen]);

	const changesWereMade = useMemo(() => {
		const currentResourcesIds = (resourcesIds || []).sort();
		const storedResourcesIds = (trustHub?.resources?.map(r => r.id) || []).sort();

		const currentFrameworks = (frameworks || []).map(f => f.id).sort();
		const storedFrameworks = (trustHub?.frameworks || []).map(f => f.id).sort();

		return !!(
			status !== trustHub?.status ||
			email !== trustHub?.email ||
			description !== trustHub?.description ||
			privacyPolicyId !== trustHub?.privacyPolicyId ||
			currentFrameworks?.join('') !== storedFrameworks.join('') ||
			currentResourcesIds.join('') !== storedResourcesIds.join('')
		);
	}, [trustHub, status, email, description, privacyPolicyId, frameworks, resourcesIds]);

	const onFormSubmitHandler = useCallback(async (data: Partial<ITrustHub>) => {
		if (Object.keys(errors).length) return;

		if (updateLoading || createLoading) return;

		const sanitizedData = sanitizeData(data) as Partial<ITrustHub>;

		try {
			let trustHuUpdateMessage = 'Trust Hub updated successfully';
			if (trustHub?.id) {
				await dispatch(updateTrustHub(sanitizedData as ITrustHub));
			} else {
				await dispatch(createTrustHub(sanitizedData as ICreateTrustHub));
				trustHuUpdateMessage = 'Trust Hub created successfully';
			}
			await dispatch(getTrustHub(true));
			onUpdateSuccess(trustHuUpdateMessage);
		} catch (error) {
			onUpdateError("Error updating Trust Hub");
		} finally {
			closeHandler();
		}
	}, [closeHandler, createLoading, dispatch, errors, onUpdateError, onUpdateSuccess, updateLoading, trustHub]);

	const onViewTrustHub = useCallback(() => {
		window.open(linkToCopy, '_blank');
	}, [linkToCopy]);

	useEffect(() => {
		if (changesWereMade) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			clearErrors();
			setBrowserShowLeaveWarning(false);
		}
	}, [open, reset, clearErrors]);

	useEffect(() => {
		if (open && trustHub?.id) {
			setValue('id', trustHub.id);
			setValue('email', trustHub.email);
			setValue('status', trustHub.status);
			setValue('description', trustHub.description);
			setValue('privacyPolicyId', trustHub.privacyPolicyId);
			setValue('resourcesIds', trustHub.resources?.map(r => r.id) || []);
			setValue('resourcesIds', trustHub.resources?.map(r => r.id) || []);
			setValue('frameworks', trustHub.frameworks);
		}
	}, [open, trustHub, setValue]);

	const renderDetails = useMemo(() => (
		<EditTrustHubDetails
			errors={errors}
			triggerHook={trigger}
			register={register}
			setValue={setValue}
			getValues={getValues}
		/>
	), [errors, getValues, register, setValue, trigger]);

	const renderEditPrivacyPolicy = useMemo(() => {
		const trigger = <SectionTitle className="!my-0">Privacy Policy</SectionTitle>;
		return (
			<CollapsiblePanel trigger={trigger}>
				<EditPrivacyPolicy
					predefinedPolicyFiles={trustHub?.privacyPolicy ? [trustHub.privacyPolicy] : null}
					getPreassignedUrlForPolicyCall={getPreassignedUrlForPolicy}
					setPrivacyPolicyFilesId={(id) => {
						setValue('privacyPolicyId', id || null);
					}}
				/>
			</CollapsiblePanel>
		);
	}, [trustHub, setValue]);

	const renderResourcesBlock = useMemo(() => {
		const trigger = <SectionTitle className="!my-0">Resources</SectionTitle>;
		return (
			<CollapsiblePanel trigger={trigger}>
				<ResourcesBlock
					predefinedResources={resources ? resources : null}
					getPreassignedUrlForPolicyCall={getPreassignedUrlForPolicy}
					onUpdateError={onUpdateError}
					onUpdateSuccess={onUpdateSuccess}
					setResourcesIds={(ids) => {
						setValue('resourcesIds', ids);
					}}
					onDeleteSelectedResources={onDeleteSelectedResources}
					selectedResources={selectedResources}
					setSelectedResources={setSelectedResources}
				/>
				{
					resources?.length === 0 &&
					<Error
						message='TrustHub will be automatically unpublished if no resources uploaded. Please ensure at least one resource is uploaded.'
					/>
				}
			</CollapsiblePanel>
		);
	}, [resources, onUpdateError, onUpdateSuccess, onDeleteSelectedResources, selectedResources, setValue]);

	const renderFrameworksBlock = useMemo(() => {
		const trigger = <SectionTitle className="!my-0">Frameworks</SectionTitle>;
		return (
			<CollapsiblePanel trigger={trigger}>
				<FrameworksBlock
					frameWorksEvaluations={frameWorksEvaluations}
					preSelectedFrameworks={trustHub?.frameworks || []}
					setFrameworks={(frameworks) => {
						setValue('frameworks', frameworks);
					}}
				/>
				{
					frameworks?.length === 0 &&
					<Error
						className='!mt-4'
						message='TrustHub will be automatically unpublished if no frameworks are selected. Please ensure at least one framework is chosen.'
					/>
				}
			</CollapsiblePanel>
		);
	}, [frameWorksEvaluations, setValue, frameworks?.length, trustHub?.frameworks]);

	const renderChangesHistory = useMemo(() => {
		const trigger = <SectionTitle>History</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<ChangesHistoryBlock changes={trustHub?.history} />
			</CollapsiblePanel>
		);
	}, [trustHub]);

	const formActions = useMemo(
		() => (
			<ActionsBlock>
				<div
					data-tooltip-id='trust-hub-share-edit'
					data-tooltip-content={!canBeViewedOrShared ? 'Publish your Trust Hub to be able to share it with others' : ''}
				>
					<Button
						type="button"
						width={136}
						onClick={onShareTrustHub}
						negative
						disabled={!canBeViewedOrShared}
					>
						Share Now
					</Button>
				</div>

				<div className='flex gap-4'>
					<Button type="button" width={136} negative disabled={!canBeViewedOrShared} onClick={onViewTrustHub}>
						View
					</Button>
					<Button type="submit" width={136} disabled={isSubmitDisabled}>
						{updateLoading || createLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
					</Button>
				</div>
			</ActionsBlock>
		),
		[canBeViewedOrShared, createLoading, isSubmitDisabled, onShareTrustHub, onViewTrustHub, updateLoading],
	);

	const modals = useMemo(() => (
		<>
			<ModalDeleteTrustHubResource
				open={deleteResourcesModalOpen}
				names={namesToDelete}
				setOpen={setDeleteResourcesModalOpen}
				selectedResources={selectedResources}
				onDeleteError={onUpdateError}
				onDeleteSuccess={onUpdateSuccess}
				setSelectedResources={setSelectedResources}
			/>

			<ModalShareTrustHub
				open={shareModalOpen}
				setOpen={setShareModalOpen}
				linkToCopy={linkToCopy}
				onCopySuccess={onUpdateSuccess}
				onShareError={onUpdateError}
				onShareSuccess={onUpdateSuccess}
			/>

			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>

		</>
	), [closeHandler, deleteResourcesModalOpen, linkToCopy, namesToDelete, onUpdateError, onUpdateSuccess, selectedResources, shareModalOpen, warningModalOpen]);

	const editForm = useMemo(() => (
		<form onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails}
					{renderEditPrivacyPolicy}
					{renderResourcesBlock}
					{renderFrameworksBlock}
					{renderChangesHistory}
				</div>
			</Scrollbar>
			{formActions}
		</form>
	), [handleSubmit, onFormSubmitHandler, renderDetails, renderEditPrivacyPolicy, renderResourcesBlock, renderFrameworksBlock, renderChangesHistory, formActions]);

	return (
		<Drawer
			open={open}
			title="Edit Trust Hub"
			onCloseClickHandler={() => {
				if (changesWereMade) setWarningModalOpen(true);
				else if (closeHandler) closeHandler();
			}}
		>
			{loading ? <EditTrustHubSkeleton /> : <div className={styles['edit-control']}>
				{editForm}
				{modals}
			</div>}
			<Tooltip
				id={'trust-hub-share-edit'}
			/>
		</Drawer>
	);
};
