import { FC, useEffect, useMemo, useState } from 'react';
import styles from './TagsAndControlsBlock.module.scss';
import classNames from 'classnames';
import ITagsAndControlsBlock from './ITagsAndControlsBlock';
import { useControls, useTags } from '../../../utils/helpers/hooks';
import { Button, ColoredTag, RemoveAction, HoverTooltip } from '../../primitives';
import { CommonColors } from '../../../utils/helpers/constants';
import { IControl } from '../../../services/store/slices/controls.slice';
import ModalAddControls from '../modals/modal-add-controls/ModalAddControls';
import { ITag, ITagBrief } from '../../../services/store/slices/tags.slice';
import ModalAddTagWithControls from '../modals/modal-add-tag-with-controls/ModalAddTagWithControls';
import { IIdName } from '../../../utils/types';
import { mergeUniqueObjsById } from '../../../utils/helpers/common';

const TagsAndControlsBlock: FC<ITagsAndControlsBlock> = ({
	className,
	entityControlIds,
	entityTagIds,
	setControlIds,
	setTagIds,
	isViewMode,
	externalAddedControlIds,
	clearExternalAddedControlIds,
}) => {
	const { items: controlItems } = useControls();
	const { items: tagItems } = useTags();

	const [openAddControlsModal, setOpenAddControlsModal] = useState(false);
	const [openAddTagModal, setOpenAddTagModal] = useState(false);
	const [addedControlIds, setAddedControlIds] = useState<string[]>([]);

	const entityControlObjs = controlItems?.filter((control) => {
		return entityControlIds?.some((c) => c.id === control.id);
	});
	const filteredControlsWithTags = entityControlObjs?.filter((control) => control.tags?.length);
	const filteredControlsWithoutTags = entityControlObjs?.filter(
		(control) => !control.tags?.length,
	);

	const onRemoveTags = (tagsGroupKey: string) => {
		if (isViewMode) return;

		let tagIds = tagsGroupKey.split('|');
		tagIds = tagIds.filter((tag) => tag);

		const newTags = entityTagIds?.filter((tag) => !tagIds?.includes(tag.id));

		if (setTagIds) setTagIds(newTags);
	};

	const onRemoveControls = (controls: IControl[], tagsGroupKey?: string) => {
		if (isViewMode) return;

		const newControls = entityControlIds?.filter(
			(c) => !controls.find((control) => control.id === c.id),
		);

		if (setControlIds) setControlIds(newControls);

		if (tagsGroupKey) onRemoveTags(tagsGroupKey);
	};

	const onRemoveOneControl = (controlId: string) => {
		if (isViewMode) return;

		const newControls = entityControlIds?.filter((control) => control.id !== controlId);

		if (setControlIds) setControlIds(newControls);
	};

	const groupControlsByTags = useMemo(() => {
		if (!filteredControlsWithTags?.length && !entityTagIds.length) return [];

		const groupedControls: { tagsGroupKey: string; controls: IControl[] }[] = [];

		filteredControlsWithTags?.forEach((control: IControl) => {
			const tagsGroupKey = '|' + control.tags?.map((t) => t.id).join('|') + '|' || '';

			const existingGroup = groupedControls.find(
				(group) => group.tagsGroupKey === tagsGroupKey,
			);

			if (existingGroup) existingGroup.controls.push(control);
			else {
				groupedControls.push({
					tagsGroupKey,
					controls: [control],
				});
			}
		});

		const tagsNotInGroups = entityTagIds?.filter(
			(tag) => !groupedControls.find((group) => group.tagsGroupKey.includes(`|${tag.id}|`)),
		);

		if (tagsNotInGroups?.length) {
			tagsNotInGroups.forEach((tag) => {
				groupedControls.push({
					tagsGroupKey: tag.id,
					controls: [],
				});
			});
		}

		return groupedControls;
	}, [entityTagIds, filteredControlsWithTags]);

	const renderTagGroups = () => {
		return groupControlsByTags?.map((group: { tagsGroupKey: string; controls: IControl[] }) => {
			const { tagsGroupKey, controls } = group;

			const tagIds = (controls?.length && controls[0].tags?.map((t) => t.id)) || [
				tagsGroupKey,
			];
			const tags = tagIds.map((tagId) => tagItems?.find((tag) => tag.id === tagId)) || [];

			return (
				<div
					key={tagsGroupKey}
					className={classNames(
						styles['tag-group'],
						isViewMode ? styles['no-hover'] : '',
					)}
				>
					{!isViewMode ? (
						<RemoveAction
							tooltipId={controls.length ? 'tooltip-remove-warning' : ''}
							tooltipContent={
								controls.length
									? `This will remove all controls tagged with ${tags.length > 1 ? 'these tags' : 'this tag'}`
									: ''
							}
							className={styles.remove}
							onClickHandler={
								controls.length
									? () => onRemoveControls(controls, tagsGroupKey)
									: () => onRemoveTags(tagsGroupKey)
							}
							text={tags.length > 1 ? 'Remove Tags' : 'Remove Tag'}
						/>
					) : null}

					<div className={styles['block-title']}>
						<h5 className={styles['title']}>Tagged</h5>

						{tags?.length &&
							tags.map((tag: ITag | undefined) => (
								<ColoredTag
									tagId={tag?.id || ''}
									key={tag?.id}
									text={tag?.name || ''}
									bgColor={tag?.color}
									withoutBackground
								/>
							))}
					</div>

					<div className={styles['controls']}>
						{controls.length ? (
							controls.map((control) => {
								return (
									<ColoredTag
										tagId={control.id}
										key={control.id}
										text={control.controlId}
										bgColor={CommonColors.defaultTagBg}
										withoutBackground
										tooltipId={'tooltip-control-name'}
										tooltipContent={control.name || ''}
										withRemoveOnHover={!isViewMode}
										onRemoveClickHandler={
											!isViewMode
												? () => onRemoveOneControl(control.id)
												: undefined
										}
										className={classNames(
											styles.tag,
											addedControlIds.length &&
												addedControlIds.includes(control.id)
												? styles['added-control']
												: '',
										)}
									/>
								);
							})
						) : (
							<p className={styles.empty}>
								You don't have any controls under this tag.
							</p>
						)}
					</div>
				</div>
			);
		});
	};

	const renderUntaggedControls = () => {
		if (filteredControlsWithoutTags?.length)
			return (
				<>
					<div
						className={classNames(
							styles['tag-group'],
							isViewMode ? styles['no-hover'] : '',
						)}
					>
						{!isViewMode ? (
							<RemoveAction
								className={styles.remove}
								onClickHandler={() => onRemoveControls(filteredControlsWithoutTags)}
								text="Remove All"
							/>
						) : null}

						<div className={styles['block-title']}>
							<h5 className={styles['title']}>Untagged</h5>
						</div>

						<div className={styles['controls']}>
							{filteredControlsWithoutTags?.map((control) => {
								return (
									<ColoredTag
										tagId={control.id}
										key={control.id}
										text={control.controlId}
										bgColor={CommonColors.defaultTagBg}
										withoutBackground
										tooltipId={'tooltip-control-name'}
										tooltipContent={control.name || ''}
										withRemoveOnHover={!isViewMode}
										onRemoveClickHandler={
											!isViewMode
												? () => onRemoveOneControl(control.id)
												: undefined
										}
										className={classNames(
											styles.tag,
											addedControlIds.length &&
												addedControlIds.includes(control.id)
												? styles['added-control']
												: '',
										)}
									/>
								);
							})}
						</div>
					</div>
				</>
			);
	};

	const onNewControlsAdded = (newControls: IIdName[]) => {
		setAddedControlIds(newControls.map((c) => c.id));

		const controlsTags = controlItems
			?.filter((control) => {
				return newControls.some((c) => c.id === control.id);
			})
			.map((control) => control.tags)
			.flat() as ITagBrief[];

		if (setControlIds) {
			const uniqueEntries = mergeUniqueObjsById(entityControlIds, newControls);
			setControlIds(uniqueEntries as IIdName[]);
		}

		if (setTagIds) {
			const uniqueEntries = mergeUniqueObjsById(entityTagIds, controlsTags);
			setTagIds(uniqueEntries as ITagBrief[]);
		}
	};

	const onNewTagAdded = (newTag: ITagBrief) => {
		if (setTagIds) setTagIds([...entityTagIds, newTag]);
	};

	const renderEmptyState = () => {
		if (!entityControlIds?.length && !entityTagIds.length)
			return (
				<>
					{isViewMode ? (
						<p className={styles.empty}>
							You don't have any controls assigned to this policy.
						</p>
					) : (
						<p className={styles.empty}>
							You don't have any controls assigned to this policy. Click "Add Control"
							or "Add Tag" to add a control.
						</p>
					)}
				</>
			);
	};

	const renderActions = () => {
		return (
			<div className={styles.actions}>
				<Button
					type="button"
					negative
					small
					width={104}
					className={styles.action}
					onClick={() => setOpenAddControlsModal(true)}
				>
					Add Control
				</Button>

				<Button
					type="button"
					negative
					small
					width={82}
					className={styles.action}
					onClick={() => setOpenAddTagModal(true)}
				>
					Add Tag
				</Button>
			</div>
		);
	};

	const modals = (
		<>
			<ModalAddControls
				open={openAddControlsModal}
				setOpen={setOpenAddControlsModal}
				entityControlIds={entityControlIds}
				setEntityControls={setControlIds!}
				onNewAdded={onNewControlsAdded}
			/>

			<ModalAddTagWithControls
				open={openAddTagModal}
				setOpen={setOpenAddTagModal}
				entityControlIds={entityControlIds}
				entityTagIds={entityTagIds}
				setEntityTags={setTagIds!}
				setEntityControls={setControlIds!}
				onNewControlsAdded={onNewControlsAdded}
				onNewTagAdded={onNewTagAdded}
			/>
		</>
	);

	useEffect(() => {
		if (addedControlIds.length) {
			const addDelay = setTimeout(() => setAddedControlIds([]), 2000);

			return () => clearTimeout(addDelay);
		}
	}, [addedControlIds]);

	useEffect(() => {
		if (externalAddedControlIds?.length) {
			setAddedControlIds(externalAddedControlIds);
			if (clearExternalAddedControlIds) clearExternalAddedControlIds();
		}
	}, [clearExternalAddedControlIds, externalAddedControlIds]);

	return (
		<div className={classNames(styles['tags-and-controls-block'], className)}>
			{renderEmptyState()}

			{renderTagGroups()}
			{renderUntaggedControls()}

			{!isViewMode ? (
				<>
					{renderActions()}

					{modals}
				</>
			) : null}

			<HoverTooltip tooltipId="tooltip-control-name" place="bottom-start" />
			<HoverTooltip warning withIcon tooltipId="tooltip-remove-warning" />
		</div>
	);
};

export default TagsAndControlsBlock;
