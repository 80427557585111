import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import trustHubApi from '../../api/trust-hub.api';
import {
	IChangeHistoryEntry,
	IFilePreassignedData,
	TrustHubAccessRequestStatus,
	TrustHubStatus,
} from '../../../utils/types';
import { TrustHubAccessRequestType } from '../../../utils/helpers/constants';
import { ICompany } from './company.slice';
import { IFramework } from './frameworks.slice';
import { IUser } from './user.slice';

export interface ITrustHubSlice {
	loading?: boolean;
	createLoading?: boolean;
	updateLoading?: boolean;
	shareLoading?: boolean;
	resourceLoading?: boolean;
	trustHubRequestAccessItemsLoading?: boolean;
	error?: string | null;
}

export interface ITrustHubFrameworksEvaluations {
	frameworkTo: IFramework;
	percentage: number;
	evaluatedAt: Date;
}

export interface ITrustHubResource {
	id: number;
	name: string;
	file: File;
	fileName: string | null;
	fileId: number | null;
	uploadedAt: Date;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date | null;
	editName?: boolean;
	markForDelete?: boolean;
}

export interface ITrustHub {
	id: number;
	status: TrustHubStatus;
	email: string;
	description: string;
	updatedAt?: Date;
	privacyPolicyId?: number | null;
	privacyPolicy?: File | null;
	resourcesIds?: number[] | null;
	resources?: ITrustHubResource[] | null;
	history?: IChangeHistoryEntry[];
	frameworks?: IFramework[] | null;
	// companyId: number;
	// createdAt: Date;
	// updatedAt: Date;
	// deletedAt: Date | null;
}

export interface IShareTrustHubLinkDto {
	email?: string;
	message?: string;
	emails: string;
	trustHubLink: string;
}

export interface TrustHubAccessRequests {
	id: number;
	name: string;
	email: string;
	companyName: string;
	requestType: TrustHubAccessRequestType;
	trustHubAccessRequestResources: TrustHubAccessRequestResource[];
	company: ICompany;
	companyId: number;
	status: TrustHubAccessRequestStatus;
	owner?: IUser;
	createdAt: Date;
	updatedAt: Date;
	requestAt: Date;
}

export interface TrustHubAccessRequestResource {
	id: number;
	trustHubAccessRequest: TrustHubAccessRequests;
	trustHubResource: ITrustHubResource;
	approved: boolean;
	createdAt: Date;
}

export interface ITrustHubRequestAccessDto {
	name: string;
	email: string;
	companyName: string;
	requestTypeSelected?: string;
	requestTypeIndividualDocuments?: ITrustHubResource[];
	requestType: TrustHubAccessRequestType;
	trustHubResources?: ITrustHubResource[];
}

export type ICreateTrustHub = Pick<
	ITrustHub,
	'email' | 'status' | 'description' | 'updatedAt' | 'privacyPolicyId' | 'resourcesIds'
>;

export interface ITrustHubInfo extends ITrustHubSlice {
	item: ITrustHub | null;
	reviewedTrustHubRequestAccessItems: ITrustHubRequestAccessDto[] | null;
	trustHubRequestAccessItems: ITrustHubRequestAccessDto[] | null;
	frameWorksEvaluations: ITrustHubFrameworksEvaluations[] | null;
	resources: ITrustHubResource[] | null;
}

const initialState: ITrustHubInfo = {
	loading: false,
	createLoading: false,
	updateLoading: false,
	resourceLoading: false,
	item: null,
	reviewedTrustHubRequestAccessItems: null,
	trustHubRequestAccessItems: null,
	trustHubRequestAccessItemsLoading: false,
	frameWorksEvaluations: null,
	resources: null,
	error: null,
};

export const trustHubSlice = createSlice({
	name: 'trustHub',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		createLoading: (state) => {
			state.createLoading = true;
		},
		createLoaded: (state) => {
			state.createLoading = false;
		},
		shareLoading: (state) => {
			state.shareLoading = true;
		},
		shareLoaded: (state) => {
			state.shareLoading = false;
		},
		setTrustHubError: (state) => {
			state.error = 'Error while getting trust hub';
		},
		setTrustHubItem: (state, { payload }: PayloadAction<ITrustHub>) => {
			state.item = payload;
		},
		setPolicy: (state, { payload }: PayloadAction<number>) => {
			if (state.item) state.item.privacyPolicyId = payload;
		},
		setTrustHubRequestAccessItems: (
			state,
			{ payload }: PayloadAction<ITrustHubRequestAccessDto[]>,
		) => {
			state.trustHubRequestAccessItems = payload;
		},
		setTrustHubRequestAccessItemsLoading: (state) => {
			state.trustHubRequestAccessItemsLoading = true;
		},
		setTrustHubRequestAccessItemsLoaded: (state) => {
			state.trustHubRequestAccessItemsLoading = false;
		},
		setReviewedTrustHubRequestAccessItems: (
			state,
			{ payload }: PayloadAction<ITrustHubRequestAccessDto[]>,
		) => {
			state.reviewedTrustHubRequestAccessItems = payload;
		},
		setFrameworksEvaluations: (
			state,
			{ payload }: PayloadAction<ITrustHubFrameworksEvaluations[]>,
		) => {
			state.frameWorksEvaluations = payload;
		},
		setResources: (state, { payload }: PayloadAction<ITrustHubResource[]>) => {
			state.resources = payload;
		},
		setResourcesLoading: (state) => {
			state.resourceLoading = true;
		},
		setResourcesLoaded: (state) => {
			state.resourceLoading = false;
		},
	},
});

export const getTrustHub = (withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
	if (withLoading) dispatch(loading());

	try {
		const trustHub = await trustHubApi.getTrustHub();

		dispatch(setTrustHubItem(trustHub));
	} catch (error) {
		dispatch(setTrustHubError());
		throw new CommonResponseError(error as string);
	} finally {
		dispatch(loaded());
	}
};

export const getResources = (withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
	if (withLoading) dispatch(setResourcesLoading());

	try {
		const resources = await trustHubApi.getResources();
		dispatch(setResources(resources));
	} catch (error) {
		dispatch(setTrustHubError());
		throw new CommonResponseError(error as string);
	} finally {
		dispatch(setResourcesLoaded());
	}
}

export const getPublicTrustHub =
	(companySlug: string, withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const trustHub = await trustHubApi.getPublicTrustHub(companySlug);

			dispatch(setTrustHubItem(trustHub));
		} catch (error) {
			dispatch(setTrustHubError());
			throw new CommonResponseError(error as string);
		} finally {
			dispatch(loaded());
		}
	};

export const updateTrustHub = (data: ITrustHub) => async (dispatch: Dispatch<any>) => {
	dispatch(updateLoading());

	try {
		const trustHub = await trustHubApi.updateTrustHub(data);
		dispatch(setTrustHubItem(trustHub));
	} catch (error) {
		dispatch(setTrustHubError());
		throw new CommonResponseError('Error while updating trust hub');
	} finally {
		dispatch(updateLoaded());
	}
};

export const updateResourceName =
	(resourceId: number, name: string) => async (dispatch: Dispatch<any>) => {
		try {
			const resource = await trustHubApi.updateResourceName(resourceId, name);
			return resource;
		} catch (error: any) {
			throw new CommonResponseError('Error while updating resource name');
		}
	};

export const getPreassignedUrlForPolicy = (fileName: string) => async () => {
	try {
		const result: IFilePreassignedData = await trustHubApi.getPreassignedUrlForPolicy(fileName);
		return result;
	} catch (error: any) {
		if (error && error.message && error.message.includes('type'))
			throw new CommonResponseError(error.message);

		throw new CommonResponseError('Error while getting preassigned url for policy');
	}
};

export const createResourceFile = (fileId: number) => async () => {
	try {
		const result = await trustHubApi.createResourceFile(fileId);
		return result as ITrustHubResource;
	} catch (error: any) {
		throw new CommonResponseError('Error while creating policy file.');
	}
};

export const deleteResourceFile = (resourceId: number) => async () => {
	try {
		return await trustHubApi.deleteResourceFile(resourceId);
	} catch (error: any) {
		throw new CommonResponseError('Error while removing policy file.');
	}
};

export const massDeleteResourceFiles = (resourceId: string[]) => async () => {
	try {
		return await trustHubApi.massDeleteResourceFiles(resourceId);
	} catch (error: any) {
		throw new CommonResponseError('Error while removing policy file.');
	}
};

export const createTrustHub = (data: ICreateTrustHub) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const trustHub = await trustHubApi.createTruestHub(data);
		const { email, status, description } = data;
		await dispatch(
			setTrustHubItem({
				id: trustHub.id,
				email,
				status,
				description,
			}),
		);
	} catch (error: any) {
		dispatch(setTrustHubError());
		throw new CommonResponseError('Error while creating trust hub');
	} finally {
		dispatch(loaded());
	}
};

export const shareTrustHub = (data: IShareTrustHubLinkDto) => async (dispatch: Dispatch<any>) => {
	dispatch(shareLoading());

	try {
		await trustHubApi.shareTrustHub(data);
	} catch (error: any) {
		throw new CommonResponseError('Error while sharing trust hub');
	} finally {
		dispatch(shareLoaded());
	}
};

export const requestAccess =
	(companySlug: string, data: ITrustHubRequestAccessDto) => async (dispatch: Dispatch<any>) => {
		dispatch(shareLoading());

		try {
			await trustHubApi.requestAccess(companySlug, data);
		} catch (error: any) {
			throw new CommonResponseError('Error while sharing trust hub');
		} finally {
			dispatch(shareLoaded());
		}
	};

export const getRequestAccessItems = (withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
	if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());

	try {
		const result = await trustHubApi.getRequestAccessItems();
		dispatch(setTrustHubRequestAccessItems(result));
	} catch (error: any) {
		throw new CommonResponseError('Error while getting request access items');
	} finally {
		if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());
	}
};

export const getPendingTrustHubRequestAccessItems =
	(withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());

		try {
			const result = await trustHubApi.getPendingTrustHubRequestAccessItems();
			dispatch(setTrustHubRequestAccessItems(result));
		} catch (error: any) {
			throw new CommonResponseError('Error while getting request access items');
		} finally {
			if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());
		}
	};

export const getReviewedTrustHubRequestAccessItems =
	(withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());

		try {
			const result = await trustHubApi.getReviewedTrustHubRequestAccessItems();
			dispatch(setReviewedTrustHubRequestAccessItems(result));
		} catch (error: any) {
			throw new CommonResponseError('Error while getting request access items');
		} finally {
			if (withLoading) dispatch(setTrustHubRequestAccessItemsLoading());
		}
	};

export const getTrustHubRequestAccessItem = (id: number) => async () => {
	try {
		const result = await trustHubApi.getTrustHubRequestAccessItem(id);
		return result;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting request access item');
	}
};

export const updateTrustHubRequestAccessItem =
	(id: string, data: { trustHubAccessRequestResources: TrustHubAccessRequestResource[] }) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());
		try {
			const result = await trustHubApi.updateTrustHubRequestAccessItem(id, data);
			return result;
		} catch (error: any) {
			throw new CommonResponseError('Error while updating request access item');
		}	finally {
			dispatch(updateLoaded());
		}
	};

export const getFrameworksEvaluations = (withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
	if (withLoading) dispatch(loading());

	try {
		const result = await trustHubApi.getFrameworksEvaluations();
		dispatch(setFrameworksEvaluations(result));
		return result;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting frameworks evaluations');
	} finally {
		if (withLoading) dispatch(loaded());
	}
}

export const runFrameworksEvaluationForCompany = (companyId: number) => async () => {
	try {
		const result = await trustHubApi.runFrameworksEvaluationForCompany(companyId);
		return result;
	} catch (error: any) {
		throw new CommonResponseError('Error while running frameworks evaluation');
	}
}

export const getPublicResourcePath = (resourceId: number, token: string) => async () => {
	try {
		const result = await trustHubApi.getPublicResourcePath(resourceId, token);
		return result;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting resource path');
	}
}

export const {
	loading,
	loaded,
	updateLoaded,
	updateLoading,
	createLoaded,
	createLoading,
	setTrustHubItem,
	setTrustHubError,
	shareLoaded,
	shareLoading,
	setTrustHubRequestAccessItems,
	setTrustHubRequestAccessItemsLoading,
	setTrustHubRequestAccessItemsLoaded,
	setReviewedTrustHubRequestAccessItems,
	setFrameworksEvaluations,
	setResources,
	setResourcesLoading,
	setResourcesLoaded,
} = trustHubSlice.actions;
export default trustHubSlice.reducer;
