import { ActionButton, Input } from '../../../../primitives';
import { ITrustHubResource, updateResourceName } from '../../../../../services/store/slices/trust-hub.slice';
import { ColoredTag, ITableColumn } from '../../../../primitives';
import { Dispatch, SetStateAction } from 'react';

export const getTrustHubResourcesColumns = (
	data: ITrustHubResource[],
	dispatch: any,
	setResources: Dispatch<SetStateAction<ITrustHubResource[] | null>>,
	onNewResultSuccess: (message: string) => void,
	onNewResultError: (message: string) => void,
) => {
	return [
		{
			header: 'Display Name',
			key: 'name',
			sorting: true,
			width: '70%',
			columnRender: (value: string, rowData: ITrustHubResource) => {

				const onResourceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
					const dataClone = structuredClone(data) as ITrustHubResource[];
					const resourceIndex = dataClone.findIndex((resource) => resource.id === Number(rowData.id));

					if (resourceIndex !== -1) {
						const resource = dataClone[resourceIndex];
						dataClone[resourceIndex] = { ...resource, name: e.target.value };
						setResources([...dataClone]);
					}
				}

				const closeResourceNameEditing = () => {
					const dataClone = structuredClone(data) as ITrustHubResource[];
					const resourceIndex = dataClone.findIndex((resource) => resource.id === Number(rowData.id));
					if (resourceIndex !== -1) {
						const resource = dataClone[resourceIndex];
						dataClone[resourceIndex] = { ...resource, editName: false };
						setResources([...dataClone]);
					}
				};

				const saveResourceName = async () => {
					const dataClone = structuredClone(data) as ITrustHubResource[];
					const resourceIndex = dataClone.findIndex((resource) => resource.id === Number(rowData.id));
					if (resourceIndex !== -1) {
						const resource = dataClone[resourceIndex];
						dataClone[resourceIndex] = { ...resource, editName: false };
						setResources([...dataClone]);
						try {
							await dispatch(updateResourceName(rowData.id, value));
							onNewResultSuccess('Resource name saved successfully');
						} catch (error) {
							onNewResultError('Error saving resource name');
						}
					}
				};


				if (rowData.editName) {
					return (
						<div className='flex items-center justify-center gap-2'>
							<Input
								className="flex-1"
								type="text"
								label="File Name"
								placeholder="File Name"
								value={value}
								onChange={onResourceNameChange}
							/>
							<div className='mt-3 w-10 flex justify-between'>
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Save Resource Name'}
									type="save"
									onClick={saveResourceName}
								/>
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Close Resource Name'}
									type="close"
									onClick={closeResourceNameEditing}
								/>
							</div>
						</div>
					)
				}
				return value;
			},
		},
		{
			header: 'Uploaded Date',
			key: 'uploadedAt',
			sorting: true,
			width: '25%',
			columnRender: (value: Date | string) => {
				const date = new Date(value);
				const dateStr = date.toLocaleDateString();
				return <ColoredTag tagId={dateStr} text={dateStr} withoutBackground />
			},
		},
	] as ITableColumn[];
};
