import { FC, useCallback, useEffect, useState } from 'react';
import { Table } from '../../../primitives';
import { getBriefControlsColumns } from './utils';
import styles from './BaseControlsBriefTable.module.scss';
import { IIdName, ISortProps } from '../../../../utils/types';
import { sortTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import IBaseControlsBriefTable from './IBaseControlsBriefTable';

const BaseControlsBriefTable: FC<IBaseControlsBriefTable> = ({ className, baseControls }) => {
	const [dataToDisplay, setDataToDisplay] = useState<IIdName[]>([]);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	useEffect(() => {
		if (baseControls.length) {
			const proccessedData: IIdName[] = sortTableData(baseControls, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [baseControls, currentSort]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			<Table
				tableKey="base-controls-brief"
				data={dataToDisplay}
				columns={getBriefControlsColumns()}
				options={{
					rowClassName: styles.row,
					onSortChange,
					emptyResultMessage: 'No base controls to display.',
					bodyMaxHeight: 360,
					small: true,
					smallScrollbar: true,
				}}
			/>
		</div>
	);
};

export default BaseControlsBriefTable;
