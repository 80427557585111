import {
	IAWSPasswordRequirements,
	IPasswordRequirements,
	ISortProps,
	IKeyValuePair,
	ISelectOption,
	IIdName,
	IChangeHistoryEntry,
	IHistoryGroupedByDateTime,
	IVariable,
	ICapturedValues,
	EntityTypes,
	IHistoryVariable,
} from '../../types';
import nistIcon from '../../../assets/images/frameworks/logo-nist.png';
import isoIcon from '../../../assets/images/frameworks/logo-iso.png';
import gdprIcon from '../../../assets/images/frameworks/logo-gdpr.png';
import psiIcon from '../../../assets/images/frameworks/logo-psi.png';
import soc2Icon from '../../../assets/images/frameworks/logo-soc2.png';
import {
	AssetCriticalityDisplayName,
	AssetDispositionDisplayName,
	AssetSensitivityDisplayName,
	AssetTypeDisplayName,
	COMPANY_TIME_FORMAT,
	CommonColors,
	CommonStatusesDisplayName,
	HistoryVariableType,
	MAX_CONTROLS_DISPLAY_NUMBER,
	MAX_TAGS_DISPLAY_NUMBER,
	MIN_CONTROLS_DISPLAY_NUMBER,
	MIN_TAGS_DISPLAY_NUMBER,
	VariableTypes,
	VariableUnitsDisplayName,
} from '../constants';
import { ColoredTag } from '../../../components';
import { DeviceType } from '../hooks/useDevice/IUseDevice';
import { ITag, ITagBrief } from '../../../services/store/slices/tags.slice';
import { IFramework } from '../../../services/store/slices/frameworks.slice';
import { IControl } from '../../../services/store/slices/controls.slice';
import DOMPurify from 'dompurify';
import { ICompanyUser } from '../../../services/store/slices/company-users.slice';
import { IPolicy } from '../../../services/store/slices/policies.slice';
import { IAsset } from '../../../services/store/slices/assets.slice';
import { format, toZonedTime } from 'date-fns-tz';
import { formatDate, isAfter } from 'date-fns';

export const nonScrollablePageOn = () => document.body.classList.add('non-scrollable');
export const nonScrollablePageOff = () => document.body.classList.remove('non-scrollable');

export const sanitizeData = (data: any) => {
	if (!data) return;

	const sanitizedData = { ...data };

	Object.entries(sanitizedData).forEach(([key, value]) => {
		if (typeof value === 'string') {
			data[key] = DOMPurify.sanitize(value);
		}
	});

	return sanitizedData;
};

export const addDays = (date: Date, days: number) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);

	return result;
};

export const formatUTCOffset = (utcOffset: string) => {
	const sign = utcOffset[0];
	const numericPart = utcOffset.match(/[0-9]+/)?.[0];
	if (numericPart === '00') return sign + '0';
	return sign + (parseInt(numericPart ?? '') || '');
};

const dateInUTC = (date?: Date) => {
	if (!date) return '';

	const dateBuff = new Date(date);
	const utcDatetime = Date.UTC(
		dateBuff.getUTCFullYear(),
		dateBuff.getUTCMonth(),
		dateBuff.getUTCDate(),
		dateBuff.getUTCHours(),
		dateBuff.getUTCMinutes(),
		dateBuff.getUTCSeconds(),
	);

	const isoDate = new Date(utcDatetime).toISOString();

	return isoDate;
};

export const dateInMDYTimeFormat = (date?: Date, timezone?: string) => {
	if (!date || !timezone) return '';

	const inUTC = dateInUTC(date);

	const zonedDateTime = toZonedTime(inUTC, timezone);
	const pattern = 'MM.dd.yyyy ' + COMPANY_TIME_FORMAT;

	return formatDate(zonedDateTime, pattern);
};

export const dateInMDYFormat = (date?: Date, timezone?: string) => {
	if (!date || !timezone) return '';

	const inUTC = dateInUTC(date);

	const zonedDateTime = toZonedTime(inUTC, timezone);
	const pattern = 'MM/dd/yy';

	return formatDate(zonedDateTime, pattern);
};

export const getFrameworkShortName = (name?: string) => {
	if (!name) return '';

	return name.split(' (')[0];
};

export const tagsColumnRenderHelper = (
	tags: ITagBrief[],
	device?: DeviceType | null,
	appTags?: ITag[] | null,
) => {
	if (!tags?.length || !appTags?.length) return;

	const maxTagsNumber =
		device === 'smallDesktop' ? MIN_TAGS_DISPLAY_NUMBER : MAX_TAGS_DISPLAY_NUMBER;

	const tagsCount = tags?.length;

	const tagsToDisplay = tagsCount > maxTagsNumber ? tags?.slice(0, maxTagsNumber) : tags;

	return (
		<>
			{tagsToDisplay.map((tag) => (
				<ColoredTag
					withoutBackground
					key={tag.id}
					tagId={tag.id}
					text={tag.name}
					bgColor={tag.color}
				/>
			))}

			{tagsCount > maxTagsNumber ? (
				<ColoredTag
					withoutBackground
					key={'rest'}
					tagId={'rest'}
					text={`+${tagsCount - maxTagsNumber}`}
					bgColor={CommonColors.defaultTagBg}
				/>
			) : null}
		</>
	);
};

export const dueDateColumnRenderHelper = (dueDate: string, className?: string) => {
	const daysDifference = checkDaysDifferenceWithNow(dueDate);
	const inPast = daysDifference > 0;

	if (inPast)
		return (
			<ColoredTag
				className={className}
				tagId={dueDate}
				text={dueDate}
				bgColor={CommonColors.errorLightTag}
				borderColor={CommonColors.errorTag}
			/>
		);

	const absDaysDifference = Math.abs(daysDifference);

	const bgColor =
		absDaysDifference < 3
			? CommonColors.errorLightTag
			: absDaysDifference < 7
				? CommonColors.alertLightTag
				: 'transparent';

	const borderColor =
		absDaysDifference < 3
			? CommonColors.errorTag
			: absDaysDifference < 7
				? CommonColors.alertTag
				: CommonColors.defaultTagBg;

	return (
		<ColoredTag
			className={className}
			tagId={dueDate}
			text={dueDate}
			bgColor={bgColor}
			borderColor={borderColor}
		/>
	);
};

export const controlsColumnRenderHelper = (
	controls: string[],
	device?: DeviceType | null,
	minControlsDisplayNumber?: number,
	maxControlsDisplayNumber?: number,
) => {
	if (!controls?.length) return;

	const maxTagsNumber =
		device === 'smallDesktop'
			? minControlsDisplayNumber || MIN_CONTROLS_DISPLAY_NUMBER
			: maxControlsDisplayNumber || MAX_CONTROLS_DISPLAY_NUMBER;

	const tagsCount = controls?.length;
	const tagsToDisplay = tagsCount > maxTagsNumber ? controls.slice(0, maxTagsNumber) : controls;

	return (
		<>
			{tagsToDisplay.map((control) => (
				<ColoredTag
					tagId={control}
					withoutBackground
					key={control}
					text={control}
					bgColor={CommonColors.defaultTagBg}
				/>
			))}

			{tagsCount > MAX_CONTROLS_DISPLAY_NUMBER ? (
				<ColoredTag
					tagId={'rest'}
					withoutBackground
					key={'rest'}
					text={`+${tagsCount - maxTagsNumber}`}
					bgColor={CommonColors.defaultTagBg}
				/>
			) : null}
		</>
	);
};

export const transformPasswordRequirements = (instructions: IAWSPasswordRequirements) => {
	const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } =
		instructions || {};

	return {
		minimumLength: MinimumLength || 8,
		requireLowercase: RequireLowercase || true,
		requireUppercase: RequireUppercase || true,
		requireNumbers: RequireNumbers || true,
		requireSymbols: RequireSymbols || true,
	} as IPasswordRequirements;
};

export const generatePasswordTextInstructions = (instructions?: IPasswordRequirements | null) => {
	if (!instructions) return;

	const { minimumLength, requireLowercase, requireUppercase, requireNumbers, requireSymbols } =
		instructions;

	const passwordInstructions = [];

	if (requireLowercase && requireUppercase)
		passwordInstructions.push({
			key: 'requireLowercaseUppercase',
			rgx: '^(?=.*[a-z])(?=.*[A-Z])',
			text: 'Lowercase and uppercase letters',
		});
	else if (requireLowercase)
		passwordInstructions.push({
			key: 'requireLowercase',
			rgx: '^(?=.*[a-z])',
			text: 'Lowercase letters',
		});
	else if (requireUppercase)
		passwordInstructions.push({
			key: 'requireUppercase',
			rgx: '^(?=.*[A-Z])',
			text: 'Uppercase letters',
		});

	passwordInstructions.push({
		key: 'minimumLength',
		rgx: `.{${minimumLength},}`,
		text: `At least ${minimumLength} characters`,
	});

	if (requireSymbols)
		passwordInstructions.push({
			key: 'requireSymbols',
			rgx: '^(?=.*[!@#$%^&*])',
			text: 'At least 1 special character',
		});
	if (requireNumbers)
		passwordInstructions.push({
			key: 'requireNumbers',
			rgx: '^(?=.*[0-9])',
			text: 'At least 1 number',
		});

	return passwordInstructions;
};

export const checkForRegex = (rgx: string, input: string) => {
	const regex = new RegExp(rgx);
	return regex.test(input);
};

export const getLogoForFramework = (name?: string) => {
	if (!name) return;

	const nameLowered = name.toLowerCase();

	if (nameLowered.includes('nist')) return nistIcon;
	if (nameLowered.includes('iso')) return isoIcon;
	if (nameLowered.includes('gdpr')) return gdprIcon;
	if (nameLowered.includes('psi')) return psiIcon;
	if (nameLowered.includes('soc')) return soc2Icon;
};

export const userNameFirstLetters = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) {
		return `${firstName[0]}${lastName[0]}`;
	}
};

export const getOwnerOptions = (
	owners?: ICompanyUser[] | null,
	userId?: string,
	withUnassigned: boolean = true,
) => {
	if (!owners || !owners.length)
		return [
			...(userId ? [{ value: userId, label: 'Me' }] : []),
			...(withUnassigned ? [{ value: 'unassigned', label: 'Unassigned' }] : []),
		];

	const filtered = owners.filter((owner) => owner.id !== userId);

	const ownerOptions = [
		...(userId ? [{ value: userId, label: 'Me' }] : []),
		...filtered.map((owner) => ({ value: owner.id, label: owner.fullName })),
		...(withUnassigned ? [{ value: 'unassigned', label: 'Unassigned' }] : []),
	];

	return ownerOptions as ISelectOption[];
};

export const getFrameworkOptions = (frameworks: IFramework[]) => {
	return frameworks
		?.filter((framework) => framework.isActive)
		?.map((framework) => ({
			value: framework.id!,
			label: framework.name!,
		}));
};

export const getUserRoleDisplay = (role?: string) => {
	if (!role) return '';

	switch (role) {
		case 'client-admin':
			return 'Admin';
		case 'gs-admin':
			return 'Admin';
		default:
			return 'Shareholder';
	}
};

export const getControlOptions = (controls: IControl[]) => {
	return Array.from(controls).map(
		(control) =>
			({
				value: control.id,
				label: control.controlId,
			}) as ISelectOption,
	);
};

export const getBaseControlOptions = (controls: IIdName[]) => {
	const uniqueControls = controls.filter(
		(control, index, self) => self.findIndex((c) => c.id === control.id) === index,
	);

	return Array.from(uniqueControls).map(
		(control) =>
			({
				value: control.id,
				label: control.displayId,
			}) as ISelectOption,
	);
};

export const getTagOptions = (tags: ITag[]) => {
	const tagsOptions = tags.map((tag) => ({
		value: tag.id,
		label: tag.name,
		metaData: { color: tag.color },
	}));

	return tagsOptions;
};

export function getSortingHandlersByProperty(propertyName: string) {
	switch (propertyName) {
		case 'owner':
			return (a: ICompanyUser | null, b: ICompanyUser | null) => {
				if (!a || !a.fullName) return -1;
				if (!b || !b.fullName) return 1;

				if (a.fullName < b.fullName) return -1;
				if (a.fullName > b.fullName) return 1;
				return 0;
			};
		case 'framework':
		case 'relatedItem':
			return (a: IIdName, b: IIdName) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			};
		case 'createdAt':
		case 'dueDate':
			return (a: string, b: string) => {
				const dateA = new Date(a);
				const dateB = new Date(b);

				if (dateA.getTime() < dateB.getTime()) return -1;
				if (dateA.getTime() > dateB.getTime()) return 1;
				return 0;
			};
		case 'controlId':
		case 'displayId':
			return compareIDs;
		default:
			return (a: string, b: string) => {
				if (a < b) return -1;
				if (a > b) return 1;
				return 0;
			};
	}
}

export function getSearchHandlerByProperty(
	propertyName: string,
	propertyValue: any,
	searchInput: string,
) {
	switch (propertyName) {
		case 'relatedItem':
			const relatedItemValue = propertyValue as IIdName;
			return relatedItemValue.name.toLowerCase().includes(searchInput.toLowerCase());
		case 'assignedControls':
			const assignedControlValue = propertyValue as IIdName[];
			return assignedControlValue.some((value) =>
				value.displayId?.toLowerCase().includes(searchInput.toLowerCase()),
			);
		case 'assignedAssets':
		case 'assignedPolicies':
			const assignedValue = propertyValue as IIdName[];
			return assignedValue.some((value) =>
				value.name.toLowerCase().includes(searchInput.toLowerCase()),
			);
		default:
			return false;
	}
}

export function sortTableData<T>(data: T[], currentSort: ISortProps) {
	if (!data.length) return [];

	if (!currentSort || !currentSort?.property || !currentSort?.direction) return data;

	const sortingHandlerForProperty = getSortingHandlersByProperty(currentSort?.property);

	const sortedData = [...data].sort((a: T, b: T) => {
		const propertyValueA = a[currentSort.property as keyof typeof a];
		const propertyValueB = b[currentSort.property as keyof typeof b];

		//@ts-ignore
		if (sortingHandlerForProperty(propertyValueA, propertyValueB) < 0)
			return currentSort.direction === 'asc' ? -1 : 1;

		//@ts-ignore
		if (sortingHandlerForProperty(propertyValueA, propertyValueB) > 0)
			return currentSort.direction === 'asc' ? 1 : -1;

		return 0;
	});

	return sortedData;
}

export function processTableData<T>(
	data: T[],
	currentFilters: IKeyValuePair,
	currentSort: ISortProps,
) {
	const processedData: T[] = [];

	data.forEach((dataRecord: T) => {
		if (Object.entries(currentFilters).every(([key, filterMatch]) => filterMatch(dataRecord))) {
			processedData.push(dataRecord);
		}
	});

	if (!currentSort || !currentSort?.property || !currentSort?.direction) return processedData;

	const sortedData = sortTableData(processedData, currentSort);

	return sortedData;
}

export const sortByNameAsc = (arr: any[]) =>
	arr.sort((a, b) =>
		a?.name?.toLowerCase() > b?.name?.toLowerCase()
			? 1
			: b?.name?.toLowerCase() > a?.name?.toLowerCase()
				? -1
				: 0,
	);

//Sort by name and then by id if names are equal
export const sortByNameAndThenByIdAsc = (arr: any[]) =>
	arr.sort((a, b) => {
		if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) return 1;
		if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) return -1;

		if (a?.id?.toLowerCase() > b?.id?.toLowerCase()) return 1;
		if (b?.id?.toLowerCase() > a?.id?.toLowerCase()) return -1;

		return 0;
	});

function compareIDs(id1: string, id2: string) {
	const parts1 = splitID(id1);
	const parts2 = splitID(id2);

	for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
		const part1 = parts1[i];
		const part2 = parts2[i];

		// Check if both parts are numbers
		const isNum1 = !isNaN(Number(part1));
		const isNum2 = !isNaN(Number(part2));

		if (isNum1 && isNum2) {
			// If both parts are numbers, compare them numerically
			const num1 = parseFloat(part1);
			const num2 = parseFloat(part2);
			if (num1 < num2) {
				return -1;
			} else if (num1 > num2) {
				return 1;
			}
		} else if (isNum1 || isNum2) {
			// If one part is a number and the other is not, prioritize the number
			return isNum1 ? -1 : 1;
		} else {
			// If both parts are not numbers, compare them as strings
			if (part1 < part2) {
				return -1;
			} else if (part1 > part2) {
				return 1;
			}
		}
	}

	return parts1.length - parts2.length;
}

function splitID(id: string) {
	// Split by '-', '.', '(', ')'
	return id.split(/[-.()]/);
}

export const sortControlObjByControlIdAsc = (arr: IControl[]) => {
	const arrCopy = [...arr];

	return arrCopy.sort((a, b) => compareIDs(a.controlId, b.controlId));
};

export const sortBaseControlObjByIdAsc = (arr: IIdName[]) => {
	const arrCopy = [...arr];

	return arrCopy.sort((a, b) => compareIDs(a.displayId || '', b.displayId || ''));
};

export const sortObjByNameAsc = (arr: IIdName[]) =>
	arr.sort((a, b) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

export const sortStringsAsc = (arr: string[]) =>
	arr.sort((a, b) =>
		a?.toLowerCase() > b?.toLowerCase() ? 1 : b?.toLowerCase() > a?.toLowerCase() ? -1 : 0,
	);

export const sortUsersByFullName = (arr: ICompanyUser[]) =>
	arr.sort((a, b) => {
		if (!a.fullName || !b.fullName) return 0;

		return a.fullName.toLowerCase() > b.fullName.toLowerCase()
			? 1
			: b.fullName.toLowerCase() > a.fullName.toLowerCase()
				? -1
				: 0;
	});

export const sortByDateAsc = (arr: any[], datePropertyName: string) => {
	if (!arr.length || !datePropertyName) return [];

	const sortedArr = [...arr].sort((a, b) => {
		const a1 = a[datePropertyName];
		const b1 = b[datePropertyName];

		const dateA = new Date(a1);
		const dateB = new Date(b1);

		return dateA.getTime() - dateB.getTime();
	});

	return sortedArr;
};

export const sortByDateDesc = (arr: any[], datePropertyName: string) => {
	if (!arr.length || !datePropertyName) return [];

	const sortedArr = [...arr].sort((a, b) => {
		const a1 = a[datePropertyName];
		const b1 = b[datePropertyName];

		const dateA = new Date(a1);
		const dateB = new Date(b1);

		return dateB.getTime() - dateA.getTime();
	});

	return sortedArr;
};

export const tagsAreDifferent = (tagsA?: ITagBrief[], tagsB?: ITagBrief[]) => {
	if (!tagsA) return false;

	if (tagsA?.length !== tagsB?.length) return true;

	if (tagsA && tagsB) {
		const currentControls = [...tagsA].sort((a, b) => a.name.localeCompare(b.name));
		const initialControls = [...tagsB].sort((a, b) => a.name.localeCompare(b.name));

		return JSON.stringify(currentControls) !== JSON.stringify(initialControls);
	}

	return false;
};

export const variablesAreDifferent = (
	variablesA?: ICapturedValues,
	variablesB?: ICapturedValues,
) => {
	if (variablesA && variablesB) {
		return variablesA ? JSON.stringify(variablesA) !== JSON.stringify(variablesB) : true;
	}

	return false;
};

export const controlsAreDifferent = (controlsA?: IIdName[], controlsB?: IIdName[]) => {
	if (controlsA?.length !== controlsB?.length) return true;

	if (controlsA && controlsB) {
		const currentControls = sortBaseControlObjByIdAsc(controlsA);
		const initialControls = sortBaseControlObjByIdAsc(controlsB);

		return JSON.stringify(currentControls) !== JSON.stringify(initialControls);
	}

	return false;
};

export const checkDaysDifferenceWithNow = (date: string) => {
	const dateToCheck = new Date(date);
	const now = new Date();

	const timeDifference = now.getTime() - dateToCheck.getTime();
	const daysDifference = timeDifference / (1000 * 3600 * 24);

	return daysDifference;
};

export const capitalizeString = (str?: string) => {
	if (!str) return '';

	return `${str?.charAt(0).toUpperCase()}${str?.toLowerCase().slice(1)}`;
};

export const getEntityInfoByType = (
	type: string,
	entityId: string,
	controls: IControl[] | null,
	policies: IPolicy[] | null,
	archivedPolicies: IPolicy[] | null,
	assets: IAsset[] | null,
	archivedAssets: IAsset[] | null,
) => {
	switch (type) {
		case EntityTypes.CompanyControl:
			const foundedControl = controls?.find((c) => c.id === entityId);

			if (foundedControl)
				return {
					id: foundedControl.id,
					displayId: foundedControl.controlId,
					name: foundedControl.name,
				} as IIdName;
			break;
		case EntityTypes.CompanyPolicy:
			const foundedPolicy = policies?.find((p) => p.id === entityId);

			if (foundedPolicy)
				return {
					id: foundedPolicy.id,
					name: foundedPolicy.name,
				} as IIdName;

			const foundedArchivedPolicy = archivedPolicies?.find((p) => p.id === entityId);

			if (foundedArchivedPolicy)
				return {
					id: foundedArchivedPolicy.id,
					name: foundedArchivedPolicy.name,
				} as IIdName;

			break;
		case EntityTypes.Asset:
			const foundedAsset = assets?.find((a) => a.id === entityId);

			if (foundedAsset)
				return {
					id: foundedAsset.id,
					name: foundedAsset.name,
				} as IIdName;

			const foundedArchivedAsset = archivedAssets?.find((a) => a.id === entityId);

			if (foundedArchivedAsset)
				return {
					id: foundedArchivedAsset.id,
					name: foundedArchivedAsset.name,
				} as IIdName;
			break;
		default:
			break;
	}
};

export const checkTokenExpiration = (token: string) => {
	const tokenPart = token.split('.')[1];
	const tokenData = atob(tokenPart);
	const expDate = JSON.parse(tokenData)?.exp;

	if (expDate) {
		const expirationDateTime = expDate * 1000;
		const nowDateTime = Date.now();

		let isValid = isAfter(expirationDateTime, nowDateTime);

		return isValid;
	}
};

export const groupHistoryEntriesByDatetime = (
	entries: IChangeHistoryEntry[],
	timezone?: string,
) => {
	const groupedEntries: IHistoryGroupedByDateTime = {};

	entries.forEach((entry) => {
		const date = dateInMDYTimeFormat(entry.createdAt, timezone);

		if (!groupedEntries[date]) groupedEntries[date] = [];

		groupedEntries[date].push(entry);
	});

	return groupedEntries;
};

export const checkForMissedVariableValues = (capturedValues: ICapturedValues) => {
	if (!capturedValues) return false;

	const valuesEntries = Object.entries(capturedValues.values);

	return valuesEntries.some(([key, typeValuePair]) => {
		const variable = typeValuePair as IVariable;

		if (!variable.value) return true;

		if (variable.type === VariableTypes.time && (!variable.value[0] || !variable.value[1]))
			return true;
		else if (!variable.value[0]) return true;

		return false;
	});
};

export const getMissedVariablesNumber = (capturedValues?: ICapturedValues) => {
	if (!capturedValues) return 0;

	const valuesEntries = Object.entries(capturedValues.values);

	const missedVariables = valuesEntries.filter(([key, typeValuePair]) => {
		const variable = typeValuePair as IVariable;

		if (!variable.value) return true;

		if (variable.type === VariableTypes.time && (!variable.value[0] || !variable.value[1]))
			return true;
		else if (!variable.value[0]) return true;

		return false;
	});

	return missedVariables.length;
};

export const getLocationWithActualTime = (locationName: string, timezoneName: string) => {
	if (!locationName) return;

	const nowDate = Date.now();
	const zonedTime = toZonedTime(nowDate, timezoneName);

	const formattedTime = format(zonedTime, COMPANY_TIME_FORMAT, { timeZone: timezoneName });

	return locationName.replace(/\([^)]*\)/g, `(${formattedTime.toLowerCase()})`);
};

export const getEntityTypeName = (type: string) => {
	switch (type) {
		case EntityTypes.CompanyControl:
			return 'Control';
		case EntityTypes.CompanyPolicy:
			return 'Policy';
		case EntityTypes.Asset:
			return 'Asset';
	}
};

export const renderHistoryTextByType = (
	initialText: string,
	variable: IHistoryVariable,
	styleClass?: string,
) => {
	let newText = initialText;

	switch (variable.type) {
		case HistoryVariableType.Name:
			const nameText = `<strong>${variable.value}</strong>`;

			newText = `${newText.replace('%s', nameText)}`;
			break;
		case HistoryVariableType.Owner:
			const displayName = variable.value === 'unassigned' ? 'Unassigned' : variable.value;

			const ownerNameText = `<span className=${styleClass}>${displayName}</span>`;

			newText = `${newText.replace('%s', ownerNameText)}`;
			break;
		case HistoryVariableType.Status:
			const statusText = `<span className=${styleClass}>${
				CommonStatusesDisplayName[variable.value as keyof typeof CommonStatusesDisplayName]
			}</span>`;

			newText = `${newText.replace('%s', statusText)}`;
			break;
		case HistoryVariableType.PolicyStatus:
			const policyStatusText = `<span className=${styleClass}>${
				CommonStatusesDisplayName[variable.value as keyof typeof CommonStatusesDisplayName]
			}</span>`;

			newText = `${newText.replace('%s', policyStatusText)}`;
			break;
		case HistoryVariableType.VariableUnit:
			const unitText = `<span className=${styleClass}>${
				VariableUnitsDisplayName[variable.value as keyof typeof VariableUnitsDisplayName] ||
				'" "'
			}</span>`;

			newText = `${newText.replace('%s', unitText)}`;
			break;
		case HistoryVariableType.AssetType:
			const typeText = `<span className=${styleClass}>${
				AssetTypeDisplayName[variable.value as keyof typeof AssetTypeDisplayName]
			}</span>`;

			newText = `${newText.replace('%s', typeText)}`;
			break;
		case HistoryVariableType.AssetDisposition:
			const dispositionText = `<span className=${styleClass}>${
				AssetDispositionDisplayName[
					variable.value as keyof typeof AssetDispositionDisplayName
				]
			}</span>`;

			newText = `${newText.replace('%s', dispositionText)}`;
			break;
		case HistoryVariableType.AssetSensitivity:
			const sensitivityText = `<span className=${styleClass}>${
				AssetSensitivityDisplayName[
					variable.value as keyof typeof AssetSensitivityDisplayName
				]
			}</span>`;

			newText = `${newText.replace('%s', sensitivityText)}`;
			break;
		case HistoryVariableType.AssetCriticality:
			const criticalityText = `<span className=${styleClass}>${
				AssetCriticalityDisplayName[
					variable.value as keyof typeof AssetCriticalityDisplayName
				]
			}</span>`;

			newText = `${newText.replace('%s', criticalityText)}`;
			break;
		default:
			const newValue = `<span className=${styleClass}>${variable.value || '" "'}</span>`;

			newText = newText.replace('%s', newValue);
			break;
	}

	return newText;
};

export const sortControlsByMissedData = (items: IControl[]) => {
	const dataWithMissingData = items.filter((dataRecord) => {
		return (
			!dataRecord.statement ||
			(dataRecord.capturedValues && checkForMissedVariableValues(dataRecord.capturedValues))
		);
	});

	const restData = items.filter((dataRecord) => {
		return !dataWithMissingData.includes(dataRecord);
	});

	return [...dataWithMissingData, ...restData];
};

export const groupByUniqueEntity = (entries: IChangeHistoryEntry[]) => {
	const groupedEntries: IHistoryGroupedByDateTime = {};

	entries.forEach((entry) => {
		const { entity, entityType } = entry;

		const entityKey = `${entityType}-${entity?.id}`;

		if (!groupedEntries[entityKey]) groupedEntries[entityKey] = [];

		groupedEntries[entityKey].push(entry);
	});

	return groupedEntries;
};

export const mergeUniqueObjsById = (arr1: any[], arr2: any[]) => {
	const combinedArray = [...arr1, ...arr2];
	const uniqueObjects = {};

	combinedArray.forEach((obj) => {
		//@ts-ignore
		uniqueObjects[obj.id] = obj;
	});

	return Object.values(uniqueObjects);
};

export const truncateString = (str: string, maxLength: number) => {
	if (str.length > maxLength) return `${str.slice(0, maxLength)}...`;

	return str;
};
