import { FC, useCallback } from 'react';
import styles from './ModalDeleteTrustHubFramework.module.scss';
import { Modal, BasicPanel } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import IModalDeleteTrustHubFramework from './IModalDeleteTrustHubFramework';

const ModalDeleteTrustHubFramework: FC<IModalDeleteTrustHubFramework> = ({
	open,
	selectedFramework,
	onDelete,
	setOpen,
}) => {
	const onFrameworkDelete = useCallback(() => {
		if (!selectedFramework) return;

		onDelete(selectedFramework);
		setOpen(false);
	}, [onDelete, selectedFramework, setOpen]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Framework?</h2>

				<p className={styles.text}>
					Are you sure you want to delete {selectedFramework?.name} from frameworks?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						onClick={onFrameworkDelete}
					>
						Delete
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteTrustHubFramework;
