import { FC } from 'react';
import styles from './ModalArchiveAsset.module.scss';
import { Modal, BasicPanel, Loader } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IModalArchiveAsset from './IModalArchiveAsset';
import { archiveAssets } from '../../../../services/store/slices/assets.slice';
import { useAssets } from '../../../../utils/helpers/hooks';

const ModalArchiveAsset: FC<IModalArchiveAsset> = ({
	open,
	setOpen,
	ids,
	names,
	onArchiveError,
	onArchiveSuccess,
}) => {
	const dispatch = useAppDispatch();
	const { archivationLoading } = useAssets();

	const onArchive = () => {
		handleWithTryCatch(
			async () => {
				await dispatch(archiveAssets(ids));

				onArchiveSuccess();
			},
			undefined,
			onArchiveError,
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Archive Asset?</h2>

				<p className={styles.text}>
					Are you sure you want to archive{' '}
					<strong>
						{ids.length > 1 ? `${ids.length} assets` : `asset ${names?.[0]}`}
					</strong>
					?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						disabled={archivationLoading}
						width={160}
						onClick={onArchive}
					>
						{archivationLoading ? (
							<Loader
								color={'#fcedf0'}
								transparentColor="transparent"
								thin
								maxHeight={14}
								maxWidth={14}
							/>
						) : (
							'Archive'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalArchiveAsset;
