import { FC, useEffect, useState } from 'react';
import styles from './ModalAddShareholder.module.scss';
import { Modal, BasicPanel } from '../../../primitives';
import IModalAddShareholder from './IModalAddShareholder';
import { ActionsBlock, Button } from '../../../primitives';
import { useAppDispatch } from '../../../../services/store';
import { useForm } from 'react-hook-form';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	ICompanyUser,
	addNewCompanyUser,
} from '../../../../services/store/slices/company-users.slice';
import {
	requireValidationPattern,
	emailValidationPattern,
	nameValidationPattern,
} from '../../../../utils/helpers/common/form';
import { Input, Select, Loader } from '../../../primitives';
import { getRoleOptions } from '../../../../utils/helpers/constants';
import { sanitizeData } from '../../../../utils/helpers/common';
import { useCompanyUsers, useCompany } from '../../../../utils/helpers/hooks';

const ModalAddShareholder: FC<IModalAddShareholder> = ({
	open,
	setOpen,
	onAddSuccess,
	onAddError,
}) => {
	const dispatch = useAppDispatch();
	const { loading: usersLoading } = useCompanyUsers();
	const { info: companyInfo } = useCompany();

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	});

	const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
	const [error, setError] = useState('');

	const onFormSubmitHandler = async (data: Partial<ICompanyUser>) => {
		if (Object.keys(errors).length) return;

		const sanitizedData = sanitizeData(data);
		const trimmedEmail = sanitizedData.email?.trim();

		handleWithTryCatch(
			async () => {
				await dispatch(addNewCompanyUser({ ...sanitizedData, email: trimmedEmail }));
				onAddSuccess(trimmedEmail);
				setOpen(false);
			},
			setError,
			onAddError,
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				type="submit"
				width={136}
				disabled={submitIsDisabled || !!Object.keys(errors).length || usersLoading}
			>
				{usersLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const createForm = (
		<div className={styles['edit-profile']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<Input
						{...register('firstName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.firstName}
						error={errors?.firstName?.message as string}
						type="text"
						label="First Name"
						placeholder="Enter first name"
					/>

					<Input
						{...register('lastName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.lastName}
						error={errors?.lastName?.message as string}
						type="text"
						label="Last Name"
						placeholder="Enter last name"
					/>

					<Input
						{...register('email', {
							...requireValidationPattern(),
							...emailValidationPattern(),
						})}
						withErrorStyle={
							!!errors.email || error.includes('email') || error.includes('exists')
						}
						error={
							error.includes('exists') ? 'User with such email already exists' : ''
						}
						className={styles.input}
						type="text"
						label="Email"
						placeholder="Enter email"
					/>

					<Select
						id="role"
						{...register('role', { ...requireValidationPattern() })}
						options={getRoleOptions(companyInfo?.name || '')}
						onValueChange={(value) => {
							setValue('role', value);
							trigger('role');
						}}
						className={styles.input}
						label="User Role"
						placeholder={'Select user role'}
					/>

					<Input
						{...register('title', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						className={styles.input}
						withErrorStyle={!!errors.title}
						error={errors?.title?.message as string}
						type="text"
						label="Title"
						placeholder="Enter title"
					/>
				</div>

				{formActions}
			</form>
		</div>
	);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setError('');
		}
	}, [open, reset]);

	useEffect(() => {
		setSubmitIsDisabled(!isValid);
	}, [isValid]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Add Shareholder</h2>

				{createForm}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddShareholder;
