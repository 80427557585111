import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../../utils/types';
import {
	ActionButton,
	AssignBlock,
	BasicPanel,
	Button,
	CriticalityFilter,
	DispositionFilter,
	HoverTooltip,
	OwnerFilter,
	Table,
	TableSkeleton,
	SearchFilter,
	TableFilters,
	SelectedNumber,
} from '../../../../primitives';
import styles from './AssetsTable.module.scss';
import {
	usePagination,
	useUser,
	useCompanyUsers
} from '../../../../../utils/helpers/hooks';
import { Alert } from '../../../../';
import classNames from 'classnames';
import IPartialTable from '../../common/IPartialTable';
import { getOwnerOptions, processTableData } from '../../../../../utils/helpers/common';
import { IAsset, assignAssets } from '../../../../../services/store/slices/assets.slice';
import { getAssetColumns } from '../utils';
import EditAsset from '../../../edit-panels/asset/edit/EditAsset';
import {
	AppQueryParams,
	AssetCriticalityOptions,
	AssetDispositionOptions,
	AssetSensitivityOptions,
} from '../../../../../utils/helpers/constants';
import { SensitivityFilter } from '../../../../primitives';
import ModalAddAsset from '../../../modals/modal-add-asset/ModalAddAsset';
import { useNavigate } from 'react-router-dom';
import ModalArchiveAsset from '../../../modals/modal-archive-asset/ModalArchiveAsset';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../services/store';
import { AppUrls } from '../../../../../utils/helpers/constants/app-routes';

const AssetsTable: FC<IPartialTable<IAsset>> = ({ data, dataLoading, showItemId }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { items: companyUsers } = useCompanyUsers();
	const { isAdmin, info: userInfo } = useUser();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IAsset[]>([]);

	const [isProcessing, setIsProcessing] = useState(true);
	const [assignIsProcessing, setAssignIsProcessing] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [showExtraActionsBlock, setShowExtraActionsBlock] = useState(false);
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [archiveModalOpen, setArchiveModalOpen] = useState(false);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [addedAsset, setAddedAsset] = useState('');
	const [assetsToArchivate, setAssetsToArchivate] = useState<string[]>([]);
	const [archiveResult, setArchiveResult] = useState<CommonResultStatusType>('');
	const [addResult, setAddResult] = useState<CommonResultStatusType>('');
	const [updateResult, setUpdateResult] = useState<CommonResultStatusType>('');
	const [assignmentResult, setAssignmentResult] = useState<CommonResultStatusType>('');
	const [updatedAssetId, setUpdatedAssetId] = useState<string | null>(null);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const tableColumnsConfig = useMemo(() => getAssetColumns(), []);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAsset) => true,
	});

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.assets}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const onAssetUpdateSuccess = () => {
		setUpdatedAssetId(showItemId || '');
		setUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.assets}`);
	};

	const onAssetUpdateError = () => setUpdateResult('error');

	const onAssetArchiveSuccess = () => {
		setArchiveResult('success');
		setOpenDrawer(false);
	};

	const onAssetArchiveError = () => setArchiveResult('error');

	const assignSelectedAssets = useCallback(
		(ownerId: string) => {
			setAssignIsProcessing(true);

			handleWithTryCatch(
				async () => {
					const owner = ownerId === 'unassigned' ? null : ownerId;
					await dispatch(assignAssets(owner, selectedRows));

					setAssignmentResult('success');
					setAssignIsProcessing(false);
				},
				undefined,
				() => {
					setAssignmentResult('error');
					setAssignIsProcessing(false);
				},
			);
		},
		[dispatch, selectedRows],
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'asset-update-success'}
				show={updateResult === 'success'}
				type="success"
				message="Asset updated!"
				clearActionStatus={() => setUpdateResult('')}
			/>
			<Alert
				uniqueKey={'asset-update-error'}
				show={updateResult === 'error'}
				type="error"
				message="Error occurred while updating asset"
				clearActionStatus={() => setUpdateResult('')}
			/>
			<Alert
				uniqueKey={'archive-success'}
				show={archiveResult === 'success'}
				type="success"
				message="Asset(s) archived!"
				clearActionStatus={() => setArchiveResult('')}
			/>
			<Alert
				uniqueKey={'archive-error'}
				show={archiveResult === 'error'}
				type="error"
				message="Error occurred while archiving asset"
				clearActionStatus={() => setArchiveResult('')}
			/>
			<Alert
				uniqueKey={'asset-add-success'}
				show={addResult === 'success'}
				type="success"
				message="Asset added!"
				clearActionStatus={() => setAddResult('')}
			/>
			<Alert
				uniqueKey={'asset-add-error'}
				show={addResult === 'error'}
				type="error"
				message="Error occurred while adding asset"
				clearActionStatus={() => setAddResult('')}
			/>
			<Alert
				uniqueKey={'assignment-success'}
				show={assignmentResult === 'success'}
				type="success"
				message="Assignment done!"
				clearActionStatus={() => setAssignmentResult('')}
			/>
			<Alert
				uniqueKey={'assignment-error'}
				show={assignmentResult === 'error'}
				type="error"
				message="Error occurred while assigning"
				clearActionStatus={() => setAssignmentResult('')}
			/>
		</>
	);

	const editDrawer = (
		<EditAsset
			open={openDrawer}
			assetId={showItemId || ''}
			onUpdateSuccess={onAssetUpdateSuccess}
			onUpdateError={onAssetUpdateError}
			onAssetArchiveSuccess={onAssetArchiveSuccess}
			onAssetArchiveError={onAssetArchiveError}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(`${AppUrls.assets}`);
			}}
		/>
	);

	const renderModals = () => {
		const foundedAssets = assetsToArchivate.map(
			(asset) => data?.find((a) => a.id === asset)?.name || '',
		);

		return (
			<>
				<ModalAddAsset
					open={addModalOpen}
					setOpen={setAddModalOpen}
					onAddSuccess={(addedAssetId) => {
						setAddedAsset(addedAssetId);
						setAddResult('success');
					}}
					onAddError={() => setAddResult('error')}
				/>

				<ModalArchiveAsset
					open={archiveModalOpen}
					setOpen={setArchiveModalOpen}
					ids={assetsToArchivate}
					names={foundedAssets}
					onArchiveSuccess={() => {
						setArchiveModalOpen(false);
						setArchiveResult('success');
					}}
					onArchiveError={() => {
						setArchiveModalOpen(false);
						setArchiveResult('error');
					}}
				/>
			</>
		);
	};

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				{showExtraActionsBlock ? (
					<AssignBlock
						loading={assignIsProcessing}
						className={styles.assign}
						show
						onAssign={assignSelectedAssets}
					/>
				) : (
					<div
						className={classNames(
							styles.left,
							!dataLoading && !data.length ? styles.disabled : '',
						)}
					>
						<SearchFilter
							placeholder="Search"
							setFilters={setCurrentFilters}
							properties={['name', 'description']}
							className={styles.search}
						/>

						<DispositionFilter
							options={AssetDispositionOptions}
							setFilters={setCurrentFilters}
						/>
						<CriticalityFilter
							options={AssetCriticalityOptions}
							setFilters={setCurrentFilters}
						/>
						<SensitivityFilter
							options={AssetSensitivityOptions}
							setFilters={setCurrentFilters}
						/>
						{isAdmin ? (
							<OwnerFilter
								options={getOwnerOptions(companyUsers, userInfo?.id)}
								setFilters={setCurrentFilters}
							/>
						) : null}
					</div>
				)}
			</Fragment>
		);

		const rightSection = (
			<>
				{showExtraActionsBlock ? (
					<Button
						type="button"
						negative
						width={165}
						onClick={() => setArchiveModalOpen(true)}
					>
						Archive Selected
					</Button>
				) : (
					<Button
						className={styles.add}
						negative
						width={118}
						onClick={() => setAddModalOpen(true)}
					>
						Add Asset
					</Button>
				)}
			</>
		);

		return (
			<TableFilters
				className={styles.filters}
				disabled={dataLoading || isProcessing}
				rightSection={isAdmin ? rightSection : null}
				leftSection={leftSection}
			/>
		);
	}, [
		assignIsProcessing,
		assignSelectedAssets,
		companyUsers,
		data.length,
		dataLoading,
		isAdmin,
		isProcessing,
		showExtraActionsBlock,
		userInfo?.id,
	]);

	const highlightedRows = useMemo(() => {
		if (assignmentResult === 'success') return selectedRows;

		if (addedAsset) {
			const addedId = data?.find((asset) => asset.id === addedAsset)?.id || '';
			return [addedId];
		}

		if (updatedAssetId) return [updatedAssetId];

		return [];
	}, [addedAsset, assignmentResult, data, selectedRows, updatedAssetId]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="assets"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					...(isAdmin && {
						rowActions: [
							{
								actionComponent: (
									<ActionButton
										tooltipId={'tooltip-archive'}
										tooltipContent={'Archive'}
										type="archive"
									/>
								),
								actionHandler: (id: string) => {
									setAssetsToArchivate([id]);
									setArchiveModalOpen(true);
								},
							},
						],
					}),
					selectedRows,
					setSelectedRows,
					bodyMaxHeight: 'calc(100vh - 370px)',
					rowCheckbox: isAdmin,
					successHighlightedRows: highlightedRows,
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No assets to display yet.${isAdmin ? '' : " You will see the assets once they're assigned to you."}`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data.length,
			dataLoading,
			highlightedRows,
			isAdmin,
			onRowClickHandler,
			onSortChange,
			selectedRows,
			tableColumnsConfig,
		],
	);

	useEffect(() => {
		if (assignmentResult === 'success') {
			const delay = setTimeout(() => {
				setAssignmentResult('');
				setSelectedRows([]);
				clearTimeout(delay);
			}, 2000);
		}
	}, [assignmentResult]);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (updatedAssetId && data.find((a) => a.id === updatedAssetId)) {
			goToPageByDataEntryProperty(updatedAssetId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedAssetId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryProperty, updatedAssetId]);

	useEffect(() => {
		setShowExtraActionsBlock(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IAsset[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (addedAsset) {
			goToPageByDataEntryProperty(addedAsset, 'id');

			const addDelay = setTimeout(() => {
				setAddedAsset('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [addedAsset, goToPageByDataEntryProperty]);

	useEffect(() => {
		if (selectedRows) setAssetsToArchivate(selectedRows);
	}, [selectedRows]);

	useEffect(() => {
		if (!archiveModalOpen) setSelectedRows([]);
	}, [archiveModalOpen]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		if (assignmentResult === 'success' && selectedRows.length) {
			goToPageByDataEntryProperty(selectedRows[0], 'id');
		}
	}, [assignmentResult, goToPageByDataEntryProperty, selectedRows]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				<SelectedNumber number={selectedRows.length} />

				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowCheckbox columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{editDrawer}

			{renderModals()}

			<HoverTooltip tooltipId="tooltip-archive" place="top-end" />

			{alerts}
		</>
	);
};

export default AssetsTable;
