import { FC, useEffect, useState } from 'react';
import styles from './AssetsStep.module.scss';
import { ActionsBlock, Button } from '../../../../../primitives';
import { useAssets, useTags } from '../../../../../../utils/helpers/hooks';
import AddAssetsTable from '../../../../tables/add-assets-table/AddAssetsTable';
import { IModalCreateTagStep } from '../../IModalCreateTag';
import { Loader } from '../../../../../primitives';

const AssetsStep: FC<IModalCreateTagStep> = ({
	currentFormState,
	setCurrentFormState,
	onFinish,
	setOpen,
}) => {
	const { items: assetItems } = useAssets();
	const { loading } = useTags();

	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const onSaveClick = () => {
		setCurrentFormState((prev) => ({ ...prev, assets: selectedRows }));
		if (onFinish) onFinish(selectedRows);
	};

	const onSkipClick = () => {
		setCurrentFormState((prev) => ({ ...prev, assets: [] }));
		if (onFinish) onFinish([]);
	};

	const actions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={96} negative onClick={() => setOpen(false)}>
				Cancel
			</Button>

			<div className={styles.group}>
				<Button
					className={styles.action}
					type="button"
					width={136}
					onClick={onSkipClick}
					negative
				>
					{loading ? <Loader thin maxHeight={12} maxWidth={12} /> : 'Skip & Save'}
				</Button>

				<Button className={styles.action} type="button" width={66} onClick={onSaveClick}>
					{loading ? (
						<Loader
							color="#ffffff"
							transparentColor="transparent"
							thin
							maxHeight={12}
							maxWidth={12}
						/>
					) : (
						'Save'
					)}
				</Button>
			</div>
		</ActionsBlock>
	);

	useEffect(() => {
		if (currentFormState?.assets?.length) setSelectedRows(currentFormState?.assets);
	}, [currentFormState?.assets]);

	useEffect(() => {
		setCurrentFormState((prev) => ({ ...prev, assets: selectedRows }));
	}, [selectedRows, setCurrentFormState]);

	const createForm = (
		<div className={styles['add-assets']}>
			<div className={styles['table-wrap']}>
				<p>
					Select the <strong>Assets</strong> to associate with the tag{' '}
					<strong>{currentFormState.name}</strong>
				</p>

				<AddAssetsTable
					bodyMaxHeight={160}
					withSearch={false}
					data={
						assetItems?.map((asset) => ({
							id: asset.id,
							name: asset.name,
							type: asset.type,
						})) || []
					}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
				/>
			</div>

			{actions}
		</div>
	);

	return createForm;
};

export default AssetsStep;
