import { FC, useCallback } from 'react';
import styles from './ModalResourceTokenExpired.module.scss';
import { Modal, BasicPanel } from '../../../primitives';
import { Button } from '../../../primitives';
import IModalResourceTokenExpired from './IModalResourceTokenExpired';

const ModalResourceTokenExpired: FC<IModalResourceTokenExpired> = ({
	open,
	setOpen,
	fileName,
}) => {
	const onClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Access expired</h2>

				<p className={styles.text}>
					Your access to <strong>{fileName}</strong> has expired.To view this file, please resubmit your request.
				</p>

				<Button
					type="button"
					className='w-full'
					negative
					onClick={onClose}
				>
					Ok
				</Button>
			</BasicPanel>
		</Modal>
	);
};

export default ModalResourceTokenExpired;
