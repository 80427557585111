import { useEffect, useMemo } from 'react';
import { useUser } from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getUserInfo } from '../../../services/store/slices/user.slice';
import {
	getCompanyInfo,
	setCompanyPrimaryFramework,
} from '../../../services/store/slices/company.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUnauthorizedRoutes, AppUrls, TRUST_HUB_ROOT } from '../constants/app-routes';
import { useFrameworks } from './useFrameworks';
import { useCompany } from './useCompany';
import { getFrameworks } from '../../../services/store/slices/frameworks.slice';
import { getCompanyUsers } from '../../../services/store/slices/company-users.slice';
import { getTags } from '../../../services/store/slices/tags.slice';
import { getControls } from '../../../services/store/slices/controls.slice';
import { getPolicies } from '../../../services/store/slices/policies.slice';
import { getAssets } from '../../../services/store/slices/assets.slice';

export function useDataPreload() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { authorized, info: userInfo } = useUser();

	const { items: frameworkItems } = useFrameworks();
	const { info: companyInfo, onboarded } = useCompany();

	const isTrustHub = useMemo(
		() => location.pathname.includes(TRUST_HUB_ROOT),
		[location.pathname],
	);

	const isTrustHubRequestAccess = useMemo(
		() => location.pathname.includes(AppUrls.trustHubAccessRequests),
		[location.pathname],
	);

	useEffect(() => {
		if (
			!AppUnauthorizedRoutes.includes(location.pathname) &&
			(!isTrustHub || isTrustHubRequestAccess)
		) {
			handleWithTryCatch(
				async () => {
					if (userInfo === undefined) await dispatch(getUserInfo());
				},
				undefined,
				() => navigate(AppUrls.login),
			);
		}
	}, [dispatch, isTrustHub, isTrustHubRequestAccess, location.pathname, navigate, userInfo]);

	useEffect(() => {
		if (authorized && !isTrustHub) handleWithTryCatch(() => dispatch(getUserInfo()));
	}, [authorized, dispatch, isTrustHub, location.pathname]);

	useEffect(() => {
		(async () => {
			if (authorized && userInfo?.companyId && (!isTrustHub || isTrustHubRequestAccess)) {
				await handleWithTryCatch(
					async () => await dispatch(getCompanyInfo(userInfo?.companyId)),
				);

				if (!frameworkItems)
					await handleWithTryCatch(async () => await dispatch(getFrameworks()));
			}
		})();
	}, [
		authorized,
		dispatch,
		userInfo?.companyId,
		frameworkItems,
		location.pathname,
		isTrustHub,
		isTrustHubRequestAccess,
	]);

	useEffect(() => {
		(async () => {
			if (isTrustHub && !isTrustHubRequestAccess) return;

			if (authorized && onboarded && frameworkItems) {
				const framework = frameworkItems?.find(
					(framework) => framework.id === companyInfo?.primaryFrameworkId,
				);

				if (framework) await dispatch(setCompanyPrimaryFramework(framework));

				await handleWithTryCatch(async () => await dispatch(getCompanyUsers()));

				if (window.location.pathname !== AppUrls.tags)
					await handleWithTryCatch(async () => await dispatch(getTags()));

				if (window.location.pathname !== AppUrls.controls)
					await handleWithTryCatch(async () => await dispatch(getControls()));

				if (window.location.pathname !== AppUrls.policies)
					await handleWithTryCatch(async () => await dispatch(getPolicies()));

				if (window.location.pathname !== AppUrls.assets)
					await handleWithTryCatch(async () => await dispatch(getAssets()));
			}
		})();
	}, [
		authorized,
		companyInfo?.primaryFrameworkId,
		dispatch,
		frameworkItems,
		isTrustHubRequestAccess,
		isTrustHub,
		location.pathname,
		onboarded,
	]);

	//TODO: think about adding check for shareholder
}
