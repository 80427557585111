import React, { FC } from 'react';
import classNames from 'classnames';
import { IButton } from './IButton';

import styles from './Button.module.scss';

export const Button: FC<IButton> = ({
	children,
	className,
	negative,
	btnRef,
	width,
	withIcon,
	small,
	errorStyled,
	...rest
}) => {
	return (
		<button
			className={classNames(
				styles.button,
				className,
				negative ? styles.negative : '',
				withIcon ? styles['with-icon'] : '',
				small ? styles.small : '',
				errorStyled ? styles.error : '',
			)}
			style={{ width }}
			ref={btnRef}
			{...rest}
		>
			<span className={classNames(styles.text, withIcon ? styles[withIcon] : '')}>
				{children}
			</span>
		</button>
	);
};
