import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export function useCompany() {
	const { info, loading, error, onBoardingStatus, onboarded } = useSelector(
		(store: RootState) => store.company,
	);

	return {
		info,
		loading,
		error,
		onBoardingStatus,
		onboarded,
	};
}
