import { useCallback } from 'react';

interface IUseCopyToClipboard {
	linkToCopy: string;
	onCopySuccess: (newMessage: string) => void;
}

export const useCopyToClipboard = ({ linkToCopy, onCopySuccess }: IUseCopyToClipboard) => {
	const copyToClipboard = useCallback(() => {
		if (!linkToCopy) return;

		navigator.clipboard.writeText(linkToCopy);

		onCopySuccess('Code copied to clipboard');
	}, [linkToCopy, onCopySuccess]);

	return { copyToClipboard };
};
