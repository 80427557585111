import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditTag.module.scss';
import {
	Input,
	Drawer,
	TagColorPicker,
	ColoredTag,
	SectionTitle,
	CollapsiblePanel,
	Scrollbar,
	Loader,
} from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import { useLeaveWarning, useTags } from '../../../../utils/helpers/hooks';
import IEditTag from './IEditTag';
import {
	ITag,
	IUpdateTag,
	getTagById,
	updateTag,
} from '../../../../services/store/slices/tags.slice';
import { sanitizeData } from '../../../../utils/helpers/common';
import ModalDeleteTag from '../../modals/modal-delete-tag/ModalDeleteTag';
import TagIncludedControls from '../../tag-included-controls/TagIncludedControls';
import classNames from 'classnames';
import TagIncludedAssets from '../../tag-included-assets/TagIncludedAssets';
import { IIdName } from '../../../../utils/types';
import TagIncludedPolicies from '../../tag-included-policies/TagIncludedPolicies';
import { EditTagSkeleton } from './EditTagSkeleton';

const EditTag: FC<IEditTag> = ({
	tagId,
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
	onDeleteSuccess,
	onDeleteError,
}) => {
	const dispatch = useAppDispatch();
	const { updateLoading } = useTags();
	const { handleSubmit } = useForm();

	const [currentFormState, setCurrentFormState] = useState<IUpdateTag>({
		name: '',
		color: '',
		controls: [],
		assets: [],
		policies: [],
	});
	const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [currentTag, setCurrentTag] = useState<ITag | null>(null);

	const {
		id,
		name,
		color: tagColor,
		assignedControls,
		assignedAssets,
		assignedPolicies,
	} = currentTag || {};

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [nameLengthError, setNameLengthError] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const onFormSubmitHandler = async () => {
		if (submitIsDisabled) return;

		const sanitizedData = sanitizeData(currentFormState);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(
						updateTag(id, {
							name: sanitizedData.name,
							color: sanitizedData.color,
							controls: sanitizedData.controls.map((control: IIdName) => control.id),
							assets: sanitizedData.assets.map((asset: IIdName) => asset.id),
							policies: sanitizedData.policies.map((policy: IIdName) => policy.id),
						}),
					);
					onUpdateSuccess();
				}
			},
			setError,
			onUpdateError,
		);
	};

	const onTagDeleteSuccessHandler = () => {
		onDeleteSuccess();
		setDeleteModalOpen(false);
		closeHandler();
	};

	const onTagDeleteErrorHandler = () => {
		onDeleteError();
		setDeleteModalOpen(false);
	};

	const formActions = (
		<ActionsBlock>
			<Button type="button" width={136} onClick={() => setDeleteModalOpen(true)} negative>
				Delete Tag
			</Button>

			<Button type="submit" width={136} disabled={updateLoading || submitIsDisabled}>
				{updateLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const renderControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Included Controls</SectionTitle>
		);

		const setControls = (newControls: IIdName[]) => {
			setCurrentFormState((prev) => ({ ...prev, controls: newControls }));
		};

		return (
			<CollapsiblePanel trigger={trigger}>
				<TagIncludedControls
					includedControlIds={currentFormState?.controls || []}
					setControlIds={setControls}
				/>
			</CollapsiblePanel>
		);
	};

	const renderAssets = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Included Assets</SectionTitle>
		);

		const setAssets = (newAssets: IIdName[]) => {
			setCurrentFormState((prev) => ({ ...prev, assets: newAssets }));
		};

		return (
			<CollapsiblePanel trigger={trigger}>
				<TagIncludedAssets
					includedAssetIds={currentFormState?.assets || []}
					setAssetIds={setAssets}
				/>
			</CollapsiblePanel>
		);
	};

	const renderPolicies = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Included Policies</SectionTitle>
		);

		const setPolicies = (newPolicies: IIdName[]) => {
			setCurrentFormState((prev) => ({ ...prev, policies: newPolicies }));
		};

		return (
			<CollapsiblePanel trigger={trigger}>
				<TagIncludedPolicies
					includedPolicyIds={currentFormState?.policies || []}
					setPolicyIds={setPolicies}
				/>
			</CollapsiblePanel>
		);
	};

	const renderEditForm = () => {
		const trigger = (
			<SectionTitle className={classNames(styles['collapsible-trigger'], styles.details)}>
				Details
			</SectionTitle>
		);

		return (
			<div className={styles['edit-tag']}>
				<form onSubmit={handleSubmit(onFormSubmitHandler)}>
					<Scrollbar className={styles.scrollbar}>
						<div className={styles.inputs}>
							<CollapsiblePanel trigger={trigger}>
								<Input
									defaultValue={name}
									className={styles.input}
									type="text"
									label="Tag Name"
									onValueChange={(value) => {
										if (value.length > 15) setNameLengthError(true);
										else setNameLengthError(false);
										setCurrentFormState((prev) => ({ ...prev, name: value }));
									}}
									withErrorStyle={!!error?.includes('name') || nameLengthError}
									error={
										error?.includes('name')
											? 'Tag name should be unique'
											: nameLengthError
												? 'Max length is 15 symbols'
												: ''
									}
								/>

								<div className={styles.section}>
									<h5 className={styles.title}>Tag Color</h5>

									<TagColorPicker
										selectedColor={currentFormState?.color}
										onColorSelect={(value) =>
											setCurrentFormState((prev) => ({
												...prev,
												color: value,
											}))
										}
									/>
								</div>

								<div className={styles.section}>
									<h5 className={styles.title}>Preview</h5>

									<ColoredTag
										tagId={currentFormState?.name || ''}
										text={currentFormState?.name || 'Preview'}
										borderColor={currentFormState?.color || ''}
										withoutBackground
									/>
								</div>
							</CollapsiblePanel>

							{renderControls()}

							{renderAssets()}

							{renderPolicies()}
						</div>
					</Scrollbar>

					{formActions}
				</form>
			</div>
		);
	};

	const changesWereMade = useCallback(() => {
		if (id) {
			const {
				name: currentTagName,
				color: currentTagColor,
				controls,
				assets,
				policies,
			} = currentFormState || {};

			const controlsDiffer = controls?.some(
				(control) => !currentTag?.assignedControls.includes(control),
			);
			const assetsDiffer = assets?.some(
				(asset: IIdName) => !currentTag?.assignedAssets?.some((a) => a.id === asset.id),
			);
			const policiesDiffer = policies?.some(
				(policy: IIdName) => !currentTag?.assignedPolicies?.some((p) => p.id === policy.id),
			);

			if (
				currentTagName !== name ||
				currentTagColor !== tagColor ||
				controlsDiffer ||
				assetsDiffer ||
				policiesDiffer
			)
				return true;
		}

		return false;
	}, [
		currentFormState,
		currentTag?.assignedAssets,
		currentTag?.assignedControls,
		currentTag?.assignedPolicies,
		id,
		name,
		tagColor,
	]);

	const modals = (
		<>
			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>

			<ModalDeleteTag
				open={deleteModalOpen}
				setOpen={setDeleteModalOpen}
				ids={[id || '']}
				names={[name || '']}
				onDeleteSuccess={onTagDeleteSuccessHandler}
				onDeleteError={onTagDeleteErrorHandler}
			/>
		</>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			setCurrentFormState({
				name: '',
				color: '',
				controls: [],
				assets: [],
				policies: [],
			});

			setCurrentTag(null);
			setError(null);

			setBrowserShowLeaveWarning(false);
			setSubmitIsDisabled(false);
		}
	}, [open]);

	useEffect(() => {
		if (!currentFormState.name || nameLengthError) setSubmitIsDisabled(true);
		else setSubmitIsDisabled(false);
	}, [currentFormState.name, nameLengthError]);

	useEffect(() => {
		if (id) {
			setCurrentFormState({
				name: name || '',
				color: tagColor || '',
				controls: assignedControls || [],
				assets: assignedAssets || [],
				policies: assignedPolicies || [],
			});
		}
	}, [id, tagColor, name, assignedAssets, assignedControls, assignedPolicies]);

	useEffect(() => {
		if (!currentTag && tagId) setLoading(true);
	}, [currentTag, tagId]);

	useEffect(() => {
		if (tagId && !currentTag && !loading && !error) {
			handleWithTryCatch(async () => {
				const tag = await dispatch(getTagById(tagId));

				setCurrentTag(tag as ITag);
				setLoading(false);
			}, setError);
		}
	}, [tagId, currentTag, dispatch, error, loading]);

	const renderContent = () => {
		if (loading) return <EditTagSkeleton />;
		else
			return (
				<>
					{renderEditForm()}

					{modals}
				</>
			);
	};

	return (
		<Drawer
			open={open}
			title="Tag Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open ? renderContent() : null}
		</Drawer>
	);
};

export default EditTag;
