import classNames from 'classnames';
import { GSPosts, LabelColors } from '../../utils/helpers/constants/gs-posts';
import styles from './GSUpdates.module.scss';
import { BasicPanel } from '../../components';
import { ColoredTag, ReadMore, Scrollbar, Drawer } from '../../components';
import { useEffect, useState } from 'react';
import { IGSPost } from '../../utils/types';

const GSUpdates = () => {
	const [openInfo, setOpenInfo] = useState(false);
	const [currentPost, setCurrentPost] = useState<IGSPost | null>();

	const getLabelConfigs = (postType: string) => {
		switch (postType) {
			case 'framework':
				return {
					text: 'Framework',
					bg: LabelColors.frameworkBg,
					border: LabelColors.frameworkBorder,
				};

			case 'security':
				return {
					text: 'Security',
					bg: LabelColors.securityBg,
					border: LabelColors.securityBorder,
				};
			case 'program':
				return {
					text: 'Program',
					bg: LabelColors.programBg,
					border: LabelColors.programBorder,
				};
		}
	};

	const renderLabel = (postType: string) => {
		const labelConfig = getLabelConfigs(postType);

		return (
			<ColoredTag
				tagId={postType}
				text={labelConfig?.text || ''}
				bgColor={labelConfig?.bg}
				borderColor={labelConfig?.border}
				className={styles.label}
			/>
		);
	};

	const postContent = (
		<Drawer
			open={openInfo}
			onCloseClickHandler={() => setOpenInfo(false)}
			title={currentPost?.title}
		>
			{openInfo ? (
				<div className={styles['drawer-content']}>
					{renderLabel(currentPost?.label || '')}

					<p>{currentPost?.content}</p>
				</div>
			) : null}
		</Drawer>
	);

	useEffect(() => {
		if (currentPost) setOpenInfo(true);
	}, [currentPost]);

	useEffect(() => {
		if (!openInfo) setCurrentPost(null);
	}, [openInfo]);

	return (
		<div className={styles['gs-updates']}>
			<h2 className={styles.title}>GS Updates</h2>

			<Scrollbar className={styles.scrollbar}>
				<div className={styles.posts}>
					{GSPosts.map((post) => (
						<BasicPanel key={post.id} className={styles.post}>
							<div
								className={classNames(
									styles.picture,
									!post.image ? styles.empty : '',
								)}
							>
								{post.image ? <img src={post.image} alt="GS Security" /> : null}
							</div>

							<div className={styles.info}>
								{renderLabel(post.label)}

								<h4 className={styles.title}>{post.title}</h4>

								<p className={styles.content}>{post.content}</p>
							</div>

							<ReadMore
								className={styles['read-more']}
								onClickHandler={() => setCurrentPost(post)}
							/>
						</BasicPanel>
					))}
				</div>
			</Scrollbar>

			{postContent}
		</div>
	);
};

export default GSUpdates;
