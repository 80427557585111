import React, { FC } from 'react';
import { ISelectOption } from '../../../../utils/types';
import { MultiSelectFilter } from '../';
import { IDispositionFilter } from './IDispositionFilter';

export const DispositionFilter: FC<IDispositionFilter> = ({ options, placeholder, setFilters }) => {
	const filterKey = 'dispositionFilter';
	const propertyName = 'disposition';

	const onMultiSelectValueChange = (value: ISelectOption[]) => {
		if (!value.length) {
			setFilters((prev: any) => ({
				...prev,
				[filterKey]: (dataRecord: any) => true,
			}));
			return;
		}

		setFilters((prev: any) => ({
			...prev,
			[filterKey]: (dataRecord: any) =>
				value.some((v) => {
					return v.value === dataRecord[propertyName];
				}),
		}));
	};

	return (
		<MultiSelectFilter
			placeholder={placeholder || 'Disposition'}
			options={options || []}
			onChange={onMultiSelectValueChange}
			isTagStyledList={false}
		/>
	);
};
