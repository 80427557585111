import React, { useMemo, useReducer } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './utils/helpers/hocs/auth/ProtectedRoute';
import { AppRoutes } from './utils/helpers/constants/app-routes';
import UnprotectedRoute from './utils/helpers/hocs/auth/UnprotectedRoute';
import { AnimatedScreenWrapper } from './utils/helpers/hocs/transition/AnimatedScreenWrapper';
import { AnimatePresence } from 'framer-motion';
import {
	useDataPreload,
	useCompany,
	useControls,
	useUser
} from './utils/helpers/hooks';
import { loginRedirectionPathReducer } from './services/store/custom-reducers/login-redirection-path-reducer';
import { LoginRedirectionProvider } from './utils/helpers/contexts/login-redirection-context';
import { Alert, Loader } from './components';
import PageNotFound from './pages/404/PageNotFound';

function App() {
	const location = useLocation();
	const { authorized, info: userInfo } = useUser();
	const { error: getCompanyError, info: companyInfo, onboarded } = useCompany();
	const { error: getControlsError } = useControls();

	const mappedRoutes = useMemo(
		() => (
			<>
				{AppRoutes.map((route) => {
					const Component = route.component;

					if (route.type === 'unprotected') {
						return (
							<Route
								path={route.url}
								key={route.key}
								element={
									<UnprotectedRoute>
										<AnimatedScreenWrapper>
											<Component />
										</AnimatedScreenWrapper>
									</UnprotectedRoute>
								}
							/>
						);
					}

					return (
						<Route
							path={route.url}
							key={route.key}
							element={
								<ProtectedRoute layout={route.layout}>
									<AnimatedScreenWrapper>
										<Component />
									</AnimatedScreenWrapper>
								</ProtectedRoute>
							}
						/>
					);
				})}
			</>
		),
		[],
	);

	const routes = useMemo(
		() => (
			<AnimatePresence mode="wait">
				<Routes {...{ location, key: location.pathname }}>
					{mappedRoutes}

					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</AnimatePresence>
		),
		[location, mappedRoutes],
	);

	const [loginRedirectionParamsState, dispatchLoginRedirectionParams] = useReducer(
		loginRedirectionPathReducer,
		{ pathname: null, search: null },
	);

	const errorAlerts = useMemo(() => {
		if (authorized)
			return (
				<>
					{!!getCompanyError ? (
						<Alert
							uniqueKey={'company-error'}
							show
							type="error"
							message="Error while getting company info. Please try to reload the page."
							hideAfterDelay={false}
						/>
					) : null}

					{!!getControlsError && onboarded ? (
						<Alert
							uniqueKey={'controls-error'}
							show
							type="error"
							message="Error while getting company controls. Please try to reload the page."
							hideAfterDelay={false}
						/>
					) : null}

					{companyInfo &&
						companyInfo.id &&
						!companyInfo.primaryFrameworkId &&
						onboarded ? (
						<Alert
							uniqueKey={'primary-framework-error'}
							show
							type="error"
							message="Error while getting company's primary framework."
							hideAfterDelay={false}
						/>
					) : null}
				</>
			);
	}, [authorized, getCompanyError, getControlsError, onboarded, companyInfo]);

	useDataPreload();

	if (authorized && userInfo?.id && onboarded === undefined) return <Loader thin wide />;

	return (
		<LoginRedirectionProvider
			value={{
				state: loginRedirectionParamsState,
				dispatch: dispatchLoginRedirectionParams,
			}}
		>
			{routes}

			{errorAlerts}
		</LoginRedirectionProvider>
	);
}

export default React.memo(App);
