import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export function usePolicies() {
	const {
		loading,
		items,
		baseItems,
		baseControls,
		archivedItems,
		policyLoading,
		updateLoading,
		commentsLoading,
		copyLoading,
		archivationLoading,
	} = useSelector((store: RootState) => store.policies);

	return {
		loading,
		items,
		baseItems,
		archivedItems,
		policyLoading,
		updateLoading,
		baseControls,
		commentsLoading,
		copyLoading,
		archivationLoading,
	};
}
