import { FC } from 'react';
import classNames from 'classnames';
import { ITableFilters } from './ITableFilters';

import styles from './TableFilters.module.scss';

export const TableFilters: FC<ITableFilters> = ({
	className,
	rightSection,
	leftSection,
	disabled,
	...rest
}) => {
	return (
		<div
			className={classNames(
				styles['table-filters'],
				className,
				disabled ? styles.disabled : '',
			)}
			{...rest}
		>
			<div className={styles['left-side']}>{leftSection}</div>
			<div className={styles['right-side']}>{rightSection}</div>
		</div>
	);
};
