import { controlsColumnRenderHelper } from '../../../../utils/helpers/common';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { IIdName } from '../../../../utils/types';
import { ITableColumn } from '../../../primitives/table/ITable';
import { ColoredTag } from '../../../primitives';
import styles from './RecentTags.module.scss';

export const getRecentTagsColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'Tag',
			key: 'name',
			width: '29%',
			columnRender: (tagName: string, rowData) => (
				<ColoredTag
					className={styles.tag}
					tagId={rowData.id}
					key={rowData.id}
					text={tagName}
					bgColor={rowData?.color}
					withoutBackground
				/>
			),
		},
		{
			header: 'Controls Included',
			key: 'assignedControls',
			width: '70%',
			columnRender: (controls: IIdName[]) =>
				controlsColumnRenderHelper(
					controls.map((c) => c.displayId || ''),
					device,
					4,
					5,
				),
		},
	] as ITableColumn[];
};
