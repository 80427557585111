import { useEffect } from 'react';

export function useScrollableSetting(stateFlag: boolean | undefined, element: HTMLElement) {
	useEffect(() => {
		if (stateFlag) element.classList.add('non-scrollable');
		else element.classList.remove('non-scrollable');

		return () => element.classList.remove('non-scrollable');
	}, [element.classList, stateFlag]);
}
