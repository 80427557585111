import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditTrustHubAccessResource.module.scss';
import { IEditTrustHubAccessResource } from './IEditTrustHubAccessResource';
import { useAppDispatch } from '../../services/store';
import { useLeaveWarning, useCompany } from '../../utils/helpers/hooks';
import { processTableData } from '../../utils/helpers/common';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { ActionsBlock, Button, Drawer, Error, Input, Loader, Scrollbar, Table } from '../../components';
import ModalLeaveWarning from '../../components/partials/modals/modal-leave-warning/ModalLeaveWarning';
import EditPolicySkeleton from '../../components/partials/edit-panels/policy/edit/EditPolicySkeleton';
import { getPendingTrustHubRequestAccessItems, getReviewedTrustHubRequestAccessItems, getTrustHubRequestAccessItem, TrustHubAccessRequestResource, TrustHubAccessRequests, updateTrustHubRequestAccessItem } from '../../services/store/slices/trust-hub.slice';
import { ISortProps, TrustHubAccessRequestStatus } from '../../utils/types';
import { getPendingAccessRequestsResourcesColumns, getReviewAccessRequestsResourcesColumns } from './utils';
import { TrustHubAccessRequestTypeDisplayName } from '../../utils/helpers/constants';
import { Tooltip } from 'react-tooltip';

const EditTrustHubAccessResource: FC<IEditTrustHubAccessResource> = ({
	trustHubRequestAccessItemId,
	onUpdateError,
	onUpdateSuccess,
	closeHandler,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { info: companyInfo } = useCompany();

	const [currentTrustHubRequestAccessItem, setCurrentTrustHubRequestAccessItem] = useState<TrustHubAccessRequests | null>();
	const { id } = currentTrustHubRequestAccessItem || {};

	const [loading, setLoading] = useState(false);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setShowBrowserLeaveWarning] = useState(false);
	const [processedData, setProcessedData] = useState<TrustHubAccessRequestResource[]>([]);
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [updateLoading, setUpdateLoading] = useState(false);
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const changesWereMade = useMemo(() => {
		if (currentTrustHubRequestAccessItem?.status === TrustHubAccessRequestStatus.reviewed) return false;

		return processedData.some(r => r.approved !== null);
	}, [currentTrustHubRequestAccessItem?.status, processedData]);

	const isSubmitDisabled = useMemo(() => {
		return processedData.some(r => r.approved === null);
	}, [processedData]);

	useEffect(() => {
		if (!currentTrustHubRequestAccessItem?.trustHubAccessRequestResources) return;
		setIsProcessing(true);

		const processedData: TrustHubAccessRequestResource[] = processTableData(currentTrustHubRequestAccessItem?.trustHubAccessRequestResources.map(r => ({
			...r,
			fileName: r.trustHubResource.name,
			status: r.approved === null ? 'Pending' : r.approved ? 'Approved' : 'Denied',
		})), {}, currentSort);

		setProcessedData(processedData);
		setIsProcessing(false);
	}, [currentSort, currentTrustHubRequestAccessItem]);
	const [error, setError] = useState('');

	const updateCurrentPolicy = useCallback(() => {
		handleWithTryCatch(
			async () => {
				const trustHubRequestAccessItem = await dispatch(getTrustHubRequestAccessItem(parseInt(trustHubRequestAccessItemId)));

				setCurrentTrustHubRequestAccessItem(trustHubRequestAccessItem);
			},
			setError,
			() => setLoading(false),
		);
	}, [trustHubRequestAccessItemId, dispatch]);

	useEffect(() => {
		if (changesWereMade) setShowBrowserLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (trustHubRequestAccessItemId && !currentTrustHubRequestAccessItem && !loading && !error) {
			updateCurrentPolicy();
		}
	}, [currentTrustHubRequestAccessItem, error, loading, trustHubRequestAccessItemId, updateCurrentPolicy]);

	useEffect(() => {
		if (!open) {
			setCurrentTrustHubRequestAccessItem(null);
			setShowBrowserLeaveWarning(false);
			setError('');
		}
	}, [open]);

	useLeaveWarning(showBrowserLeaveWarning);

	const onDeny = useCallback(() => {
		setProcessedData(
			processedData.map(r => {
				const selected = selectedRows.find(sr => sr.toString() === r.id.toString());
				if (selected) return { ...r, approved: false, status: 'Denied' }
				return r;
			})
		);
		setSelectedRows([]);
	}, [processedData, selectedRows, setProcessedData]);

	const onApprove = useCallback(() => {
		setProcessedData(
			processedData.map(r => {
				const selected = selectedRows.find(sr => sr.toString() === r.id.toString());
				if (selected) return { ...r, approved: true, status: 'Approved' }
				return r;
			})
		);
		setSelectedRows([]);
	}, [processedData, selectedRows, setProcessedData]);

	const onFormSubmitHandler = useCallback(async () => {
		if (isSubmitDisabled || updateLoading) return null;

		await handleWithTryCatch(
			async () => {
				setUpdateLoading(true);
				await dispatch(
					updateTrustHubRequestAccessItem(trustHubRequestAccessItemId, {
						trustHubAccessRequestResources: processedData,
					}),
				);

				await dispatch(getPendingTrustHubRequestAccessItems(true));
				await dispatch(getReviewedTrustHubRequestAccessItems(true));
				setUpdateLoading(false);
				onUpdateSuccess("Request updated successfully");
				closeHandler();
			},
			undefined,
			() => {
				onUpdateError('Failed to update request')
				setUpdateLoading(false);
			},
		);
	}, [isSubmitDisabled, updateLoading, dispatch, trustHubRequestAccessItemId, processedData, onUpdateSuccess, closeHandler, onUpdateError]);

	const modals = useMemo(() => (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				setShowBrowserLeaveWarning(false);
				closeHandler();
			}}
		/>
	), [warningModalOpen, setWarningModalOpen, closeHandler]);

	const renderActions = useMemo(() => {
		return (
			<ActionsBlock>
				<Button width={168} type="button" negative onClick={closeHandler}>
					Cancel
				</Button>

				<div
					data-tooltip-id='trust-hub-access-request-submit'
					data-tooltip-content={isSubmitDisabled ? 'Make sure there are no files in status”Pending” to Save the changes' : ''}
				>
					<Button
						width={168}
						type="button"
						disabled={isSubmitDisabled || updateLoading}
						onClick={() => onFormSubmitHandler()}
					>
						{isProcessing || updateLoading ? (
							<Loader thin maxHeight={14} maxWidth={14} />
						) : (
							'Save'
						)}
					</Button>
				</div>

				<Tooltip
					id={'trust-hub-access-request-submit'}
				/>
			</ActionsBlock>
		);
	}, [closeHandler, isProcessing, isSubmitDisabled, onFormSubmitHandler, updateLoading]);

	const renderDetails = () => {
		return (
			<div className='flex flex-col gap-2'>
				<div className='flex flex-row items-center gap-4'>
					<Input
						disabled
						className="flex-1"
						type='text'
						value={currentTrustHubRequestAccessItem?.companyName}
						label="Company Name"
					/>
					<Input
						disabled
						type='text'
						className="flex-1"
						value={currentTrustHubRequestAccessItem?.name}
						label="Full name"
					/>
				</div>

				<div className='flex flex-row items-center gap-4'>
					<Input
						disabled
						className="flex-1"
						type='text'
						value={currentTrustHubRequestAccessItem?.email}
						label="Email"
					/>
					<Input
						disabled
						className="flex-1"
						type='text'
						value={TrustHubAccessRequestTypeDisplayName[currentTrustHubRequestAccessItem?.requestType!]}
						label="Request type"
					/>
				</div>

				{currentTrustHubRequestAccessItem?.owner && (
					<div className='mb-5'>
						<Input
							disabled
							className="flex-1"
							type='text'
							value={`${currentTrustHubRequestAccessItem?.owner!.firstName!} ${currentTrustHubRequestAccessItem?.owner!.lastName!}`}
							label="Reviewed by"
						/>
					</div>
				)}

				{currentTrustHubRequestAccessItem?.status === TrustHubAccessRequestStatus.pending && (
					<ActionsBlock className="!justify-end gap-4">
						<Button
							disabled={selectedRows.length === 0}
							className={styles.action}
							width={132}
							type="button"
							negative
							onClick={onDeny}
						>
							{isProcessing || updateLoading ? (
								<Loader thin maxHeight={14} maxWidth={14} />
							) : (
								'Deny'
							)}
						</Button>

						<Button
							disabled={selectedRows.length === 0}
							className={styles.action}
							width={132}
							type="button"
							negative
							onClick={onApprove}
						>
							{isProcessing || updateLoading ? (
								<Loader thin maxHeight={14} maxWidth={14} />
							) : (
								'Approve'
							)}
						</Button>
					</ActionsBlock>
				)}
				<div>
					<Table
						tableKey="pending-requests"
						data={processedData || []}
						columns={
							currentTrustHubRequestAccessItem?.status === TrustHubAccessRequestStatus.pending ?
								getPendingAccessRequestsResourcesColumns(companyInfo) :
								getReviewAccessRequestsResourcesColumns(companyInfo)
						}
						options={{
							rowCheckbox: currentTrustHubRequestAccessItem?.status === TrustHubAccessRequestStatus.pending,
							emptyResultMessage: 'No pending requests to display yet.',
							emptyResultMessageType: 'common',
							extraSmall: true,
							noScroll: true,
							onSortChange,
							selectedRows,
							setSelectedRows,
						}}
					/>
				</div>
			</div>
		);
	};

	const editForm = (
		<form>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails()}

				</div>
			</Scrollbar>

			{currentTrustHubRequestAccessItem?.status === TrustHubAccessRequestStatus.pending && renderActions}
		</form>
	);

	return (
		<Drawer
			contentClassName={styles.drawer}
			title="Details"
			open={open}
			onCloseClickHandler={() => {
				if (changesWereMade) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{error ? <Error message={error} /> : null}

			{loading ? (
				<EditPolicySkeleton />
			) : (
				<div className={styles['edit-policy']}>
					{id ? editForm : null}

					{modals}
				</div>
			)}
		</Drawer>
	);
};

export default EditTrustHubAccessResource;
