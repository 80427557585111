export const TrustHubTextPreloader = () => {
	return (
		<div className='fixed inset-0 flex justify-center items-center bg-white bg-opacity-90 z-50 rounded-2xl'>
			<svg xmlns="http://www.w3.org/2000/svg" width="500" height="100" viewBox="0 0 500 100">
				<defs>
					<style>
						{`
						.text {
							fill: none;
							stroke: #4CAF50;
							stroke-width: 2;
							stroke-dasharray: 500;
							stroke-dashoffset: 500;
							font-family: Arial, sans-serif;
							font-size: 36px;
							animation: reveal 4s linear forwards;
						}

						@keyframes reveal {
							to {
								stroke-dashoffset: 0;
							}
						}
					`}
					</style>
				</defs>
				<text x="10" y="60" className="text">
					Powered by <tspan fill="none" stroke="#5f4adc">Gravity</tspan> <tspan fill="none" stroke="#b0b0b0">Security</tspan>
				</text>
			</svg>
		</div>
	);
};
