import { IControl } from "../../services/store/slices/controls.slice";
import { OverviewControlGroup } from "./OverviewControlGroup";

export interface OverviewControlsBoxProps {
  frameworksLoading: boolean;
  groupedControlItems?: {
    [key: string]: {
      enhancedFamilyName: string;
      items: IControl[];
    };
  };
  setCustomActiveTab: (tab: string) => void;
}

export const OverviewControlsBox = ({ frameworksLoading, groupedControlItems, setCustomActiveTab }: OverviewControlsBoxProps) => {
    return (
      <div className="p-6 bg-white rounded-2xl border border-levander-75">
        <h3 className="font-semibold mb-4">Controls</h3>
        <div className="grid gap-x-10 gap-y-6 lg:grid-cols-2 grid-cols-1">
          {
            frameworksLoading ?
              <>Loading...</> :
              groupedControlItems && Object.values(groupedControlItems).map((group) => (
                <OverviewControlGroup
                  title={group.enhancedFamilyName}
                  items={group.items}
                  setCustomActiveTab={setCustomActiveTab}
                />
              ))
          }
        </div>
      </div>
    );
  };