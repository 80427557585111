import React, { FC } from 'react';
import styles from './FilePicker.module.scss';
import { IFilePicker } from './IFilePicker';
import classNames from 'classnames';
import { Button } from '../';
import { useDropzone } from 'react-dropzone';
import { joinClassNames } from '../../../utils/helpers/common/joinClassNames';

export const FilePicker: FC<IFilePicker> = ({
	className,
	setAcceptedFiles,
	acceptExtensions = { 'image/*': [], 'application/pdf': [] },
	buttonText,
	multipleSelection = true,
	showDropZone = true,
	buttonClassName,
}) => {
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } =
		useDropzone({
			multiple: multipleSelection,
			accept: acceptExtensions,
			onDrop: (acceptedFiles: File[]) => {
				if (setAcceptedFiles)
					setAcceptedFiles((prev) => [...(prev || []), ...acceptedFiles]);
			},
		});

	const dropZone = (
		<div
			{...getRootProps()}
			className={classNames(
				styles['drag-n-drop'],
				className,
				isFocused ? styles.focused : '',
				isDragAccept ? styles.accepted : '',
				isDragReject ? styles.rejected : '',
			)}
		>
			<p>Drag and drop files here</p>
		</div>
	);

	return (
		<div className={joinClassNames(styles['file-picker'], className)}>
			<input {...getInputProps()} type="file" className="hidden" />
			{showDropZone && (
				<>
					{dropZone}

					<div className={styles.divider}>or</div>
				</>
			)}

			<Button
				type="button"
				className={joinClassNames(buttonClassName)}
				negative
				onClick={open}
			>
				{buttonText}
			</Button>
		</div>
	);
};
