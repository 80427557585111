import { ITableColumn } from '../../../primitives/table/ITable';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { controlsColumnRenderHelper } from '../../../../utils/helpers/common';
import { ColoredTag } from '../../../primitives';
import { ITag } from '../../../../services/store/slices/tags.slice';
import styles from './TagsTable.module.scss';
import { IIdName } from '../../../../utils/types';

export const getTagColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'Name',
			key: 'name',
			sorting: true,
			width: '14%',
			columnRender: (value: string, rowData: ITag) => {
				return (
					<ColoredTag
						className={styles.tag}
						tagId={rowData.id}
						text={value}
						withoutBackground
						bgColor={rowData.color}
					/>
				);
			},
		},
		{
			header: 'Included Policies',
			key: 'assignedPolicies',
			width: '24%',
			columnRender: (policies: IIdName[]) => {
				const policyNames = policies?.map((policy) => policy.name);
				return <span className={styles['colum-text']}>{policyNames?.join(', ')}</span>;
			},
		},
		{
			header: 'Included Controls',
			key: 'assignedControls',
			width: '24%',
			columnRender: (controls: IIdName[]) =>
				controlsColumnRenderHelper(
					controls.map((c) => c.displayId || ''),
					device,
					2,
					4,
				),
		},
		{
			header: 'Included Assets',
			key: 'assignedAssets',
			width: '24%',
			columnRender: (assets: IIdName[]) => {
				const assetNames = assets?.map((asset) => asset.name);
				return <span className={styles['colum-text']}>{assetNames?.join(', ')}</span>;
			},
		},
		{
			header: 'Creation Date',
			key: 'createdAt',
			sorting: true,
			width: '14%',
			columnRender: (value: string) => {
				return <ColoredTag tagId={value} text={value} withoutBackground />;
			},
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
