import React, { FC, useEffect, useRef } from 'react';
import styles from './Scrollbar.module.scss';
import { IScrollbar } from './IScrollbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classNames from 'classnames';

export const Scrollbar: FC<IScrollbar> = ({ className, children, small, hide }) => {
	let scrollBarRef = useRef<PerfectScrollbar | null>(null);

	useEffect(() => {
		if (hide) return;

		function updateScroll() {
			// @ts-ignore
			if (scrollBarRef) scrollBarRef.updateScroll();
		}

		const to = setTimeout(() => {
			//time for rendering
			updateScroll();
		}, 1000);

		window.addEventListener('resize', updateScroll);

		return () => {
			window.removeEventListener('resize', updateScroll);
			clearTimeout(to);
		};
	});

	return (
		<PerfectScrollbar
			// @ts-ignore
			ref={(ref) => (scrollBarRef = ref)}
			className={classNames(styles.scrollbar, className, small ? styles.small : '')}
			options={{ suppressScrollX: true, suppressScrollY: hide }}
		>
			{children}
		</PerfectScrollbar>
	);
};
