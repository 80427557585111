import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditCompany.module.scss';
import { ActionsBlock, Button } from '../../../primitives';
import { useForm } from 'react-hook-form';
import { IEditCompany } from './IEditCompany';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	companyNameValidationPattern,
	locationValidationPattern,
} from '../../../../utils/helpers/common/form';
import { useCompany, useLeaveWarning, useFormItemValues } from '../../../../utils/helpers/hooks';
import {
	ICompany,
	deleteCompanyLogo,
	updateCompanyInfo,
} from '../../../../services/store/slices/company.slice';
import LocationSearch from '../../location-search/LocationSearch';
import { IndustriesSelectOptions } from '../../../../utils/helpers/constants';
import { Input, Select, ImagePicker, Drawer, Loader } from '../../../primitives';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import { sanitizeData } from '../../../../utils/helpers/common';

export const EditCompany: FC<IEditCompany> = ({
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { info, loading } = useCompany();
	const {
		id,
		name,
		locationName,
		locationTimeZone: currentTimeZone,
		industry,
		logoId,
		logoUrl,
	} = info || {};

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [newLogoId, setNewLogoId] = useState<string | null>('');
	const [locationNameValue, setLocationNameValue] = useState<string | null>('');
	const [locationTimeZone, setLocationTimeZone] = useState('');

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
		trigger,
		getValues,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			name,
			locationName,
			industry,
			logoId,
		},
	});

	useFormItemValues(setValue, { industry });

	const onFormSubmitHandler = async (data: Partial<ICompany>) => {
		if (Object.keys(errors).length) return;

		const sanitizedData = sanitizeData(data);
		delete sanitizedData.locationName;

		if (info?.id)
			await handleWithTryCatch(
				async () => {
					const logo = newLogoId !== '' ? newLogoId : logoId;

					await dispatch(
						updateCompanyInfo(info?.id!.toString(), {
							...sanitizedData,
							location: {
								name: locationNameValue,
								timeZone: locationTimeZone || currentTimeZone,
							},
							logoId: logo !== null ? logo?.toString() : null,
						}),
					);
					onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);

		handleWithTryCatch(() => {
			if (logoId && newLogoId !== '') {
				dispatch(deleteCompanyLogo(logoId!));
			}
		});
	};

	const changesWereMade = useCallback(() => {
		if (!id) return false;

		const currentValues = getValues();

		return !!(
			currentValues.name !== name ||
			currentValues.locationName !== locationName ||
			currentValues.industry !== industry ||
			newLogoId !== ''
		);
	}, [getValues, id, industry, locationName, name, newLogoId]);

	const onCancelClick = () => {
		if (changesWereMade()) setWarningModalOpen(true);
		else closeHandler();
	};

	const formActions = (
		<ActionsBlock>
			<Button type="button" width={136} onClick={onCancelClick} negative disabled={loading}>
				Cancel
			</Button>

			<Button type="submit" width={136} disabled={!!(loading || Object.keys(errors).length)}>
				{loading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const editForm = (
		<div className={styles['edit-company']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<ImagePicker existingFileUrl={logoUrl || ''} setAcceptedFileId={setNewLogoId} />

					<Input
						{...register('name', {
							...requireValidationPattern(),
							...companyNameValidationPattern(),
						})}
						defaultValue={name}
						className={styles.input}
						withErrorStyle={!!errors.name}
						type="text"
						label="Name"
					/>

					<LocationSearch
						{...register('locationName', {
							...requireValidationPattern(),
							...locationValidationPattern(),
						})}
						type="text"
						value={locationName}
						withErrorStyle={!!errors.locationName}
						className={styles.input}
						onValueChange={(data) => {
							setLocationNameValue(data.value);
							setLocationTimeZone(data.timeZone);
							setValue('locationName', data.value);
						}}
					/>

					<Select
						id="industry"
						{...register('industry')}
						defaultValue={industry}
						className={styles.input}
						options={IndustriesSelectOptions}
						label="Industry"
						onValueChange={(value) => setValue('industry', value)}
						placeholder="Select industry"
					/>
				</div>

				{formActions}
			</form>
		</div>
	);

	const modals = (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				setBrowserShowLeaveWarning(false);
				closeHandler();
			}}
		/>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (open && changesWereMade()) setBrowserShowLeaveWarning(true);
		else setBrowserShowLeaveWarning(false);
	}, [changesWereMade, open]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setNewLogoId('');
		}
	}, [open, reset]);

	useEffect(() => {
		if (locationNameValue) trigger('locationName');
	}, [locationNameValue, trigger]);

	return (
		<Drawer
			open={open}
			title="Company Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open ? editForm : null}

			{modals}
		</Drawer>
	);
};
