import { FC, useMemo } from 'react';
import styles from './PrimaryFrameworkCard.module.scss';
import classNames from 'classnames';
import { ReadMore } from '../../primitives';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../utils/helpers/constants/app-routes';
import IPrimaryFrameworkCard from './IPrimaryFrameworkCard';
import { useCompany } from '../../../utils/helpers/hooks';
import { useFrameworks } from '../../../utils/helpers/hooks';
import PrimaryFrameworkReporting from '../primary-framework-reporting/PrimaryFrameworkReporting';

const PrimaryFrameworkCard: FC<IPrimaryFrameworkCard> = ({ className }) => {
	const navigate = useNavigate();
	const { items: frameworkItems } = useFrameworks();
	const { info: companyInfo } = useCompany();

	const currentPrimaryFramework = useMemo(
		() => frameworkItems?.find((framework) => framework.id === companyInfo?.primaryFrameworkId),
		[companyInfo?.primaryFrameworkId, frameworkItems],
	);

	return (
		<div className={classNames(styles['primary-framework'], className)}>
			<div className={styles.top}>
				<div className={styles['title-section']}>
					<h3>My Framework</h3>

					<ReadMore
						text="Read More"
						onClickHandler={() => navigate(AppUrls.frameworks)}
					/>
				</div>

				<div className={styles.framework}>
					<div className={styles.title}>
						{currentPrimaryFramework?.logo ? (
							<img src={currentPrimaryFramework.logo} alt="F" />
						) : null}

						<h4>{currentPrimaryFramework?.name}</h4>
					</div>

					<p className={styles.description}>{currentPrimaryFramework?.description}</p>
				</div>
			</div>

			<div className={styles.bottom}>
				<PrimaryFrameworkReporting />
			</div>
		</div>
	);
};

export default PrimaryFrameworkCard;
