import React, { useEffect, useMemo, useState } from 'react';
import styles from './Tags.module.scss';
import { useTags, useGetTags } from '../../utils/helpers/hooks';
import TagsTable from '../../components/partials/tables/tags/TagsTable';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';

const Tags = () => {
	const location = useLocation();
	const { items: tagsItems } = useTags();
	const [dataLoading, setDataLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!dataLoading && tagsItems) {
			const tagIdFromUrl = new URLSearchParams(location.search).get(AppQueryParams.item);

			if (tagIdFromUrl) {
				const tag = tagsItems?.find((item) => item.id === tagIdFromUrl);

				if (tag) return tagIdFromUrl;
			}
		}
	}, [dataLoading, location.search, tagsItems]);

	useGetTags();

	useEffect(() => {
		if (tagsItems) setDataLoading(false);
	}, [tagsItems]);

	return (
		<div className={styles.tags}>
			<h2>Tags</h2>

			<TagsTable
				data={tagsItems || []}
				dataLoading={dataLoading}
				showItemId={navigateToItem || ''}
			/>
		</div>
	);
};

export default Tags;
