
import { ColoredTag, ITableColumn, StatusInfo } from '../../components';
import { ICompany } from '../../services/store/slices/company.slice';
import { dateInMDYTimeFormat } from '../../utils/helpers/common';
import { TrustHubAccessRequestType, TrustHubAccessRequestTypeDisplayName } from '../../utils/helpers/constants';
import { CommonResultStatusType } from '../../utils/types';

export const getPendingAccessRequestsColumns = (companyInfo?: Partial<ICompany> | null) => {
	return [
		{
			header: 'Company Name',
			key: 'companyName',
			width: '20%',
			sorting: true,
			columnRender: (companyName: string) => <div>{companyName}</div>
		},
		{
			header: 'Full Name',
			key: 'name',
			width: '20%',
			sorting: true,
			columnRender: (fullName: string) => <div>{fullName}</div>
		},

		{
			header: 'Email',
			key: 'email',
			width: '20%',
			sorting: true,
			columnRender: (email: string) => <div>{email}</div>
		},
		{
			header: 'Request Type',
			key: 'requestType',
			width: '20%',
			sorting: true,
			columnRender: (requestType: TrustHubAccessRequestType) => <div>{TrustHubAccessRequestTypeDisplayName[requestType]}</div>
		},
		{
			header: 'Request Date',
			key: 'requestAt',
			width: '20%',
			sorting: true,
			columnRender: (value: Date | string) => {
				const date = new Date(value);
				const dateStr = dateInMDYTimeFormat(date, companyInfo?.locationTimeZone);
				return <ColoredTag tagId={dateStr} text={dateStr} withoutBackground />
			},
		},
	] as ITableColumn[];
};

export const getReviewedAccessRequestsColumns = (companyInfo?: Partial<ICompany> | null) => {
	return [
		...getPendingAccessRequestsColumns(companyInfo),
		{
			header: "Reviewed By",
			key: 'reviewedBy',
			width: '20%',
			sorting: true,
			columnRender: (_reviewedBy: string, rowData) => <div>
				{`${rowData?.owner!.firstName!} ${rowData?.owner!.lastName!}`}
			</div>
		}
	] as ITableColumn[];
};


export const getPendingAccessRequestsResourcesColumns = (companyInfo?: Partial<ICompany> | null) => {
	return [
		{
			header: 'File name',
			key: 'fileName',
			width: '40%',
			sorting: true,
			columnRender: (fileName: string) => <div>{fileName}</div>
		},
		{
			header: 'Status',
			key: 'status',
			width: '20%',
			sorting: true,
			columnRender: (status: string) => {
				let type = status === 'Approved' ? 'success' : 'error';
				if (status === 'Pending') {
					type = 'warning';
				}

				return (
					<StatusInfo
						className='!mt-0 inline-block'
						type={type as CommonResultStatusType}
					>
						{status}
					</StatusInfo>
				);
			}
		},
		{
			header: 'Request At',
			key: 'createdAt',
			width: '20%',
			sorting: true,
			columnRender: (value: Date | string) => {
				const date = new Date(value);
				const dateStr = dateInMDYTimeFormat(date, companyInfo?.locationTimeZone);
				return <ColoredTag tagId={dateStr} text={dateStr} withoutBackground />
			},
		},
	] as ITableColumn[];
};

export const getReviewAccessRequestsResourcesColumns = (companyInfo?: Partial<ICompany> | null) => {
	return [
		...getPendingAccessRequestsResourcesColumns(companyInfo),
		{
			header: 'Updated At',
			key: 'updatedAt',
			width: '20%',
			sorting: true,
			columnRender: (value: Date | string) => {
				const date = new Date(value);
				const dateStr = dateInMDYTimeFormat(date, companyInfo?.locationTimeZone);
				return <ColoredTag tagId={dateStr} text={dateStr} withoutBackground />
			},
		},
	] as ITableColumn[];
};
