import { FC, useCallback, useMemo } from 'react';
import styles from './ModalTrustHubRequestAccess.module.scss';
import { Modal, BasicPanel, Loader, Input, Select } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import IModalTrustHubRequestAccess from './IModalTrustHubRequestAccess';
import { useAppDispatch } from '../../../../services/store';
import { useCompanyUsers } from '../../../../utils/helpers/hooks';
import { useForm } from 'react-hook-form';
import { ITrustHubRequestAccessDto, requestAccess } from '../../../../services/store/slices/trust-hub.slice';
import { sanitizeData } from '../../../../utils/helpers/common';
import { requireValidationPattern } from '../../../../utils/helpers/common/form';
import { TrustHubAccessRequestType } from '../../../../utils/helpers/constants';
import { ISelectOption } from '../../../../utils/types';

const ModalTrustHubRequestAccess: FC<IModalTrustHubRequestAccess> = ({
	open,
	trustHub,
	companySlug,
	setCompanyName,
	onShareError,
	onShareSuccess,
	setOpen,
	setRequestModalOpen,
}) => {
	const dispatch = useAppDispatch();
	const { loading } = useCompanyUsers();

	const {
		handleSubmit,
		register,
		setValue,
		formState,
		clearErrors,
		reset,
		watch,
		trigger,
	} = useForm({
		mode: 'onSubmit',
		defaultValues: {
			name: '',
			email: '',
			companyName: '',
			requestTypeSelected: '',
			requestTypeIndividualDocuments: undefined,
			requestType: TrustHubAccessRequestType.fullAccess,
		} as Partial<ITrustHubRequestAccessDto>,
	});
	const { errors, isValid } = formState;
	const name = watch('name');
	const email = watch('email');
	const companyName = watch('companyName');
	const requestType = watch('requestType');
	const requestTypeSelected = watch('requestTypeSelected');
	const requestTypeIndividualDocuments = watch('requestTypeIndividualDocuments');

	const onFormSubmitHandler = useCallback(async (data: Partial<ITrustHubRequestAccessDto>, e: any) => {
		if (!isValid || Object.keys(errors).length) return;

		// const emailsLength = data.emails?.split(',').filter((email) => email).length || 0;

		// if (!emailsLength) {
		// 	onShareError('Please add at least one email');
		// 	return;
		// }

		// if (emailsLength > 5) {
		// 	onShareError('You can share with up to 5 people at a time');
		// 	return;
		// }

		// Remove ``email`` field before sending the request
		// delete data.email;

		const sanitizedData = sanitizeData(data) as Partial<ITrustHubRequestAccessDto>;

		try {
			await dispatch(requestAccess(companySlug, sanitizedData as ITrustHubRequestAccessDto));
			onShareSuccess('Trust Hub shared successfully');
		} catch (error) {
			onShareError("Error sharing Trust Hub");
		} finally {
			clearErrors();
			reset();
			setOpen(false);
			setRequestModalOpen(true);
		}
	}, [clearErrors, companySlug, dispatch, errors, isValid, onShareError, onShareSuccess, reset, setOpen, setRequestModalOpen]);

	const onClose = useCallback(() => {
		reset();
		clearErrors();
		setOpen(false);
	}, [clearErrors, reset, setOpen]);

	const resourcesOptions = useMemo(() => {
		if (!trustHub) return [] as ISelectOption[];

		return (trustHub.resources?.map((resource) => ({
			value: resource.id,
			label: resource.name,
		})) || []) as unknown as ISelectOption[];
	}, [trustHub]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={'lg:w-[712px] w-[90vw]'}>
				<h2 className="mb-5">Request access</h2>

				<form onSubmit={handleSubmit(onFormSubmitHandler)} className='flex flex-col gap-6'>

					<Input
						{...register('name', {
							...requireValidationPattern()
						})}
						type="text"
						label="Full Name"
						defaultValue={name}
						placeholder="Enter full name"
						withErrorStyle={!!errors.name}
						error={!!errors.name ? 'Please enter full name' : ''}
						onValueChange={(value: string) => {
							setValue('name', value)
							setCompanyName(value);
						}}
					/>

					<Input
						{...register('email', {
							...requireValidationPattern()
						})}
						type="text"
						label="Email"
						defaultValue={email}
						placeholder="Enter email"
						withErrorStyle={!!errors.email}
						error={!!errors.email ? 'Please enter email' : ''}
						onValueChange={(value: string) => setValue('email', value)}
					/>

					<Input
						{...register('companyName', {
							...requireValidationPattern()
						})}
						type="text"
						label="Company name"
						defaultValue={companyName}
						placeholder="Enter company name"
						withErrorStyle={!!errors.companyName}
						error={!!errors.email ? 'Please enter company name' : ''}
						onValueChange={(value: string) => setValue('companyName', value)}
					/>

					<div>
						<label className={styles.label} htmlFor='fullAccess'>
							Access level
						</label>
						<div className='flex gap-4'>
							<label className='flex gap-2 cursor-pointer'>
								<input
									type="radio"
									id="fullAccess"
									name="requestType"
									value={TrustHubAccessRequestType.fullAccess}
									checked={requestType === TrustHubAccessRequestType.fullAccess}
									onChange={() => setValue('requestType', TrustHubAccessRequestType.fullAccess)}
								/>
								<span>
									Full access
								</span>
							</label>
							<label className='flex gap-2 cursor-pointer'>
								<input
									type="radio"
									id="individualDocument"
									name="requestType"
									value={TrustHubAccessRequestType.individualDocument}
									checked={requestType === TrustHubAccessRequestType.individualDocument}
									onChange={() => setValue('requestType', TrustHubAccessRequestType.individualDocument)}
								/>
								<span>
									Individual document
								</span>
							</label>
						</div>

						{requestType === TrustHubAccessRequestType.individualDocument && (
							<div className='mt-4'>
								<Select
									id="requestTypeSelected"
									{...register('requestTypeSelected', {
										...requireValidationPattern()
									})}
									onValueChange={(value) => {
										setValue('requestTypeSelected', value);
										trigger('requestTypeSelected');

										const document = trustHub?.resources?.find((resource) => resource.id === parseInt(value));
										if (!document) return;

										if (requestTypeIndividualDocuments?.find((item) => item.id === document.id)) return;

										setValue("requestTypeIndividualDocuments", [...(requestTypeIndividualDocuments || []), document]);
									}}
									withErrorStyle={!!errors.requestTypeSelected}
									error={!!errors.requestTypeSelected ? 'Please select at document' : ''}
									value={requestTypeSelected}
									className="flex-1 mb-4"
									options={resourcesOptions}
									label="Give access to the following documents"
									placeholder='Select documents'
								/>
								{!!requestTypeIndividualDocuments?.length && (
									<ul className='flex flex-wrap gap-2 mt-2'>
										{requestTypeIndividualDocuments.map((resource, index) => (
											<li key={index} className='p-2 flex gap-2 border-[1px] border-solid border-levander-50 rounded-lg'>
												<div className={styles.clear} onClick={() => {
													setValue('requestTypeIndividualDocuments', requestTypeIndividualDocuments.filter((item) => item.id !== resource.id));
												}}></div>
												{resource.name}
											</li>
										))}
									</ul>
								)}
							</div>
						)}
					</div>

					<ActionsBlock>
						<Button
							type="button"
							width={160}
							negative
							onClick={onClose}
						>
							Cancel
						</Button>

						<Button
							type="submit"
							width={160}
							disabled={!isValid}
						>
							{loading ? (
								<Loader
									color={'#fcedf0'}
									transparentColor="transparent"
									thin
									maxHeight={14}
									maxWidth={14}
								/>
							) : (
								'Request access'
							)}
						</Button>
					</ActionsBlock>
				</form>
			</BasicPanel>
		</Modal>
	);
};

export default ModalTrustHubRequestAccess;
