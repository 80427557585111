import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export function useTrustHub() {
	const {
		loading,
		updateLoading,
		createLoading,
		item,
		trustHubRequestAccessItems,
		reviewedTrustHubRequestAccessItems,
		frameWorksEvaluations,
		resources,
		resourceLoading
	} = useSelector((store: RootState) => store.trustHub);

	return {
		loading,
		createLoading,
		updateLoading,
		item,
		trustHubRequestAccessItems,
		reviewedTrustHubRequestAccessItems,
		frameWorksEvaluations,
		resourceLoading,
		resources,
	};
}
