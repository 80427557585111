import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from './useUser';
import LoginRedirectionContext from '../contexts/login-redirection-context';
import { setLoginRedirectionState } from '../../../services/store/custom-reducers/login-redirection-path-reducer';
import { UnauthorizedRoutes } from '../constants';
import { useAuthorization } from './useAuthorization';

export const useLoginRedirection = () => {
	const location = useLocation();

	const { authorized } = useUser();
	const { loggedOut } = useAuthorization();
	const { dispatch: dispatchLoginRedirection } = useContext(LoginRedirectionContext);

	useEffect(() => {
		const onlyValidUrls =
			!UnauthorizedRoutes.includes(location?.pathname) && location?.pathname !== '/';

		if (onlyValidUrls && !authorized && !loggedOut) {
			dispatchLoginRedirection(setLoginRedirectionState(location.pathname, location.search));
		}
	}, [authorized, dispatchLoginRedirection, location.pathname, location.search, loggedOut]);
};
