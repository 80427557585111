import { useEffect, useMemo, useState } from 'react';
import { FileInfo, FilePicker, ProgressBar } from '../../../../primitives';
import { SUPPORTED_TRUST_HUB_POLICY_TYPES } from '../../../../../utils/helpers/constants';
import { IFilePreassignedData, IFileUploadResult } from '../../../../../utils/types';

interface EditPrivacyPolicyProps {
	setPrivacyPolicyFilesId: (id?: number) => void;
	getPreassignedUrlForPolicyCall: (fileName: string) => () => Promise<IFilePreassignedData>;
	predefinedPolicyFiles: File[] | null,
}

export const EditPrivacyPolicy = ({
	getPreassignedUrlForPolicyCall,
	setPrivacyPolicyFilesId,
	predefinedPolicyFiles,
}: EditPrivacyPolicyProps) => {
	const [filesProgress, setFilesProgress] = useState<IFileUploadResult[]>([]);
	// @ts-ignore
	const [uploadedFileIds, setUploadedFileIds] = useState<number[]>(predefinedPolicyFiles?.map((file) => file?.id) || []);
	const [policyFiles, setPolicyFiles] = useState<File[] & { id?: number } | null>(predefinedPolicyFiles);
	const [filesLoading, setFilesLoading] = useState(false);

	useEffect(() => {
		setPrivacyPolicyFilesId(uploadedFileIds.length ? uploadedFileIds[0] : undefined);
	}, [uploadedFileIds, setPrivacyPolicyFilesId]);

	useEffect(() => {
		if (policyFiles?.length) setFilesLoading(true);
		else setFilesLoading(false);
	}, [policyFiles?.length]);

	const renderProgress = useMemo(() => {
		if (!policyFiles?.length) return null;

		const calculateGeneralProgress = () => {
			if (filesProgress?.length === 1) {
				return filesProgress[0].progress;
			}

			const result =
				filesProgress?.reduce((acc, file) => {
					return acc + file.progress;
				}, 0) || 0;

			return Math.round(result / filesProgress?.length);
		};

		const generalProgress = calculateGeneralProgress();

		const progressBlock = (
			<div className="my-10">
				<ProgressBar progressPercent={generalProgress} />
				<div className="mt-2 flex justify-between">
					<div className="text-xs">Uploading Files</div>
					<div className="text-xs">{generalProgress}%</div>
				</div>
			</div>
		);

		return generalProgress < 100 ? progressBlock : null;
	}, [filesProgress, policyFiles?.length]);

	const renderEmptyState = useMemo(() => {
		return (
			<>
				{policyFiles?.length ? (
					<>
						<div className="my-6">
							{policyFiles.map((file) => (
								<FileInfo
									key={file.name}
									file={file}
									setPolicyFiles={setPolicyFiles}
									setUploadedFileIds={setUploadedFileIds}
									setFilesProgress={setFilesProgress}
									getPreassignedUrlForPolicyCall={getPreassignedUrlForPolicyCall}
								/>
							))}
						</div>
					</>
				) : (
					<div className="pt-6 text-sm text-blueberry-100">
						You don’t have any privacy policy uploaded to this hub. Click “Upload” to
						upload a file.
					</div>
				)}
			</>
		);
	}, [getPreassignedUrlForPolicyCall, policyFiles]);

	const renderActions = useMemo(() => {
		return (
			<>
				{filesLoading ? (
					renderProgress
				) : (
					<div className="pt-6 flex flex-row-reverse">
						<FilePicker
							acceptExtensions={SUPPORTED_TRUST_HUB_POLICY_TYPES}
							setAcceptedFiles={setPolicyFiles}
							multipleSelection={false}
							showDropZone={false}
							buttonText="Upload"
							buttonClassName="min-w-[82px]"
						/>
					</div>
				)}
			</>
		);
	}, [filesLoading, renderProgress]);

	return (
		<div>
			{renderEmptyState}
			{renderActions}
		</div>
	);
};
