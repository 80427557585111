import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getTrustHubFrameworksColumns } from './utils';
import styles from './AddTrustHubFrameworkTable.module.scss';
import { ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import IAddTrustHubFrameworkTable from './IAddTrustHubFrameworkTable';
import { Table } from '../../../primitives';
import { IFramework } from '../../../../services/store/slices/frameworks.slice';

const AddTrustHubFrameworkTable: FC<IAddTrustHubFrameworkTable> = ({
	className,
	data,
	selectedRows,
	setSelectedRows,
	bodyMaxHeight = 360,
	primaryFrameworkId
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IFramework[]>([]);
	const disabledIds = useMemo(() => data.filter(item => item.name.includes('NIST')).map(item => item.id), [data]);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	useEffect(() => {
		if (data.length) {
			const processedData: IFramework[] = processTableData(data, {}, currentSort);

			setDataToDisplay(processedData);
		}
	}, [currentSort, data]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			<Table
				tableKey="add-control"
				data={dataToDisplay}
				columns={getTrustHubFrameworksColumns(primaryFrameworkId)}
				options={{
					rowCheckbox: true,
					rowClassName: styles.row,
					onSortChange,
					emptyResultMessage: !data.length
						? 'No controls to display.'
						: 'No matches found. Please try another search query.',
					emptyResultMessageType: !data.length ? 'common' : 'search',
					bodyMaxHeight,
					small: true,
					scrollFullRightPosition: true,
					selectedRows,
					setSelectedRows,
					disabledIds,
				}}
			/>
		</div>
	);
};

export default AddTrustHubFrameworkTable;
