import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';
import styles from './Textarea.module.scss';
import { Error } from '../../error/Error';
import { ITextarea } from './ITextarea';
import classNames from 'classnames';
import { setTextareaHeight } from '../../../../utils/helpers/common/form';

export const Textarea: FC<ITextarea> = forwardRef(
	(
		{
			id,
			name,
			placeholder,
			disabled,
			readOnly,
			value,
			defaultValue,
			error,
			className,
			label,
			onChange,
			onValueChange,
			withErrorStyle,
			asInput,
		},
		ref,
	) => {
		const [currentValue, setCurrentValue] = useState('');
		const textareaRef = useRef<HTMLTextAreaElement>(ref as unknown as HTMLTextAreaElement);
		const wrapperRef = useRef<HTMLDivElement>(null);

		const onChangeHandler = (e: any) => {
			setCurrentValue(e.target.value);
			if (onValueChange) onValueChange(e.target.value);
			if (onChange) onChange(e);
		};

		useEffect(() => {
			const textarea = textareaRef.current;
			const wrapper = wrapperRef.current;

			textarea?.addEventListener('input', () =>
				setTextareaHeight(textarea, wrapperRef.current),
			);
			setTextareaHeight(textarea, wrapperRef.current);

			return () => {
				textarea?.removeEventListener('input', () => setTextareaHeight(textarea, wrapper));
			};
		});

		useEffect(() => {
			setCurrentValue((value as string) || (defaultValue as string) || '');
		}, [value, defaultValue]);

		const textarea = (
			<div
				ref={wrapperRef}
				className={classNames(
					styles['textarea-wrap'],
					withErrorStyle ? styles.error : '',
					disabled ? styles.disabled : '',
					asInput ? styles['as-input'] : '',
				)}
			>
				<textarea
					ref={textareaRef}
					value={currentValue as string}
					onChange={onChangeHandler}
					{...{ name, id, placeholder, disabled, readOnly }}
				/>
			</div>
		);

		return (
			<div className={className}>
				{label ? (
					<label className={styles.label} htmlFor={id}>
						{label}
					</label>
				) : null}

				{textarea}

				{error ? <Error disabled={disabled} message={error} /> : null}
			</div>
		);
	},
);
