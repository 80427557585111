import React, { FC } from 'react';
import styles from './RemoveAction.module.scss';
import {IRemoveAction} from './IRemoveAction';
import classNames from 'classnames';

export const RemoveAction: FC<IRemoveAction> = ({
	className,
	text,
	onClickHandler,
	tooltipId,
	tooltipContent,
}) => {
	return (
		<div className={classNames(styles['remove-action'], className)} onClick={onClickHandler}>
			<span
				{...(tooltipId ? { 'data-tooltip-id': tooltipId } : {})}
				{...(tooltipContent ? { 'data-tooltip-content': tooltipContent } : {})}
				className={styles.text}
			>
				{text || 'Remove'}
			</span>
		</div>
	);
};

