interface TrustHubNotFoundProps {
	logo?: string;
}

export const TrustHubNotFound = ({ logo }: TrustHubNotFoundProps) => {
	return (
		<div className="min-h-screen w-[1200px] min-h-screen flex flex-col items-start justify-center max-w-[100vw] py-[60px] px-5 relative">
			{!!logo && <img src={logo} alt="GRC Logo" />}
			<h1 className='mb-7 mt-5'>Not found</h1>
			<p>We can't find the page you are looking for.</p>
		</div>
	);
};
