import { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../primitives';
import ModalAddTrustHubFrameworks from '../../../modals/modal-add-trust-hub-frameworks/ModalAddTrustHubFrameworks';
import { IFramework } from '../../../../../services/store/slices/frameworks.slice';
import SelectedTrustHubFrameworksTable from '../../../tables/selected-trust-hub-frameworks-table/SelectedTrustHubFrameworksTable';
import { ITrustHubFrameworksEvaluations } from '../../../../../services/store/slices/trust-hub.slice';

export interface FrameworksBlockProps {
	frameWorksEvaluations: ITrustHubFrameworksEvaluations[] | null;
	preSelectedFrameworks: IFramework[];
	setFrameworks: (frameworks?: IFramework[]) => void;
}

export const FrameworksBlock = ({ frameWorksEvaluations, preSelectedFrameworks, setFrameworks }: FrameworksBlockProps) => {
	const [openAddTrustHubFrameworks, setOpenAddTrustHubFrameworks] = useState(false);
	const [selectedFrameworks, setSelectedFrameworks] = useState<IFramework[]>(preSelectedFrameworks);
	const [trustHubSelectedFrameworks, setTrustHubSelectedFrameworks] = useState<string[]>(preSelectedFrameworks.map((framework) => framework.id) as string[]);

	useEffect(() => {
		const newFrameworks = selectedFrameworks.filter((framework, idx, arr) => arr.findIndex((f) => framework.id === f.id) === idx);

		setTrustHubSelectedFrameworks(newFrameworks.map((framework) => framework.id) as string[]);
		setFrameworks(newFrameworks);
	}, [selectedFrameworks, setFrameworks, setSelectedFrameworks]);

	const renderUploadHint = useMemo(() => {
		return (
			<div className="pt-6 text-sm text-blueberry-100">
				{
					!!selectedFrameworks.length ? 
					'Add the frameworks that you’d like to be presented on Trust Hub' :
					'You don’t have any frameworks added to this hub. Click “Add Frameworks” to select frameworks.'
				}
			</div>
		);
	}, [selectedFrameworks]);


	const renderContent = useMemo(() => {
		return (
			!!selectedFrameworks.length && (
				<SelectedTrustHubFrameworksTable
					data={selectedFrameworks}
					frameWorksEvaluations={frameWorksEvaluations}
					selectedRows={trustHubSelectedFrameworks}
					setSelectedRows={setTrustHubSelectedFrameworks}
					setSelectedFrameworks={setSelectedFrameworks}
				/>
			)
		);
	}, [frameWorksEvaluations, selectedFrameworks, trustHubSelectedFrameworks]);

	const modals = useMemo(() => {
		return (
			<ModalAddTrustHubFrameworks
				open={openAddTrustHubFrameworks}
				setOpen={setOpenAddTrustHubFrameworks}
				predefinedItems={selectedFrameworks}
				onSelectedAdd={(selected) => {
					setSelectedFrameworks(selected);
				}}
				cancelBtnText="Cancel"
				onCancel={() => { }}
			/>
		);
	}, [selectedFrameworks, openAddTrustHubFrameworks]);

	const renderActions = useMemo(() => {
		return (
			<div className="pt-6 flex flex-row-reverse">
				<Button
					type="button"
					negative
					small
					width={132}
					onClick={() => setOpenAddTrustHubFrameworks(true)}
				>
					Add Frameworks
				</Button>
			</div>
		);
	}, []);

	return (
		<div>
			<div className='flex flex-row justify-between items-center gap-4'>
				{renderUploadHint}
				{renderActions}
			</div>
			{renderContent}
			{modals}
		</div>
	);
};
