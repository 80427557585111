import { FC } from 'react';
import styles from './EditControl.module.scss';

import classNames from 'classnames';
import {
	LoadingSkeleton,
	CommentsSkeleton,
	TagsSkeleton,
	HistorySkeleton,
} from '../../../primitives';

const EditControlSkeleton: FC = () => {
	const renderVariablesSkeleton = () => {
		return (
			<div className={styles.section}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<LoadingSkeleton height={24} width={'calc(50% - 8px)'} />

				<div className={classNames(styles['input-group'], styles.variables)}>
					<LoadingSkeleton height={40} />
					<LoadingSkeleton height={40} />
				</div>
			</div>
		);
	};

	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<LoadingSkeleton containerClassName={styles.input} height={60} />

				<LoadingSkeleton containerClassName={styles.input} height={60} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={60} />

					<LoadingSkeleton height={60} />
				</div>

				<LoadingSkeleton containerClassName={styles['input-with-info']} height={156} />

				{renderVariablesSkeleton()}

				<TagsSkeleton />
				<CommentsSkeleton />
				<HistorySkeleton />
			</div>
		</div>
	);

	return <div className={styles['edit-control-skeleton']}>{editForm}</div>;
};

export default EditControlSkeleton;
