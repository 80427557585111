import React, { FC, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser, useAuthorization } from '../../hooks';
import BasicLayout from '../../../../components/layouts/basic-layout/BasicLayout';
import { AppUrls, TRUST_HUB_ROOT } from '../../constants/app-routes';
import { handleWithTryCatch } from '../../errors';
import { useAppDispatch } from '../../../../services/store';
import { getUserInfo } from '../../../../services/store/slices/user.slice';
import LoginRedirectionContext from '../../contexts/login-redirection-context';
import { setLoginRedirectionState } from '../../../../services/store/custom-reducers/login-redirection-path-reducer';

const UnprotectedRoute: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { authorized } = useUser();
	const { mfaCodeRequired } = useAuthorization();
	const location = useLocation();

	const {
		state: { pathname: loginRedirectionPath, search: loginRedirectionSearch },
		dispatch: dispatchLoginRedirection,
	} = useContext(LoginRedirectionContext);

	const isTrustHub = useMemo(() => location.pathname.includes(TRUST_HUB_ROOT), [location.pathname]);

	useEffect((): any => {
		if (isTrustHub) return;

		if (mfaCodeRequired && !authorized) {
			return navigate(AppUrls.verify, { replace: true });
		} else if (authorized) {
			if (loginRedirectionPath) {
				const pathnameWithQuery = `${loginRedirectionPath}${loginRedirectionSearch}`;

				return navigate(pathnameWithQuery, { replace: true });
			}

			return navigate(AppUrls.home, { replace: true });
		} else
			handleWithTryCatch(async () => {
				// check if user is authorized
				await dispatch(getUserInfo());
				return navigate(AppUrls.home, { replace: true });
			});

		return () => {
			if (authorized) dispatchLoginRedirection(setLoginRedirectionState(null, null));
		};
	}, [authorized, dispatch, dispatchLoginRedirection, isTrustHub, location.pathname, loginRedirectionPath, loginRedirectionSearch, mfaCodeRequired, navigate]);

	return <BasicLayout>{children}</BasicLayout>;
};

export default UnprotectedRoute;
