import { FC, useCallback, useEffect, useState } from 'react';
import styles from './VariableItem.module.scss';
import { Select } from '../../primitives';
import { Input, ColoredTag, VariableTooltip } from '../../primitives';
import { VariableTypes, VariableUnits } from '../../../utils/helpers/constants';
import classNames from 'classnames';
import { useUser } from '../../../utils/helpers/hooks';
import IVariableItem from './IVariableItem';
import { Button } from '../../primitives';
import editIcon from '../../../assets/images/icon-edit.svg';
import plusIcon from '../../../assets/images/icon-plus-dark.svg';
import { variableValueIsValid } from '../../../utils/helpers/common/form';

const VariableItem: FC<IVariableItem> = ({
	className,
	variable,
	onPairValueChange,
	onSingleValueChange,
}) => {
	const { isAdmin } = useUser();
	const { id, type, value } = variable;

	const [editMode, setEditMode] = useState(false);
	const [inputValue, setInputValue] = useState(value[0]);
	const [inputUnit, setInputUnit] = useState(value[1]);

	const onVariableTagClick = (e: React.MouseEvent, tagId: string) => {
		if (!isAdmin) return;
		setEditMode(true);
	};

	const onSaveClick = useCallback(() => {
		setEditMode(false);

		if (type !== VariableTypes.time) {
			onSingleValueChange(inputValue, id);
		} else {
			onPairValueChange(inputValue, inputUnit, id);
		}
	}, [id, inputUnit, inputValue, onPairValueChange, onSingleValueChange, type]);

	const onCancelClick = () => {
		setEditMode(false);
		setInputValue(value[0] || '');
		setInputUnit(value[1] || '');
	};

	const renderInputDialog = () => {
		const getInputs = () => {
			switch (type) {
				case VariableTypes.text:
					return (
						<Input
							id={`${id}-value`}
							name={`${id}-value`}
							className={classNames(styles.input, styles.single)}
							type="text"
							value={inputValue}
							placeholder="Enter value"
							withErrorStyle={!variableValueIsValid(inputValue)}
							error={
								!variableValueIsValid(inputValue)
									? 'The input field must allow alpha-numeric input, dot, coma and space'
									: ''
							}
							errorClassName={styles.error}
							onValueChange={(value) => setInputValue(value)}
						/>
					);
				case VariableTypes.numeric:
					return (
						<Input
							id={`${id}-value`}
							name={`${id}-value`}
							className={classNames(styles.input, styles.single)}
							type="number"
							min={0}
							value={inputValue}
							placeholder="Enter value"
							onValueChange={(value) => setInputValue(value)}
						/>
					);
				default:
					return (
						<div className={styles['variable-value-group']}>
							<Input
								id={`${id}-value`}
								name={`${id}-value`}
								className={styles.input}
								type="number"
								min={0}
								value={inputValue}
								placeholder="Enter value"
								onValueChange={(value) => setInputValue(value)}
							/>

							<Select
								id={`${id}-unit`}
								name={`${id}-unit`}
								className={styles.input}
								options={VariableUnits}
								value={inputUnit}
								placeholder="Select unit"
								onValueChange={(value) => setInputUnit(value)}
								withEmptyOption
								emptyOptionLabel="Select unit"
							/>
						</div>
					);
			}
		};

		const saveIsDisabled = () => {
			if (type === VariableTypes.text) return !variableValueIsValid(inputValue);

			if (type === VariableTypes.time) {
				if (inputValue && !inputUnit) return true;
				if (!inputValue && inputUnit) return true;
			}

			return false;
		};

		return (
			<div className={styles.dialog}>
				{getInputs()}

				<div className={styles.actions}>
					<Button
						className={styles.action}
						width={73}
						small
						type="button"
						negative
						onClick={onCancelClick}
					>
						Cancel
					</Button>

					<Button
						className={styles.action}
						width={62}
						small
						type="button"
						disabled={saveIsDisabled()}
						onClick={onSaveClick}
					>
						Save
					</Button>
				</div>
			</div>
		);
	};

	const renderValueTag = () => {
		if (type === VariableTypes.time && value[0] && value[1])
			return (
				<ColoredTag
					className={classNames(
						styles.tag,
						styles['with-value'],
						editMode ? styles.focus : '',
						!isAdmin ? styles.disabled : '',
					)}
					tagId={id}
					text={`${value[0]} ${value[1]}`}
					onClickHandler={onVariableTagClick}
					iconOnRight={isAdmin ? editIcon : ''}
				/>
			);

		if (value[0])
			return (
				<ColoredTag
					className={classNames(
						styles.tag,
						styles['with-value'],
						editMode ? styles.focus : '',
						!isAdmin ? styles.disabled : '',
					)}
					tagId={id}
					text={value[0]}
					onClickHandler={onVariableTagClick}
					iconOnRight={isAdmin ? editIcon : ''}
				/>
			);

		return (
			<ColoredTag
				className={classNames(
					styles.tag,
					styles.add,
					editMode ? styles.focus : '',
					!isAdmin ? styles.disabled : '',
				)}
				tagId={variable.id}
				text="Add Value"
				onClickHandler={onVariableTagClick}
				iconOnRight={plusIcon}
			/>
		);
	};

	useEffect(() => {
		const onEnterPress = (e: KeyboardEvent) => {
			if (editMode && e.key === 'Enter' && !e.shiftKey) onSaveClick();
		};

		window.addEventListener('keydown', onEnterPress);
		return () => window.removeEventListener('keydown', onEnterPress);
	}, [editMode, onSaveClick]);

	return (
		<div className={classNames(styles['variable-item'], className)}>
			<span data-tooltip-id={`tooltip-${variable.id}`}>{renderValueTag()}</span>

			<VariableTooltip
				hidden={!editMode}
				tooltipId={`tooltip-${variable.id}`}
				render={renderInputDialog}
				setEditMode={setEditMode}
			/>
		</div>
	);
};

export default VariableItem;
