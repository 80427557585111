import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export function useCompanyUsers() {
	const { loading, updateLoading, items } = useSelector((store: RootState) => store.companyUsers);

	return {
		loading,
		updateLoading,
		items,
	};
}
