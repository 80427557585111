import iconCheck from '../../assets/images/icon-check.svg';

export const OverviewControlItem = ({ control }: { control: string }) => {
  return (
    <li className="flex justify-between items-center text-xs gap-4">
      <span className="flex-1 inline-block">{control}</span>
      <span className="bg-leaf-75 inline-flex items-center gap-1 rounded-2xl py-[2px] px-[4px] text-[10px] leading-[10px] text-blueberry-500">
        <img className="w-[10px] h-[10px]" src={iconCheck} alt="Check icon" />
        Implemented
      </span>
    </li>
  );
};