import { ICompany } from '../../../../services/store/slices/company.slice';
import { ITrustHubFrameworksEvaluations } from '../../../../services/store/slices/trust-hub.slice';
import { dateInMDYTimeFormat } from '../../../../utils/helpers/common';
import { getStatusType } from '../../../../utils/helpers/common/getStatusType';
import { CommonResultStatusType } from '../../../../utils/types';
import { StatusInfo } from '../../../primitives';
import { ITableColumn } from '../../../primitives/table/ITable';

export const getTrustHubFrameworksColumns = (frameWorksEvaluations: ITrustHubFrameworksEvaluations[] | null, companyInfo?: Partial<ICompany> | null) => {
	return [
		{
			header: 'Name',
			key: 'name',
			sorting: false,
			width: '40%',
			columnRender: (value: string) => <span>{value}</span>,
		},
		{
			header: 'Compliance %',
			key: 'compliance',
			sorting: false,
			width: '20%',
			columnRender: (value: number) => (
				<StatusInfo
					className='w-[50px] !mt-0'
					type={getStatusType(value) as CommonResultStatusType}
				>
					{value}%
				</StatusInfo>
			),
		},
		{
			header: "Evaluation Date",
			key: "evaluationDate",
			sorting: false,
			width: "40%",
			columnRender: (value: Date) => {
				const date = new Date(value);
				return <span>{dateInMDYTimeFormat(date, companyInfo?.locationTimeZone)}</span>
			}
		}
	] as ITableColumn[];
};
