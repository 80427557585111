import { FC, useEffect, useState } from 'react';
import styles from './ControlsStep.module.scss';
import { ActionsBlock, Button } from '../../../../../primitives';
import AddControlsTable from '../../../../tables/add-controls-table/AddControlsTable';
import { useControls } from '../../../../../../utils/helpers/hooks';
import { IModalCreateTagStep } from '../../IModalCreateTag';
import { IIdName } from '../../../../../../utils/types';

const ControlsStep: FC<IModalCreateTagStep> = ({
	setOpen,
	currentFormState,
	setCurrentFormState,
	setNextStep,
}) => {
	const { items: controlItems } = useControls();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const onSkipClick = () => {
		setCurrentFormState((prev) => ({ ...prev, controls: [] }));
		if (setNextStep) setNextStep();
	};

	const actions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={96} negative onClick={() => setOpen(false)}>
				Cancel
			</Button>

			<div className={styles.group}>
				<Button
					className={styles.action}
					type="button"
					width={66}
					onClick={onSkipClick}
					negative
				>
					Skip
				</Button>

				<Button className={styles.action} type="button" width={66} onClick={setNextStep}>
					Next
				</Button>
			</div>
		</ActionsBlock>
	);

	useEffect(() => {
		if (currentFormState?.controls?.length) setSelectedRows(currentFormState.controls);
	}, [currentFormState.controls]);

	useEffect(() => {
		setCurrentFormState((prev) => ({ ...prev, controls: selectedRows }));
	}, [selectedRows, setCurrentFormState]);

	const createForm = (
		<div className={styles['add-controls']}>
			<div className={styles['table-wrap']}>
				<p>
					Select the <strong>Controls</strong> to associate with the tag{' '}
					<strong>{currentFormState.name}</strong>
				</p>

				<AddControlsTable
					bodyMaxHeight={160}
					withSearch={false}
					data={
						controlItems?.map(
							(control) =>
								({
									id: control.id,
									displayId: control.controlId,
									name: control.name,
								}) as IIdName,
						) || []
					}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
				/>
			</div>

			{actions}
		</div>
	);

	return createForm;
};

export default ControlsStep;
