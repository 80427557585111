import { FC } from 'react';
import styles from './AssetDetails.module.scss';
import {
	LoadingSkeleton,
	CommentsSkeleton,
	TagsSkeleton,
	HistorySkeleton,
} from '../../../../primitives';

const AssetDetailsSkeleton: FC = () => {
	const editForm = (
		<>
			<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />

			<TagsSkeleton />
			<CommentsSkeleton />
			<HistorySkeleton />
		</>
	);

	return <div className={styles['asset-details-skeleton']}>{editForm}</div>;
};

export default AssetDetailsSkeleton;
