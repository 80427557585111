import React, { FC } from 'react';
import styles from './ReadMore.module.scss';
import { IReadMore } from './IReadMore';
import classNames from 'classnames';

export const ReadMore: FC<IReadMore> = ({ className, text, onClickHandler }) => {
	return (
		<div className={classNames(styles['read-more'], className)} onClick={onClickHandler}>
			<span className={styles.text}>{text || 'Read more'}</span>
		</div>
	);
};
