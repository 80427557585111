import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditPolicy.module.scss';
import {
	ActionsBlock,
	Button,
	ColoredTag,
	CollapsiblePanel,
	Drawer,
	Error,
	Input,
	Loader,
	Select,
	SectionTitle,
	Scrollbar,
	Textarea,
} from '../../../../primitives';
import {
	CommonColors,
	CommonStatuses,
	PolicyStatusOptions,
	VariableUnits,
} from '../../../../../utils/helpers/constants';
import { useAppDispatch } from '../../../../../services/store';
import CommentsBlock from '../../../comments-block/CommentsBlock';
import ChangesHistoryBlock from '../../../changes-history-block/ChangesHistoryBlock';
import { IEditPolicy } from './IEditPolicy';
import {
	IPolicy,
	IUpdatePolicy,
	addCommentToPolicy,
	downloadPolicyFile,
	getPolicyById,
	updatePolicy,
} from '../../../../../services/store/slices/policies.slice';
import {
	controlsAreDifferent,
	getOwnerOptions,
	sanitizeData,
	tagsAreDifferent,
} from '../../../../../utils/helpers/common';
import EditPolicySkeleton from './EditPolicySkeleton';
import TagsAndControlsBlock from '../../../tags-and-controls-block/TagsAndControlsBlock';
import { useUser, useLeaveWarning, useCompanyUsers, usePolicies } from '../../../../../utils/helpers/hooks';
import ModalLeaveWarning from '../../../modals/modal-leave-warning/ModalLeaveWarning';
import { EntityTypes, IIdName } from '../../../../../utils/types';
import { ICompanyUser } from '../../../../../services/store/slices/company-users.slice';
import { commonStringIsValid } from '../../../../../utils/helpers/common/form';
import { Alert } from '../../../../';
import { useFileDownload } from '../../../../../utils/helpers/hooks/useFileDownload';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { ITagBrief } from '../../../../../services/store/slices/tags.slice';

const EditPolicy: FC<IEditPolicy> = ({
	policyId,
	onUpdateError,
	onUpdateSuccess,
	onUpdateBeforeDownloadSuccess,
	closeHandler,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { items: companyUsers } = useCompanyUsers();
	const { isAdmin, info: userInfo } = useUser();
	const { policyLoading, updateLoading, commentsLoading } = usePolicies();

	const { setDownloadResult, downloadResult, downloadFileByUrl } = useFileDownload();

	const [currentPolicy, setCurrentPolicy] = useState<IPolicy | null>();
	const {
		id,
		name,
		status,
		owner,
		preamble,
		description,
		controls,
		tags,
		version,
		reviewValue,
		reviewUnit,
		comments,
		history,
	} = currentPolicy || {};

	const [loading, setLoading] = useState(false);
	const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
	const [currentFormState, setCurrentFormState] = useState<IUpdatePolicy | null>();
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setShowBrowserLeaveWarning] = useState(false);
	const [error, setError] = useState('');
	const [commentIsAdding, setCommentIsAdding] = useState(false);
	const [fileLoading, setFileLoading] = useState(false);
	const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('');

	const initialEditFormState: IUpdatePolicy = useMemo(
		() => ({
			id,
			name,
			status,
			ownerId: owner?.id || 'unassigned',
			controls,
			tags,
			description,
			preamble,
			reviewValue,
			reviewUnit,
		}),
		[
			id,
			name,
			status,
			owner?.id,
			controls,
			tags,
			description,
			preamble,
			reviewValue,
			reviewUnit,
		],
	);

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id),
		[companyUsers, userInfo?.id],
	);

	const onFormSubmitHandler = async (withDownload?: boolean) => {
		if (submitIsDisabled) return null;

		const transformedOwner =
			companyUsers?.find((o: ICompanyUser) => o.id === currentFormState?.ownerId)?.id || null;

		const transformedPeriod = {
			[`${currentFormState?.reviewUnit}`]: currentFormState?.reviewValue,
		};

		const sanitizedData = sanitizeData(currentFormState);

		await handleWithTryCatch(
			async () => {
				await dispatch(
					updatePolicy(policyId, {
						...sanitizedData,
						companyControlIds: sanitizedData.controls?.map((c: IIdName) => c.id),
						ownerId: transformedOwner,
						reviewPeriod: transformedPeriod,
						tags: sanitizedData.tags?.map((t: ITagBrief) => t.id),
					}),
				);

				if (!withDownload) onUpdateSuccess();
				else {
					onPolicyDownload();
					updateCurrentPolicy();
					onUpdateBeforeDownloadSuccess();
				}
			},
			undefined,
			onUpdateError,
		);
	};

	const onCommentAdded = useCallback(() => {
		setCommentIsAdding(true);

		handleWithTryCatch(
			async () => {
				const policy = await dispatch(getPolicyById(policyId));
				const newComments = policy?.comments;
				setCurrentPolicy((prev: IPolicy | null | undefined) => ({
					...prev!,
					comments: newComments,
				}));

				setCommentIsAdding(false);
			},
			setError,
			() => setCommentIsAdding(false),
		);
	}, [policyId, dispatch]);

	const renderChangesHistory = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>History</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<ChangesHistoryBlock changes={history} />
			</CollapsiblePanel>
		);
	};

	const renderTagsAndControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Tags and Controls</SectionTitle>
		);

		const setPolicyControls = (newControls: IIdName[]) => {
			setCurrentFormState((prev) => ({ ...prev, controls: newControls }));
		};

		const setPolicyTags = (newTags: ITagBrief[]) => {
			setCurrentFormState((prev) => ({ ...prev, tags: newTags }));
		};

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<TagsAndControlsBlock
					entityControlIds={currentFormState?.controls || []}
					entityTagIds={currentFormState?.tags || []}
					setControlIds={setPolicyControls}
					setTagIds={setPolicyTags}
					isViewMode={!isAdmin}
				/>
			</CollapsiblePanel>
		);
	};

	const renderComments = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Comments</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<CommentsBlock
					entityId={id}
					entityType={EntityTypes.CompanyPolicy}
					comments={comments}
					addDispatchHandler={addCommentToPolicy}
					commentsLoading={commentsLoading || commentIsAdding}
					onAddSuccess={onCommentAdded}
				/>
			</CollapsiblePanel>
		);
	};

	const changesWereMade = useMemo(() => {
		if (currentFormState?.id && currentPolicy) {
			const controlsDiffer = controlsAreDifferent(
				currentFormState?.controls,
				initialEditFormState?.controls,
			);

			const tagsDiffer = tagsAreDifferent(currentFormState?.tags, initialEditFormState?.tags);

			if (
				currentFormState?.name !== initialEditFormState.name ||
				currentFormState?.description !== initialEditFormState.description ||
				currentFormState?.status !== initialEditFormState.status ||
				currentFormState?.ownerId !== initialEditFormState.ownerId ||
				currentFormState?.reviewValue !== initialEditFormState.reviewValue ||
				currentFormState?.reviewUnit !== initialEditFormState.reviewUnit ||
				currentFormState?.preamble !== initialEditFormState.preamble ||
				controlsDiffer ||
				tagsDiffer
			) {
				return true;
			}

			return false;
		}

		return false;
	}, [
		currentFormState?.id,
		currentFormState?.controls,
		currentFormState?.tags,
		currentFormState?.name,
		currentFormState?.description,
		currentFormState?.status,
		currentFormState?.ownerId,
		currentFormState?.reviewValue,
		currentFormState?.reviewUnit,
		currentFormState?.preamble,
		currentPolicy,
		initialEditFormState?.controls,
		initialEditFormState?.tags,
		initialEditFormState.name,
		initialEditFormState.description,
		initialEditFormState.status,
		initialEditFormState.ownerId,
		initialEditFormState.reviewValue,
		initialEditFormState.reviewUnit,
		initialEditFormState.preamble,
	]);

	const updateCurrentPolicy = useCallback(() => {
		handleWithTryCatch(
			async () => {
				const policy = await dispatch(getPolicyById(policyId));

				setCurrentPolicy(policy);
				setLoading(false);
			},
			setError,
			() => setLoading(false),
		);
	}, [policyId, dispatch]);

	const onPolicyDownload = useCallback(() => {
		setFileLoading(true);

		if (id) {
			handleWithTryCatch(
				async () => {
					const result = await dispatch(downloadPolicyFile(id));

					//@ts-ignore
					const fileUrl = result.file;

					if (fileUrl) {
						await downloadFileByUrl(fileUrl, `${currentFormState?.name || name}.pdf`);
						setTimeout(() => setFileLoading(false), 1000);
					} else {
						setFileLoading(false);
						setDownloadResult('error');
					}
				},
				undefined,
				(message) => {
					setFileLoading(false);
					setDownloadErrorMessage(message || '');
					setDownloadResult('error');
				},
			);
		}
	}, [currentFormState?.name, dispatch, downloadFileByUrl, id, name, setDownloadResult]);

	const onDownloadClick = (save: boolean) => {
		if (save) onFormSubmitHandler(true);
		else {
			onPolicyDownload();
		}
	};

	const renderFormActions = () => {
		const renderBtnText = () => {
			if (policyLoading || updateLoading || fileLoading)
				return <Loader thin maxHeight={14} maxWidth={14} />;
			if (changesWereMade && isAdmin) return 'Save & Download';
			return 'Download';
		};

		const btnsDisabled = !!(submitIsDisabled || policyLoading || updateLoading || fileLoading);

		return (
			<ActionsBlock className={styles.actions}>
				<div className={styles.group}>
					<Button
						className={styles.action}
						width={changesWereMade ? 172 : 133}
						negative
						type="button"
						disabled={btnsDisabled}
						onClick={() => onDownloadClick(changesWereMade)}
					>
						{renderBtnText()}
					</Button>

					{isAdmin ? (
						<Button
							className={styles.action}
							width={132}
							type="button"
							disabled={btnsDisabled}
							onClick={() => onFormSubmitHandler()}
						>
							{policyLoading || updateLoading ? (
								<Loader thin maxHeight={14} maxWidth={14} />
							) : (
								'Save'
							)}
						</Button>
					) : null}
				</div>
			</ActionsBlock>
		);
	};

	const modals = (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				setShowBrowserLeaveWarning(false);
				closeHandler();
			}}
		/>
	);

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<div className={styles['input-group']}>
					<Select
						id="owner"
						disabled={!isAdmin}
						onValueChange={(value) => {
							setCurrentFormState((prev) => ({ ...prev, ownerId: value }));
						}}
						defaultValue={currentFormState?.ownerId}
						className={styles.input}
						label="Owner"
						options={ownersOptions || []}
					/>

					<Input
						disabled
						type="text"
						className={styles.input}
						value={`v${version || 1}`}
						label="Policy Version"
					/>
				</div>

				<div className={styles['input-group']}>
					<Select
						id="status"
						disabled={!isAdmin}
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, status: value }))
						}
						defaultValue={currentFormState?.status}
						className={styles.input}
						options={PolicyStatusOptions}
						label="Status"
					/>

					<div className={styles['input-group']}>
						<Input
							disabled={!isAdmin}
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({
									...prev,
									reviewValue: value,
								}))
							}
							type="number"
							min={1}
							className={styles.input}
							value={currentFormState?.reviewValue}
							placeholder='e.g. "30"'
							label="Review Period (every)"
							withErrorStyle={
								!!(currentFormState?.reviewUnit && !currentFormState?.reviewValue)
							}
						/>

						<Select
							id="reviewUnit"
							disabled={!isAdmin}
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({
									...prev,
									reviewUnit: value,
								}))
							}
							withErrorStyle={
								!!(!currentFormState?.reviewUnit && currentFormState?.reviewValue)
							}
							defaultValue={currentFormState?.reviewUnit}
							className={styles.input}
							placeholder="Select unit"
							options={VariableUnits}
							withEmptyOption
							emptyOptionLabel="Select unit"
						/>
					</div>
				</div>

				<Input
					disabled={!isAdmin}
					type="text"
					className={styles.input}
					value={currentFormState?.name}
					withErrorStyle={
						!!(
							!currentFormState?.name ||
							(currentFormState?.name && !commonStringIsValid(currentFormState?.name))
						)
					}
					label="Policy Name"
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, name: value }))
					}
				/>

				<Textarea
					disabled={!isAdmin}
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, description: value }))
					}
					defaultValue={currentFormState?.description}
					className={styles.input}
					placeholder="Enter or paste policy description here..."
					label="Policy Description (optional)"
					asInput
				/>

				<SectionTitle
					className={styles.content}
					withTag={
						currentFormState?.status === CommonStatuses['pending-review'] && (
							<ColoredTag
								tagId="action"
								small
								text={'Review Content'}
								bgColor={CommonColors.alertTag}
							/>
						)
					}
				>
					Content
				</SectionTitle>

				<p className={styles.note}>You can view the file after downloading it</p>

				<Textarea
					disabled={!isAdmin}
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, preamble: value }))
					}
					defaultValue={currentFormState?.preamble}
					className={styles.input}
					placeholder="Enter or paste policy preamble here..."
					label="Preamble"
				/>
			</CollapsiblePanel>
		);
	};

	const editForm = (
		<form>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails()}

					{renderTagsAndControls()}

					{renderComments()}

					{renderChangesHistory()}
				</div>
			</Scrollbar>

			{renderFormActions()}
		</form>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'download-success'}
				show={downloadResult === 'success'}
				type="success"
				message="Policy downloaded"
				clearActionStatus={() => setDownloadResult('')}
			/>
			<Alert
				uniqueKey={'download-error'}
				show={downloadResult === 'error'}
				type="error"
				message={downloadErrorMessage || "Policy wasn't downloaded. Please try again"}
				clearActionStatus={() => setDownloadResult('')}
				hideDelayTime={4000}
			/>
		</>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade) setShowBrowserLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		setCurrentFormState(initialEditFormState);
	}, [initialEditFormState]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (currentFormState?.reviewUnit && !currentFormState?.reviewValue)
			return setSubmitIsDisabled(true);
		if (currentFormState?.reviewValue && !currentFormState?.reviewUnit)
			return setSubmitIsDisabled(true);

		if (
			!currentFormState?.name ||
			(currentFormState?.name && !commonStringIsValid(currentFormState?.name))
		)
			return setSubmitIsDisabled(true);
		setSubmitIsDisabled(false);
	}, [currentFormState?.name, currentFormState?.reviewUnit, currentFormState?.reviewValue]);

	useEffect(() => {
		if (!currentPolicy && policyId) setLoading(true);
	}, [currentPolicy, policyId]);

	useEffect(() => {
		if (policyId && !currentPolicy && !loading && !error) {
			updateCurrentPolicy();
		}
	}, [currentPolicy, error, loading, policyId, updateCurrentPolicy]);

	useEffect(() => {
		if (!open) {
			setCurrentPolicy(null);
			setShowBrowserLeaveWarning(false);
			setSubmitIsDisabled(false);
			setError('');
		}
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			title="Policy Details"
			open={open}
			onCloseClickHandler={() => {
				if (changesWereMade) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{error ? <Error message={error} /> : null}

			{loading ? (
				<EditPolicySkeleton />
			) : (
				<div className={styles['edit-policy']}>
					{id ? editForm : null}

					{modals}

					{alerts}
				</div>
			)}
		</Drawer>
	);
};

export default EditPolicy;
