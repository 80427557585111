import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CompanyProfile.module.scss';
import { ActionsBlock, Button, ColoredTag } from '../../components/primitives';
import {
	Alert,
	BasicPanel,
	EditCompany,
	EditTrustHub,
	DataList,
	DataListItem,
} from '../../components';
import {
	useCompany,
	useGetCompany,
	useTrustHub,
	useAPIUpdateResult,
	useTrustHubLinkToCopy
} from '../../utils/helpers/hooks';
import classNames from 'classnames';
import { CommonResultStatusType, TrustHubStatus } from '../../utils/types';
import { IndustriesSelectOptions } from '../../utils/helpers/constants';
import CompanyProfileSkeleton from './CompanyProfileSkeleton';
import iconEmptyTrustHub from '../../assets/images/icon-empty-trust-hub.svg';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { useAppDispatch } from '../../services/store';
import { getFrameworksEvaluations, getResources, getTrustHub } from '../../services/store/slices/trust-hub.slice';
import { truncateString } from '../../utils/helpers/common';
import TrustHubSkeleton from './TrustHubSkeleton';
import ModalShareTrustHub from '../../components/partials/modals/modal-share-trust-hub/ModalShareTrustHub';
import PendingAccessRequests from '../../components/partials/tables/pending-access-requests/PendingAccessRequests';
import { Tooltip } from 'react-tooltip';

const CompanyProfile: FC = () => {
	const dispatch = useAppDispatch();
	const [openEditCompany, setOpenEditCompany] = useState(false);
	const [openEditTrustHub, setOpenEditTrustHub] = useState(false);
	const [companyUpdateResult, setCompanyUpdateResult] = useState<CommonResultStatusType>('');
	const { info, loading } = useCompany();
	const { item: trustHub, loading: trustHubLoading } = useTrustHub();
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const { linkToCopy } = useTrustHubLinkToCopy(window.location.origin, info?.slug);
	const { result, onNewResultSuccess, onNewResultError, onResultClear } = useAPIUpdateResult();

	useEffect(() => {
		handleWithTryCatch(async () => {
			await dispatch(getFrameworksEvaluations());
			await dispatch(getResources());
			await dispatch(getTrustHub(true));
		});
	}, [dispatch]);

	const onUpdateSuccess = () => {
		setCompanyUpdateResult('success');
		setOpenEditCompany(false);
	};

	const onUpdateError = () => {
		setCompanyUpdateResult('error');
	};

	const onShareTrustHub = useCallback(() => {
		setShareModalOpen(true);
	}, [setShareModalOpen]);

	const canBeViewedOrShared = useMemo(() => trustHub?.status === TrustHubStatus.published && trustHub?.id, [trustHub]);

	const companyInfo =
		!info || loading ? (
			<CompanyProfileSkeleton />
		) : (
			<>
				<Button
					small
					className={styles.edit}
					width={56}
					negative
					onClick={() => setOpenEditCompany(true)}
				>
					Edit
				</Button>

				<div className={classNames(styles.logo, !info?.logoUrl ? styles.stub : '')}>
					{info?.logoUrl ? <img src={info?.logoUrl} alt="logo" /> : null}
				</div>

				<DataList>
					<DataListItem label="Name" text={info?.name} />
					<DataListItem label="Location" text={info?.locationName} />
					<DataListItem
						label="Industry"
						text={
							IndustriesSelectOptions.find(
								(option) => option.value === info?.industry,
							)?.label || ''
						}
					/>
				</DataList>
			</>
		);

	const editCompanySection = (
		<>
			<EditCompany
				open={openEditCompany}
				onUpdateSuccess={onUpdateSuccess}
				onUpdateError={onUpdateError}
				closeHandler={() => setOpenEditCompany(false)}
			/>

			<Alert
				uniqueKey={'company-success'}
				show={companyUpdateResult === 'success'}
				type="success"
				message="Company profile updated!"
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>
			<Alert
				uniqueKey={'company-error'}
				show={companyUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating company profile"
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>

			<Alert
				uniqueKey={'trust-hub-results'}
				show={!!result.status}
				type={result.status}
				message={result.message}
				clearActionStatus={() => onResultClear()}
			/>
		</>
	);

	const detailsInfo = (
		<>
			{
				trustHubLoading ? (
					<TrustHubSkeleton />
				) : (
					<>

						<div className="no-desc mt-8 mb-5 flex items-center flex-col">
							{trustHub?.description ? (
								<div className="block w-full">{truncateString(trustHub?.description, 200)}</div>
							) : (
								<>
									<img src={iconEmptyTrustHub} alt="Icon Empty Trust Hub" />
									<p className="text-blueberry-100 mt-3">
										{'No description to display yet.'}
									</p>
								</>
							)}
						</div>

						<DataList>
							<DataListItem
								label="Status"
								text={
									<ColoredTag
										className={`${trustHub?.status === TrustHubStatus.published ? '!bg-green-500 text-white' : '!bg-blueberry-50'}`}
										tagId="trust-hub-status"
										text={trustHub?.status === TrustHubStatus.published ? 'Published' : 'Not Published'}
									/>
								}
							/>
							<DataListItem
								label="Last update"
								text={
									trustHub?.updatedAt
										? new Date(trustHub?.updatedAt).toLocaleDateString()
										: '-'
								}
							/>
						</DataList>
					</>
				)}
		</>
	);

	const editTrustHubSection = (
		<>
			<EditTrustHub
				open={openEditTrustHub}
				closeHandler={() => setOpenEditTrustHub(false)}
				onUpdateError={onNewResultError}
				onUpdateSuccess={onNewResultSuccess}
			/>
		</>
	);

	useGetCompany();

	return (
		<>
			<h2>Company Profile</h2>

			<BasicPanel className={styles['basic-panel']}>
				<h3>Company Details</h3>

				{companyInfo}
			</BasicPanel>

			<h2 className="mt-10">Trust Hub</h2>
			<div className='flex gap-5'>
				<BasicPanel className={styles['basic-panel']}>
					<ActionsBlock className='!mt-0'>
						<h3>Details</h3>

						{!trustHubLoading && (
							<ActionsBlock className='!mt-0 gap-2'>
								<div
									data-tooltip-id='trust-hub-share-company'
									data-tooltip-content={!canBeViewedOrShared ? 'Publish your Trust Hub to be able to share it with others' : ''}
								>
									<Button
										small
										width={136}
										onClick={onShareTrustHub}
										negative
										disabled={!canBeViewedOrShared}
									>
										Share Now
									</Button>
								</div>
								<Button
									small
									width={56}
									negative
									onClick={() => setOpenEditTrustHub(true)}
								>
									Edit
								</Button>
							</ActionsBlock>
						)}
					</ActionsBlock>
					{detailsInfo}
				</BasicPanel>
				<BasicPanel className={styles['basic-panel']}>
					<PendingAccessRequests />
				</BasicPanel>
			</div>

			{editTrustHubSection}

			{info ? editCompanySection : null}

			<ModalShareTrustHub
				open={shareModalOpen}
				setOpen={setShareModalOpen}
				linkToCopy={linkToCopy}
				onCopySuccess={onUpdateSuccess}
				onShareError={onUpdateError}
				onShareSuccess={onUpdateSuccess}
			/>

			<Tooltip
				id={'trust-hub-share-company'}
			/>
		</>
	);
};

export default CompanyProfile;
