import React, { FC } from 'react';
import styles from './DataListItem.module.scss';
import { IDataListItem } from './IDataListItem';
import classNames from 'classnames';

export const DataListItem: FC<IDataListItem> = ({ className, label, labelWidth, text, element }) => {
	const renderContent = () => {
		if (text) return <div className={styles.text}>{text}</div>;

		if (element) return element;

		return null;
	};

	return (
		<div className={classNames(styles['data-list-item'], className)}>
			<div style={{ minWidth: labelWidth }} className={styles.label}>
				{label}
			</div>

			{renderContent()}
		</div>
	);
};
