import React, { FC } from 'react';
import styles from './Home.module.scss';
import { LoadingSkeleton } from '../../components';
import classNames from 'classnames';

const HomeSkeleton: FC = () => {
	return (
		<div className={styles['home-skeleton']}>
			<LoadingSkeleton
				containerClassName={styles.skeleton}
				className={styles['skeleton-item']}
				height={250}
			/>

			<LoadingSkeleton
				containerClassName={styles.skeleton}
				className={styles['skeleton-item']}
				height={250}
			/>

			<LoadingSkeleton
				containerClassName={styles.skeleton}
				className={styles['skeleton-item']}
			/>

			<LoadingSkeleton
				containerClassName={classNames(styles.skeleton, styles.updates)}
				className={styles['skeleton-item']}
				height={400}
			/>

			<LoadingSkeleton
				containerClassName={styles.skeleton}
				className={styles['skeleton-item']}
			/>
		</div>
	);
};

export default HomeSkeleton;
