import { useParams } from 'react-router-dom';
import { Alert, Select, Tabs } from '../../components';
import { useEffect, useMemo, useState } from 'react';
import iconNavigationLight from '../../assets/images/icon-navigation-light.svg';
import iconEnvelope from '../../assets/images/icon-envelope.svg';
import iconGRCLogo from '../../assets/images/logo-gravity-grc-for-light-bg.svg';
import ModalTrustHubRequestAccess from '../../components/partials/modals/modal-trust-hub-request-access/ModalTrustHubRequestAccess';
import {
	useAPIUpdateResult,
	useCompany,
	useTrustHub,
	useControls
} from '../../utils/helpers/hooks';
import { useAppDispatch } from '../../services/store';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { getPublicTrustHub, ITrustHubResource } from '../../services/store/slices/trust-hub.slice';
import { getPublicCompanyInfo } from '../../services/store/slices/company.slice';
import { getPublicControls, IControl } from '../../services/store/slices/controls.slice';
import ModalRequestIsSent from '../../components/partials/modals/modal-request-is-sent/ModalRequestIsSent';
import { TrustHubNotFound } from './TrustHubNotFound';
import { TrustHubTextPreloader } from './TrustHubTextPreloader';
import { OverviewControlsBoxFull } from './OverviewControlsBoxFull';
import { OverviewControlsBox } from './OverviewControlsBox';
import { OverviewResources } from './OverviewResources';
import ModalResourceTokenExpired from '../../components/partials/modals/modal-resource-token-expired/ModalResourceTokenExpired';

export const TrustHub = () => {
	const dispatch = useAppDispatch();
	const { companySlug } = useParams<{ companySlug: string }>();
	const [loading, setLoading] = useState(false);
	const { info } = useCompany();
	const { item: trustHub } = useTrustHub();
	const { items: controlItems } = useControls();
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const { result, onNewResultSuccess, onNewResultError, onResultClear } = useAPIUpdateResult();
	const [customActiveTab, setCustomActiveTab] = useState('');
	const [selectedFramework, setSelectedFramework] = useState<string | undefined>(trustHub?.frameworks?.[0]?.id!.toString());
	const [frameworksLoading, setFrameworksLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);
	const [tokenExpiredModalOpen, setTokenExpiredModalOpen] = useState(false);
	const [tokenExpiredResource, setTokenExpiredResource] = useState<ITrustHubResource | null>(null);

	const groupedControlItems = useMemo(() => {
		return controlItems?.reduce((acc, item) => {
			if (!item.enhancedFamilyName) {
				return acc;
			}

			if (item.enhancedFamilyName && !acc[item.enhancedFamilyName]) {
				acc[item.enhancedFamilyName] = {
					enhancedFamilyName: item.enhancedFamilyName,
					items: [],
				};
			}

			if (acc[item.enhancedFamilyName!]) {
				acc[item.enhancedFamilyName].items.push(item);
			}
			return acc;
		}, {} as { [key: string]: { enhancedFamilyName: string; items: IControl[] } });
	}, [controlItems]);

	useEffect(() => {
		(async () => {
			if (!companySlug) return;
			setLoading(true);
			await handleWithTryCatch(async () => {
				await dispatch(getPublicTrustHub(companySlug));
				await dispatch(getPublicCompanyInfo(companySlug))
				await dispatch(getPublicControls(companySlug))
			}, () => setNotFound(true));

			setLoading(false);
		})();
	}, [dispatch, companySlug, setLoading]);

	useEffect(() => {
		(async () => {
			if (!companySlug) return;
			await handleWithTryCatch(async () => {
				setFrameworksLoading(true);
				await dispatch(getPublicControls(companySlug, selectedFramework));
				setFrameworksLoading(false);
			});
		})();
	}, [companySlug, dispatch, selectedFramework]);

	const OverviewControlsBoxFullWrap = useMemo(() => {
		return (<OverviewControlsBoxFull frameworksLoading={frameworksLoading} groupedControlItems={groupedControlItems} />)
	}, [frameworksLoading, groupedControlItems]);


	const OverviewResourcesWrap = useMemo(() => {
		return (
			<OverviewResources
				showSeeAll={false}
				trustHub={trustHub}
				setShareModalOpen={setShareModalOpen}
				setCustomActiveTab={setCustomActiveTab}
				setTokenExpiredResource={setTokenExpiredResource}
				setTokenExpiredModalOpen={setTokenExpiredModalOpen}
			/>
		);
	}, [trustHub]);

	const OverviewControls = useMemo(() => {
		return (
			<div className="flex flex-col gap-10">
				<OverviewControlsBox
					frameworksLoading={frameworksLoading}
					groupedControlItems={groupedControlItems}
					setCustomActiveTab={setCustomActiveTab}
				/>
				<OverviewResources
					showSeeAll={true}
					trustHub={trustHub}
					setShareModalOpen={setShareModalOpen}
					setCustomActiveTab={setCustomActiveTab}
					setTokenExpiredResource={setTokenExpiredResource}
					setTokenExpiredModalOpen={setTokenExpiredModalOpen}
				/>
			</div>
		);
	}, [frameworksLoading, groupedControlItems, trustHub]);

	const alerts = (
		<>
			<Alert
				uniqueKey={'trust-hub-results'}
				show={!!result.status}
				type={result.status}
				message={result.message}
				clearActionStatus={() => onResultClear()}
			/>
		</>
	);

	const modals = useMemo(() => {
		return (
			<>
				<ModalTrustHubRequestAccess
					trustHub={trustHub}
					open={shareModalOpen}
					companySlug={companySlug!}
					setOpen={setShareModalOpen}
					setRequestModalOpen={setRequestSentModalOpen}
					onShareError={onNewResultError}
					onShareSuccess={onNewResultSuccess}
					setCompanyName={setCompanyName}
				/>
				<ModalRequestIsSent
					open={requestSentModalOpen}
					setOpen={setRequestSentModalOpen}
					companyName={companyName}
				/>
				<ModalResourceTokenExpired
					open={tokenExpiredModalOpen}
					setOpen={setTokenExpiredModalOpen}
					fileName={tokenExpiredResource?.name || ''}
				/>
			</>
		);
	}, [trustHub, shareModalOpen, companySlug, onNewResultError, onNewResultSuccess, requestSentModalOpen, companyName, tokenExpiredModalOpen, tokenExpiredResource?.name]);

	if (loading) return <TrustHubTextPreloader />;


	if (notFound) {
		return <TrustHubNotFound logo={info?.logoUrl} />;
	}

	return (
		<>
			<div className="min-h-screen w-[1200px] flex flex-col lg:flex-row gap-10 max-w-[100vw] lg:py-[60px] py-3 px-5 relative">
				<aside className="w-full lg:w-[300px] bg-blueberry-400 text-white p-6 gap-4 rounded-2xl self-start flex flex-col flex justify-between text-white min-h-[612px] relative lg:sticky top-5">
					<div>
						{!!info?.logoUrl && (
							<div className="logo pb-10">
								<img src={info?.logoUrl} alt="Company Logo" className='w-full' />
							</div>
						)}
						<div>
							<h1 className="text-white pb-3">{info?.name}</h1>
							<p>
								{trustHub?.description || ''}
							</p>
						</div>
					</div>

					<div className="">
						<ul className="mb-10 text-sm">
							<li className="mb-2">
								<a
									href={`mailto:${trustHub?.email}`}
									target='_blank'
									className="flex gap-2 items-center text-sm" rel="noreferrer"
								>
									<img
										className="w-[20px] h-[20px]"
										src={iconEnvelope}
										alt="Envelope icon"
									/>
									{trustHub?.email || ''}
								</a>
							</li>
							{trustHub?.privacyPolicy && (
								<li>
									{/* @ts-ignore */}
									<a href={trustHub?.privacyPolicy.path} target='_blank' rel='noreferrer' className="flex gap-2 items-center text-sm">
										<img
											className="w-[20px] h-[20px]"
											src={iconNavigationLight}
											alt="Link Icon"
										/>
										Privacy Policy
									</a>
								</li>
							)}
						</ul>

						<button
							className="py-2 px-4 bg-levander-300 rounded-[10px]"
							onClick={() => {
								setShareModalOpen(true)
							}}
						>
							Request access
						</button>
					</div>
				</aside>

				<main className="flex-1">
					<div className="flex justify-between">
						<Tabs
							customActiveTab={customActiveTab}
							tabs={[
								{
									key: 'overview',
									text: 'Overview',
									component: OverviewControls,
								},
								{
									key: 'controls',
									text: 'Controls',
									component: OverviewControlsBoxFullWrap,
								},
								{
									key: 'resources',
									text: 'Resources',
									component: OverviewResourcesWrap,
								},
							]}
							tabSideComponent={
								trustHub?.frameworks?.length !== 1 ? <Select
									options={
										trustHub?.frameworks?.map((framework) => ({
											value: framework.id!.toString(),
											label: framework.name!,
										})) || []}
									className="w-[200px]"
									disabled={frameworksLoading}
									onValueChange={(value) => {
										setSelectedFramework(value);
									}}
									defaultValue={trustHub?.frameworks?.[0]?.id!.toString()}
								/> : <></>
							}
						/>
					</div>
				</main>
			</div>

			<div className='flex justify-center lg:ml-[300px] ml-0 mb-10'>
				<div className="flex justify-center text-sm gap-4 mt-8 font-semibold">
					Powered by
					<img src={iconGRCLogo} alt="GRC Logo" />
				</div>
			</div>

			{alerts}
			{modals}
		</>
	);
};
