import { FC, forwardRef, useCallback, useMemo, useEffect, useRef, ChangeEvent } from 'react';
import { IMultiValueInput } from './IMultiValueInput';
import styles from './MultiValueInput.module.scss';
import { Error } from '../..';
import classNames from 'classnames';
import { EMAIL_PATTERN } from '../../../../utils/helpers/common/form';

export const MultiValueInput: FC<IMultiValueInput> = forwardRef(
	(
		{
			id,
			name,
			type,
			placeholder,
			defaultValue,
			currentValue,
			value,
			values,
			error,
			errorClassName,
			disabled,
			withErrorStyle,
			className,
			label,
			onChange,
			onSetValues,
			negative,
			isValid,
			touchedFields,
			...rest
		},
		ref,
	) => {
		const wrapperRef = useRef<HTMLDivElement | null>(null);
		const parsedValues = useMemo(() => {
			if (typeof values === 'string' && values.length) {
				return values.split(",") as string[];
			}
			return (values || "").split(",").filter(v => v) as string[];
		}, [values]);


		const emailValid = useCallback((value: string) => {
			if (!value) return;

			const emailVerified = EMAIL_PATTERN.test(value as string);

			if (!emailVerified) {
				return 'Please enter valid email';
			}

			return "";
		}, []);

		const findParsedValueIndex = useCallback((value: string, parsedValues: string[]) => parsedValues.findIndex(v => v === value), []);

		const sanitizedValues = useCallback((parsedValues: string[], value?: string) => {
			if (value) {
				const valueIndex = findParsedValueIndex(value, parsedValues);
				if (valueIndex === -1) {
					return [...parsedValues, value].join(',');
				}
			}
			return parsedValues.join(',');
		}, [findParsedValueIndex]);

		const onRemoveValue = useCallback((value: string) => () => {
			if (onSetValues) {
				const valueIndex = findParsedValueIndex(value, parsedValues);
				if (valueIndex !== -1) {
					parsedValues.splice(valueIndex, 1);
					onSetValues(sanitizedValues([...parsedValues]));
					return;
				}
				onSetValues(sanitizedValues(parsedValues, value));
			}
		}, [parsedValues, onSetValues, sanitizedValues, findParsedValueIndex]);

		useEffect(() => {
			if (!wrapperRef.current) return;

			const input = wrapperRef.current.querySelector('input');

			if (!input) return;

			const onEnterPress = (e: Event) => {
				// @ts-ignore
				if (e.key === 'Enter') {
					e.preventDefault();
					e.stopImmediatePropagation();
					if (onSetValues) {
						const target = e.target as HTMLInputElement;
						const value = target.value;
						if (emailValid(value)) {
							return;
						}

						onSetValues(sanitizedValues(parsedValues, value));
						target.value = '';
						if (onChange) onChange(e as unknown as ChangeEvent<HTMLInputElement>);
					}
					return;
				}
			};

			input?.addEventListener('keydown', onEnterPress);
			return () => input?.removeEventListener('keydown', onEnterPress);
		}, [onSetValues, ref, parsedValues, sanitizedValues, onChange, isValid, emailValid]);

		const renderInput = useMemo(() => {
			return (
				<div>
					<input
						className={styles.input}
						onChange={onChange}
						{...{
							ref,
							type,
							name,
							id,
							placeholder,
							value,
							defaultValue,
							disabled,
							...rest,
						}}
					/>
					<p className='text-xs text-gray-400 mt-1'>
						Type an email and press 'Enter' to add it to the list.
					</p>

					{error || emailValid(currentValue as string) ? (
						<Error disabled={disabled} message={error || emailValid(currentValue as string)} className={errorClassName} />
					) : null}

					{parsedValues?.length > 0 && (
						<ul className='flex flex-wrap gap-2 mt-2'>
							{parsedValues.map((value) => (
								<li key={value} className='p-2 flex gap-2 border-[1px] border-solid border-levander-50 rounded-lg'>
									<div className={styles.clear} onClick={onRemoveValue(value)}></div>
									{value}
								</li>
							))}
						</ul>
					)}
				</div>
			);
		}, [currentValue, defaultValue, disabled, emailValid, error, errorClassName, id, name, onChange, onRemoveValue, parsedValues, placeholder, ref, rest, type, value]);

		return (
			<div
				className={classNames(
					styles['multi-value-input'],
					className,
					negative ? styles.negative : '',
					withErrorStyle ? styles.error : '',
					disabled ? styles.disabled : '',
				)}
				ref={wrapperRef}
			>
				{label ? (
					<label className={styles.label} htmlFor={id}>
						{label}
					</label>
				) : (
					''
				)}

				{renderInput}
			</div>
		);
	},
);
