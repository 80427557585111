import React, { FC } from 'react';
import classNames from 'classnames';

import { ISpinner } from './ISpinner';

import styles from './Spinner.module.scss';

export const Spinner: FC<ISpinner> = ({ thin, style, width, height, ...rest }) => {
	const inlineStyles = {
		...style,
		width,
		height,
	};

	return (
		<div
			style={inlineStyles}
			className={classNames(styles.spinner, thin ? styles.thin : '')}
			{...rest}
		></div>
	);
};
