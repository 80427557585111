import { CommonColors } from '../../../../utils/helpers/constants';
import { ColoredTag } from '../../../primitives';
import { ITableColumn } from '../../../primitives/table/ITable';

export const getTrustHubFrameworksColumns = (primaryFrameworkId?: string) => {
	return [
		{
			header: 'Framework Name',
			key: 'name',
			sorting: true,
			width: '100%',
			columnRender: (value: string, rowData) => {
				return <div className='flex justify-between	items-center w-full'>
					<span>{value}</span>
					{rowData.id === primaryFrameworkId && (
						<ColoredTag
							tagId="primary"
							bgColor={CommonColors.primaryFramework}
							text="Primary"
						/>
					)}
				</div>
			},
		}
	] as ITableColumn[];
};
