import React, { FC, MouseEvent } from 'react';

import styles from './AddNewTag.module.scss';
import { IAddNewTag } from './IAddNewTag';
import { CommonColors } from '../../../utils/helpers/constants';
import classNames from 'classnames';

export const AddNewTag: FC<IAddNewTag> = ({
	text,
	onAddClickHandler,
	disabled,
	color = CommonColors.addNewTagBg,
}) => {
	const onTagClick = (e: MouseEvent<HTMLDivElement>) => {
		if (disabled) return;
		e.stopPropagation();
		onAddClickHandler?.(e);
	};

	return (
		<div
			className={classNames(styles['add-tag'], disabled ? styles.disabled : '')}
			style={{ backgroundColor: color }}
			onClick={onTagClick}
		>
			{text || 'Add New'}
		</div>
	);
};
