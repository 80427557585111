import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ModalAddTrustHubFrameworks.module.scss';
import { Loader, Modal } from '../../../primitives';
import IModalAddTrustHubFrameworks from './IModalAddTrustHubFrameworks';
import { ActionsBlock, BasicPanel, Button } from '../../../primitives';
import { sortBaseControlObjByIdAsc } from '../../../../utils/helpers/common';
import { IIdName } from '../../../../utils/types';
import { useCompany, useFrameworks } from '../../../../utils/helpers/hooks';
import AddTrustHubFrameworkFrameworkTable from '../../tables/add-trust-hub-frameworks-table/AddTrustHubFrameworkFrameworkTable';
import { IFramework } from '../../../../services/store/slices/frameworks.slice';
import { getFrameworksEvaluations, runFrameworksEvaluationForCompany } from '../../../../services/store/slices/trust-hub.slice';
import { useAppDispatch } from '../../../../services/store';

const ModalAddTrustHubFrameworks: FC<IModalAddTrustHubFrameworks> = ({
	predefinedItems,
	allPreselected = false,
	open,
	cancelBtnText = 'Cancel',
	setOpen,
	onSelectedAdd,
	onCancel,
}) => {
	const dispatch = useAppDispatch();
	const { items: frameworks } = useFrameworks();
	const { info } = useCompany();
	const [evaluationLoading, setEvaluationLoading] = useState(false);

	const itemsToDisplay = useMemo(() => {
		return frameworks?.filter((f) => f.isActive) || [];
	}, [frameworks]);

	const [selectedFrameworks, setSelectedFrameworks] = useState<string[]>((predefinedItems?.map((c) => c.id?.toString()) || []) as string[]);
	const submitIsDisabled = !selectedFrameworks.length || evaluationLoading;

	useEffect(() => {
		setSelectedFrameworks((predefinedItems?.map((c) => c.id?.toString()) || []) as string[]);
	}, [predefinedItems]);

	const onAdd = useCallback(async () => {
		setEvaluationLoading(true);
		await dispatch(runFrameworksEvaluationForCompany(parseInt(info!.id!)));
		await dispatch(getFrameworksEvaluations());
		const selectedObj = selectedFrameworks.map((id) => {
			return frameworks?.find((c) => c.id! === id.toString()) as IFramework;
		});

		onSelectedAdd(selectedObj);
		setSelectedFrameworks([]);
		setOpen(false);
		setEvaluationLoading(false);
	}, [dispatch, info, selectedFrameworks, onSelectedAdd, setOpen, frameworks, setEvaluationLoading]);

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button
					width={136}
					type="button"
					negative
					onClick={() => {
						if (onCancel) onCancel();
						setOpen(false);
					}}
				>
					{cancelBtnText}
				</Button>

				<Button width={136} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					{evaluationLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Add Selected'
					)}
				</Button>
			</ActionsBlock>
		);
	};

	useEffect(() => {
		if (allPreselected && itemsToDisplay?.length) {
			setSelectedFrameworks(itemsToDisplay.map((control) => control.id) as string[]);
		}
	}, [allPreselected, itemsToDisplay]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Frameworks</h2>

					<div className={styles['table-wrap']}>
						<AddTrustHubFrameworkFrameworkTable
							data={sortBaseControlObjByIdAsc(
								itemsToDisplay?.map(
									(control) =>
										({
											id: control.id,
											name: control.name,
										}) as IIdName,
								) || [],
							)}
							selectedRows={selectedFrameworks}
							setSelectedRows={setSelectedFrameworks}
							primaryFrameworkId={info?.primaryFrameworkId}
						/>
					</div>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddTrustHubFrameworks;
