import { TrustHubAccessRequestType, TrustHubAccessRequestTypeDisplayName } from '../../../../utils/helpers/constants';
import { ColoredTag } from '../../../primitives';
import { ITableColumn } from '../../../primitives/table/ITable';

export const getPendingAccessRequestsColumns = () => {
	return [
		{
			header: 'Company name',
			key: 'name',
			width: '33%',
			sorting: true,
			columnRender: (name: string) => <div>{name}</div>,
		},
		{
			header: 'Request Type',
			key: 'requestType',
			width: '43%',
			sorting: true,
			columnRender: (requestType: TrustHubAccessRequestType) => <b>{TrustHubAccessRequestTypeDisplayName[requestType]}</b>,
		},

		{
			header: 'Request Date',
			key: 'requestAt',
			width: '27%',
			sorting: true,
			columnRender: (value: Date | string) => {
				const date = new Date(value);
				const dateStr = date.toLocaleDateString();
				return <ColoredTag tagId={dateStr} text={dateStr} withoutBackground />
			},
		},
	] as ITableColumn[];
};
