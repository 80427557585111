import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, IKeyValuePair } from '../../../../../utils/types';
import { processTableData } from '../../../../../utils/helpers/common';
import {
	BasicPanel,
	CriticalityFilter,
	Drawer,
	DispositionFilter,
	SearchFilter,
	SensitivityFilter,
	Table,
	TableFilters,
	TableSkeleton,
} from '../../../../primitives';
import styles from './ArchivedAssetsTable.module.scss';
import { getAssetColumns } from '../utils';
import { usePagination } from '../../../../../utils/helpers/hooks';
import classNames from 'classnames';
import {
	AppQueryParams,
	AssetCriticalityOptions,
	AssetDispositionOptions,
	AssetSensitivityOptions,
} from '../../../../../utils/helpers/constants';
import IPartialTable from '../../common/IPartialTable';
import { IAsset } from '../../../../../services/store/slices/assets.slice';
import AssetDetails from '../../../edit-panels/asset/details/AssetDetails';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../../utils/helpers/constants/app-routes';

const ArchivedAssetsTable: FC<IPartialTable<IAsset>> = ({ data, dataLoading, showItemId }) => {
	const navigate = useNavigate();
	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IAsset[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [isProcessing, setIsProcessing] = useState(true);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAsset) => true,
		dispositionFilter: (dataRecord: IAsset) => true,
		sensitivityFilter: (dataRecord: IAsset) => true,
		criticalityFilter: (dataRecord: IAsset) => true,
	});

	const tableColumnsConfig = useMemo(() => getAssetColumns(), []);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(assetId: string) => {
			navigate(`${AppUrls.assets}?${AppQueryParams.item}=${assetId}`);
		},
		[navigate],
	);

	const detailsDrawer = (
		<>
			<Drawer
				title="Asset Details"
				open={openDrawer}
				onCloseClickHandler={() => {
					setOpenDrawer(false);
					navigate(AppUrls.assets);
				}}
			>
				{showItemId ? <AssetDetails assetId={showItemId} /> : null}
			</Drawer>
		</>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					placeholder="Search asset..."
					setFilters={setCurrentFilters}
					properties={['name', 'description']}
				/>
				<DispositionFilter
					options={AssetDispositionOptions}
					setFilters={setCurrentFilters}
				/>
				<CriticalityFilter
					options={AssetCriticalityOptions}
					setFilters={setCurrentFilters}
				/>
				<SensitivityFilter
					options={AssetSensitivityOptions}
					setFilters={setCurrentFilters}
				/>
			</Fragment>
		);

		return (
			<TableFilters
				disabled={dataLoading || (!dataLoading && !data.length)}
				leftSection={leftSection}
			/>
		);
	}, [data.length, dataLoading]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="assets"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No assets to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data.length,
			dataLoading,
			onRowClickHandler,
			onSortChange,
			tableColumnsConfig,
		],
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IAsset[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={8} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{detailsDrawer}
		</>
	);
};

export default ArchivedAssetsTable;
