import React, { FC, useEffect, useMemo, useRef } from 'react';
import styles from './PasswordInstructions.module.scss';
import { IPasswordInstructions } from './IPasswordInstructions';
import classNames from 'classnames';
import { checkForRegex, generatePasswordTextInstructions } from '../../../utils/helpers/common';
import { useAuthorization } from '../../../utils/helpers/hooks';
import { IKeyValuePair } from '../../../utils/types';

export const PasswordInstructions: FC<IPasswordInstructions> = React.memo(
	({ className, onValidationHandler, passwordInput = '' }) => {
		const passedValidation = useRef<IKeyValuePair | null>(null);
		const { passwordRequirements } = useAuthorization();
		const textInstructions = useMemo(
			() => generatePasswordTextInstructions(passwordRequirements),
			[passwordRequirements],
		);

		const checkValidation = (key: string, rgx: string) => {
			if (passwordInput === '') return '';

			if (checkForRegex(rgx, passwordInput)) {
				passedValidation.current = {
					...passedValidation.current,
					[key]: true,
				};

				return styles.valid;
			}

			return styles.invalid;
		};

		const renderInstructions = () => {
			if (!passwordRequirements || !textInstructions) return null;

			return textInstructions.map((instruction) => {
				const { key, rgx, text } = instruction;
				const validityClassName = checkValidation(key, rgx);

				return (
					<div key={text} className={classNames(styles.item, validityClassName)}>
						{text}
					</div>
				);
			});
		};

		useEffect(() => {
			if (
				passwordInput &&
				passedValidation.current &&
				Object.keys(passedValidation.current).length === textInstructions?.length
			) {
				const allPassed = Object.values(passedValidation).every((val) => val);

				if (onValidationHandler && allPassed) onValidationHandler(allPassed);
			}
		}, [onValidationHandler, passedValidation, passwordInput, textInstructions?.length]);

		return (
			<div className={classNames(styles.instructions, className)}>
				<p>Your password has to include:</p>

				<div className={styles.items}>{renderInstructions()}</div>
			</div>
		);
	},
);
