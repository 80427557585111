import { FC, useEffect, useState } from 'react';
import styles from './DetailsStep.module.scss';
import { ActionsBlock, Button, TagColorPicker, ColoredTag, Input } from '../../../../../primitives';
import { IModalCreateTagStep } from '../../IModalCreateTag';

const DetailsStep: FC<IModalCreateTagStep> = ({
	setOpen,
	currentFormState,
	setCurrentFormState,
	setNextStep,
}) => {
	const [nextIsDisabled, setNextIsDisabled] = useState(true);
	const [nameLengthError, setNameLengthError] = useState(false);

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={96} negative onClick={() => setOpen(false)}>
				Cancel
			</Button>

			<Button type="button" width={66} disabled={nextIsDisabled} onClick={setNextStep}>
				Next
			</Button>
		</ActionsBlock>
	);

	useEffect(() => {
		setNextIsDisabled(!currentFormState.name || !currentFormState.color || nameLengthError);
	}, [currentFormState, nameLengthError]);

	const createForm = (
		<div className={styles['create-tag-details']}>
			<form>
				<div className={styles.inputs}>
					<Input
						className={styles.input}
						type="text"
						label="Tag Name"
						value={currentFormState?.name || ''}
						onValueChange={(value) => {
							if (value.length > 15) setNameLengthError(true);
							else setNameLengthError(false);
							setCurrentFormState((prev) => ({ ...prev, name: value }));
						}}
						withErrorStyle={nameLengthError}
						error={nameLengthError ? 'Max length is 15 symbols' : ''}
					/>

					<div className={styles.section}>
						<h5 className={styles.title}>Tag Color</h5>

						<TagColorPicker
							selectedColor={currentFormState?.color}
							onColorSelect={(value) =>
								setCurrentFormState((prev) => ({ ...prev, color: value }))
							}
						/>
					</div>

					<div className={styles.section}>
						<h5 className={styles.title}>Preview</h5>

						<ColoredTag
							tagId={currentFormState?.name || ''}
							text={currentFormState?.name || 'Preview'}
							bgColor={currentFormState?.color || ''}
							withoutBackground
						/>
					</div>
				</div>

				{formActions}
			</form>
		</div>
	);

	return createForm;
};

export default DetailsStep;
