import { FC, useState } from 'react';
import styles from './ModalDeleteTrustHubResource.module.scss';
import { Modal, BasicPanel, Loader } from '../../../primitives';
import { ActionsBlock, Button } from '../../../primitives';
import IModalDeleteTrustHubResource from './IModalDeleteTrustHubResource';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { getTrustHub, massDeleteResourceFiles } from '../../../../services/store/slices/trust-hub.slice';

const ModalDeleteTrustHubResource: FC<IModalDeleteTrustHubResource> = ({
	open,
	names,
	selectedResources,
	setOpen,
	onDeleteError,
	onDeleteSuccess,
	setSelectedResources,
}) => {
	const dispatch = useAppDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const onDelete = () => {
		handleWithTryCatch(
			async () => {
				if (selectedResources?.length) {
					setDeleteLoading(true);
					await dispatch(massDeleteResourceFiles(selectedResources));
					await dispatch(getTrustHub(true));
					onDeleteSuccess("Resource deleted successfully");
					setDeleteLoading(false);
					setSelectedResources([]);
					setOpen(false);
				}
			},
			undefined,
			() => onDeleteError("Error deleting resource"),
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Resources?</h2>

				<p className={styles.text}>
					Are you sure you want to delete these items from resources?
				</p>

				<ul className='text-sm'>
					{names?.map((name, index) => (
						<li key={index}>{name}</li>
					))}
				</ul>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						disabled={deleteLoading}
						onClick={onDelete}
					>
						{deleteLoading ? (
							<Loader
								color={'#fcedf0'}
								transparentColor="transparent"
								thin
								maxHeight={14}
								maxWidth={14}
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteTrustHubResource;
