import { OverviewControlItem } from "./OverviewControlItem";
import iconNavigationAccent from '../../assets/images/icon-navigation-accent.svg';
import { IControl } from "../../services/store/slices/controls.slice";
import { Link, useLocation } from "react-router-dom";
import slugify from "slugify";
import { useMemo } from "react";


export interface OverviewControlGroupProps {
  title: string;
  items: IControl[];
  setCustomActiveTab: (tab: string) => void;
}

export const OverviewControlGroup = ({
  title,
  items,
  setCustomActiveTab,
}: OverviewControlGroupProps) => {
  const location = useLocation();

  const seeAll = useMemo(() => items?.length > 3, [items]); 

  return (
    <div className="rounded-2xl">
      <div className="flex justify-between pb-2 border-b-[1px] border-blueberry-50 mb-4 gap-2">
        <h5 className="font-semibold text-blueberry-200 text-xs font-semibold">
          {title}
        </h5>
        {seeAll && (
          <Link
            to={`#${slugify(title.toLowerCase())}`}
            className="font-semibold text-levander-300 text-xs flex gap-2 items-center whitespace-nowrap"
            onClick={() => {
              setCustomActiveTab('controls');
              setTimeout(() => {
                setCustomActiveTab('');
              }, 100);

              setTimeout(() => {
                const hash = location.hash;
                if (hash && document.querySelector(hash)) {
                  document.querySelector(hash)?.scrollIntoView();
                }
              }, 350);
            }}
          >
            See {items?.length - 3} more
            <img
              className="w-[12px] h-[12px]"
              src={iconNavigationAccent}
              alt="Navigation icon"
            />
          </Link>
        )}
      </div>
      <ul>
        {items?.slice(0, 3)?.map((item) => (
          <OverviewControlItem key={item.id} control={item.trustHubDefaultStatement!} />
        ))}
      </ul>
    </div>
  )
};
