import { FC } from 'react';
import { LoadingSkeleton, HistorySkeleton } from '../../../primitives';

export const EditTrustHubSkeleton: FC = () => {
	const editForm = (
		<div className="flex min-h-screen flex-col">
			<div className="min-h-screen">
				<LoadingSkeleton height={24} />

				<LoadingSkeleton height={54} />
				<LoadingSkeleton height={54} />
				<LoadingSkeleton height={54} />

				<HistorySkeleton />
				<HistorySkeleton />
				<HistorySkeleton />
				<HistorySkeleton />
			</div>
		</div>
	);

	return <div>{editForm}</div>;
};
