import { FC } from 'react';
import styles from './EditAsset.module.scss';
import {
	LoadingSkeleton,
	CommentsSkeleton,
	TagsSkeleton,
	HistorySkeleton,
} from '../../../../primitives';

const EditAssetSkeleton: FC = () => {
	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<LoadingSkeleton containerClassName={styles.input} height={96} />

				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<div className={styles['input-group']}>
					<LoadingSkeleton height={54} />

					<LoadingSkeleton height={54} />
				</div>

				<TagsSkeleton />
				<CommentsSkeleton />
				<HistorySkeleton />
			</div>
		</div>
	);

	return <div className={styles['edit-asset-skeleton']}>{editForm}</div>;
};

export default EditAssetSkeleton;
