export const TrustHubToken = {
  key: 'trustHubToken',
  get: () => {
    return localStorage.getItem('trustHubToken');
  },
  set: (token: string) => {
    localStorage.setItem('trustHubToken', token);
  },
  remove: () => {
    localStorage.removeItem('trustHubToken');
  },
  parse: () => {
    const token = TrustHubToken.get();
    if (!token) return null;
    const parsedToken = JSON.parse(atob(token.split('.')[1]));

    if (!parsedToken) return null;

    if (parsedToken.exp * 1000 < Date.now()) {
      TrustHubToken.remove();
      return null;
    }

    return parsedToken;
  }
}