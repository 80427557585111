import { useEffect } from 'react';
import { useUser } from './useUser';
import { useAppDispatch } from '../../../services/store';
import { getCompanyInfo } from '../../../services/store/slices/company.slice';
import { handleWithTryCatch } from '../errors';

export function useGetCompany(skip: boolean = false) {
	const dispatch = useAppDispatch();
	const { authorized, info: userInfo } = useUser();

	useEffect(() => {
		if (skip) return;

		if (authorized && userInfo?.companyId) {
			handleWithTryCatch(() => {
				dispatch(getCompanyInfo(userInfo?.companyId));
			});
		}
	}, [authorized, dispatch, skip, userInfo?.companyId]);
}
