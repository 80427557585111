import { CommonResponseError } from './CommonResponseError';

export const handleWithTryCatch = async (
	action: () => any,
	errorMessageReceiver?: (message: string) => any,
	catchAction?: (message?: string) => any,
) => {
	try {
		await action();
	} catch (error: unknown) {
		const knownError = error as CommonResponseError;

		if (errorMessageReceiver) errorMessageReceiver(knownError.message);
		if (catchAction) catchAction(knownError.message);

		if (!catchAction && !errorMessageReceiver) {
			console.error(knownError);
		}
	}
};
