import { FC } from 'react';
import styles from './ArchivedPolicyDetails.module.scss';

import { LoadingSkeleton, TagsSkeleton } from '../../../../primitives';

const ArchivedPolicyDetailsSkeleton: FC = () => {
	const editForm = (
		<>
			<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />

			<TagsSkeleton />
		</>
	);

	return <div className={styles['policy-details-skeleton']}>{editForm}</div>;
};

export default ArchivedPolicyDetailsSkeleton;
