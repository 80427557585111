import {
	controlsColumnRenderHelper,
	tagsColumnRenderHelper,
} from '../../../../../utils/helpers/common';
import { ColoredTag, ITableColumn } from '../../../../primitives';
import styles from './CompanyPoliciesTable.module.scss';
import { DeviceType } from '../../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { IPolicy } from '../../../../../services/store/slices/policies.slice';
import warningIcon from '../../../../../assets/images/icon-exclamation-in-circle-dark.svg';
import { CommonColors, CommonStatuses } from '../../../../../utils/helpers/constants';
import { ITag, ITagBrief } from '../../../../../services/store/slices/tags.slice';
import { IIdName } from '../../../../../utils/types';
import { ICompanyUser } from '../../../../../services/store/slices/company-users.slice';

export const getCompanyPolicyColumns = (device?: DeviceType | null, appTags?: ITag[] | null) => {
	return [
		{
			header: 'Policy Name',
			key: 'name',
			sorting: true,
			width: '20%',
			columnRender: (value: string, rowData: IPolicy) => {
				return (
					<div className={styles['name-wrap']}>
						<h6 className={styles.name}>{value}</h6>

						{rowData.status === CommonStatuses['pending-review'] ? (
							<ColoredTag
								tagId="alert"
								small
								iconOnLeft={warningIcon}
								key={'alert'}
								text={'Review'}
								bgColor={CommonColors.alertTag}
								className={styles['alert-tag']}
							/>
						) : null}
					</div>
				);
			},
		},
		{
			header: 'Controls',
			key: 'controls',
			width: '14%',
			columnRender: (controls: IIdName[]) =>
				controlsColumnRenderHelper(
					controls.map((c) => c.displayId || ''),
					device,
				),
		},
		{
			header: 'Framework',
			key: 'framework',
			sorting: true,
			width: '14.8%',
			columnRender: (framework: IIdName) => (framework ? framework.name : ''),
		},
		{
			header: 'Owner',
			key: 'owner',
			sorting: true,
			width: '14.8%',
			columnRender: (owner: ICompanyUser) =>
				owner && owner.fullName ? owner.fullName : 'Unassigned',
		},
		{
			header: 'Version',
			key: 'version',
			width: '7%',
		},
		{
			header: 'Tags',
			key: 'tags',
			width: '17.7%',
			columnRender: (tags: ITagBrief[]) => tagsColumnRenderHelper(tags, device, appTags),
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
