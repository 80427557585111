import { FC, useEffect, useMemo, useState } from 'react';
import styles from './DetailsStep.module.scss';
import {
	AssetCriticalityOptions,
	AssetDispositionOptions,
	AssetSensitivityOptions,
} from '../../../../../../utils/helpers/constants';
import { ActionsBlock, Button, Select, Textarea, Loader } from '../../../../../primitives';
import IDetailsStep from './IDetailsStep';
import { getOwnerOptions } from '../../../../../../utils/helpers/common';
import { handleWithTryCatch } from '../../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../../services/store';
import { createAsset } from '../../../../../../services/store/slices/assets.slice';
import { useAssets, useUser, useCompanyUsers } from '../../../../../../utils/helpers/hooks';
import { commonStringIsValid } from '../../../../../../utils/helpers/common/form';

const DetailsStep: FC<IDetailsStep> = ({
	setOpen,
	currentFormState,
	setCurrentFormState,
	onAddSuccess,
	onAddError,
}) => {
	const dispatch = useAppDispatch();
	const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
	const { info: userInfo } = useUser();
	const { items: companyUsers } = useCompanyUsers();
	const { assetLoading } = useAssets();

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id),
		[companyUsers, userInfo?.id],
	);

	const onAddClick = () => {
		handleWithTryCatch(
			async () => {
				const addedAssetId = await dispatch(
					createAsset(userInfo?.companyId!, currentFormState),
				);
				onAddSuccess(addedAssetId);
				setOpen(false);
			},
			undefined,
			onAddError,
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				type="button"
				width={74}
				disabled={submitIsDisabled || assetLoading}
				onClick={onAddClick}
			>
				{assetLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Finish'}
			</Button>
		</ActionsBlock>
	);

	useEffect(() => {
		setSubmitIsDisabled(
			!currentFormState.description ||
			!currentFormState.disposition ||
			!currentFormState.sensitivity ||
			!currentFormState.criticality ||
			!commonStringIsValid(currentFormState?.description),
		);
	}, [currentFormState]);

	const createForm = (
		<div className={styles['add-asset']}>
			<form>
				<div className={styles.inputs}>
					<Textarea
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, description: value }))
						}
						withErrorStyle={!commonStringIsValid(currentFormState?.description)}
						error={
							!commonStringIsValid(currentFormState?.description)
								? 'The input field allows alpha-numeric and special characters'
								: ''
						}
						defaultValue={currentFormState?.description}
						className={styles.description}
						placeholder="Enter description here"
						label="Asset Description"
					/>

					<div className={styles['input-group']}>
						<Select
							id="disposition"
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({ ...prev, disposition: value }))
							}
							defaultValue={currentFormState?.disposition}
							className={styles.input}
							label="Disposition"
							options={AssetDispositionOptions}
						/>

						<Select
							id="sensitivity"
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({ ...prev, sensitivity: value }))
							}
							defaultValue={currentFormState?.sensitivity}
							className={styles.input}
							label="Sensitivity"
							options={AssetSensitivityOptions}
						/>
					</div>
					<div className={styles['input-group']}>
						<Select
							id="criticality"
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({ ...prev, criticality: value }))
							}
							defaultValue={currentFormState?.criticality}
							className={styles.input}
							options={AssetCriticalityOptions}
							label="Criticality"
						/>

						<Select
							id="owner"
							onValueChange={(value) =>
								setCurrentFormState((prev) => ({ ...prev, ownerId: value }))
							}
							defaultValue={currentFormState?.ownerId || 'unassigned'}
							className={styles.input}
							label="Owner"
							options={ownersOptions || []}
						/>
					</div>
				</div>

				{formActions}
			</form>
		</div>
	);

	return createForm;
};

export default DetailsStep;
