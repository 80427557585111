import { useCallback, useState } from "react";
import { CommonResultStatusType } from "../../types";

export interface IUseAPIUpdateResult {
  status: CommonResultStatusType | '';
  message: string;
}

export function useAPIUpdateResult () {
  const [result, setResult] = useState<IUseAPIUpdateResult>({
    status: '',
    message: ''
  });

  const onNewResultSuccess = useCallback((newMessage: IUseAPIUpdateResult['message']) => {
		setResult({ status: 'success', message: newMessage });
	}, [setResult]);

  const onNewResultError = useCallback((newMessage: IUseAPIUpdateResult['message']) => {
    setResult({ status: 'error', message: newMessage });
  }, [setResult]);

  const onResultClear = useCallback(() => {
    setResult({ status: '', message: '' });
  }, [setResult]);

  return {
    result,
    onNewResultSuccess,
    onNewResultError,
    onResultClear
  }
}