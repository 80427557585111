import React, { FC, useEffect, useState } from 'react';
import styles from './Drawer.module.scss';
import { IDrawer } from './IDrawer';
import classNames from 'classnames';
import { useScrollableSetting } from '../../../utils/helpers/hooks';
import { AnimatePresence } from 'framer-motion';
import { AnimatedScreenWrapper } from '../../../utils/helpers/hocs/transition/AnimatedScreenWrapper';
import { Close } from '../';

export const Drawer: FC<IDrawer> = ({
	className,
	contentClassName,
	children,
	open,
	onCloseClickHandler,
	title,
	expandable = true,
}) => {
	useScrollableSetting(open, document.body);

	const [isWide, setIsWide] = useState(false);

	const onExpandClick = () => {
		setIsWide((prev) => !prev);
	};

	useEffect(() => {
		if (!open) setIsWide(false);
	}, [open]);

	return (
		<AnimatePresence mode="wait">
			<div className={classNames(styles.drawer, className, open ? styles.open : '')}>
				{open && <div className='fixed inset-0 bg-black opacity-20 z-10' onClick={onCloseClickHandler} />}
				<AnimatedScreenWrapper
					isDrawer
					drawerWidth={isWide ? '70%' : '46%'}
					animate={open ? 'in' : 'out'}
					inTransitionDuration={0.4}
					outTransitionDuration={0.4}
					className={classNames(styles['drawer-content'], contentClassName)}
				>
					{title ? <h3 className={styles.title}>{title}</h3> : null}

					{expandable ? (
						<div
							className={classNames(
								styles['expand-tool'],
								isWide ? styles.expanded : styles.minimized,
							)}
							onClick={onExpandClick}
						></div>
					) : null}

					<Close className={styles.close} onClick={onCloseClickHandler} />

					<div className={styles['content-wrap']}>{children}</div>
				</AnimatedScreenWrapper>
			</div>
		</AnimatePresence>
	);
};
