import { FC } from 'react';
import styles from './ModalLanguageQuidance.module.scss';
import { Modal, BasicPanel, Close, Scrollbar } from '../../../primitives';
import IModalLanguageQuidance from './IModalLanguageQuidance';
import parse from 'html-react-parser';

const ModalLanguageQuidance: FC<IModalLanguageQuidance> = ({ open, setOpen, guidance }) => {
	const wordsToBold = [
		'Control Enhancement Statement',
		'Control Statement',
		'Key Elements',
		'Guidance for Implementation',
		'Purpose',
	];

	const transformTextStyling = (text: string) => {
		wordsToBold.forEach((word) => {
			text = text.replace(new RegExp(word, 'g'), `<strong>${word}</strong>`);
		});

		return parse(text);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<Close onClick={() => setOpen(false)} />
				<h2 className={styles.title}>Language Guidance</h2>

				<div className={styles['text-wrap']}>
					<Scrollbar>
						<div className={styles.text}>
							<p>{transformTextStyling(guidance || '')}</p>
						</div>
					</Scrollbar>
				</div>
			</BasicPanel>
		</Modal>
	);
};

export default ModalLanguageQuidance;
