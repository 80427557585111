import { FC, useEffect } from 'react';
import { ISelectOptionsListComponentOverride } from './IOptionsListComponentOverride';
import styles from './OptionsListComponentOverride.module.scss';
import { Scrollbar } from '../../../';
import { ISelectOption } from '../../../../../utils/types';
import classNames from 'classnames';

export const SelectOptionsListComponentOverride: FC<ISelectOptionsListComponentOverride> = ({
	selectId,
	selectedOption,
	options,
	onSelectChangeHandler,
}) => {
	const isGrouped = !!(options && options.length && 'groupName' in options[0]);

	useEffect(() => {
		const selected = document.getElementById(`${selectId}-${selectedOption}` || '');
		if (selected) selected.scrollIntoView({ block: 'nearest' });
	}, [selectId, selectedOption]);

	const renderGroupedItems = () => {
		const groupedOptions = options.reduce((acc: any[], item: ISelectOption) => {
			const group = acc.find((group: any) => group.groupName === item.groupName);

			if (group) group.options.push(item);
			else {
				acc.push({ groupName: item.groupName, options: [item] });
			}

			return acc;
		}, []);

		return groupedOptions.map((item: any) => (
			<div className={styles.group}>
				<div className={styles['group-title']}>{item.groupName}</div>

				<div className={styles.items}>
					{item.options.map((option: ISelectOption) => {
						return (
							<div
								id={`${selectId}-${option.value}`}
								key={option.value}
								className={classNames(
									styles.item,
									selectedOption === option.value ? styles.selected : '',
								)}
								onClick={() => onSelectChangeHandler(option)}
							>
								{option.label}
							</div>
						);
					})}
				</div>
			</div>
		));
	};

	const renderRegularItems = () => {
		const opts = options as ISelectOption[];

		if (opts.length) {
			return opts.map((option: ISelectOption) => (
				<div
					id={`${selectId}-${option.value}`}
					key={option.value}
					className={classNames(
						styles.item,
						selectedOption === option.value ? styles.selected : '',
						option.value === '' ? styles['empty-item'] : '',
					)}
					onClick={() => onSelectChangeHandler(option)}
				>
					{option.label}
				</div>
			));
		}

		return <div className={styles['no-options']}>No options to select</div>;
	};

	return (
		<Scrollbar small>
			<div className={classNames(styles['options-list'], isGrouped ? styles.grouped : '')}>
				{isGrouped ? renderGroupedItems() : renderRegularItems()}
			</div>
		</Scrollbar>
	);
};
