import React, { FC, useEffect, useState } from 'react';

import styles from './Tabs.module.scss';
import { ITabs } from './ITabs';
import { Tab } from '../tab';

export const Tabs: FC<ITabs> = ({ tabs, customActiveTab, tabSideComponent, setCustomActiveTab, ...rest }) => {
	const [activeTab, setActiveTab] = useState(tabs[0].key);
	const isActiveTab = (key: string): boolean => key === activeTab;

	const onTabClick = (tabKey: string) => {
		setActiveTab(tabKey);

		if (setCustomActiveTab) setCustomActiveTab(tabKey);
	};

	const tabsElement = tabs.map(({ text, key }) => (
		<Tab key={key} isActive={isActiveTab(key)} onClick={() => onTabClick(key)}>
			{text}
		</Tab>
	));

	const currentTab = tabs.find((tab) => tab.key === activeTab);
	const componentsElement = currentTab?.component;

	useEffect(() => {
		if (customActiveTab) setActiveTab(customActiveTab);
	}, [customActiveTab]);

	return (
		<div className={styles.tabs} {...rest}>
			<div className='flex items-center justify-between lg:flex-row flex-col lg:mb-0 mb-4'>
				<div className={styles['tabs-wrap']}>
					{tabsElement}
				</div>
				{tabSideComponent}
			</div>

			{componentsElement}
		</div>
	);
};
