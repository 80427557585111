const AUTH = '/auth';
const LOGIN = '/auth/login';
const LOGOUT = '/auth/logout';
const INITIATE_RESET_PASSWORD = '/auth/forgot-password';
const CONFIRM_RESET_PASSWORD = '/auth/reset-password';
const CHANGE_PASSWORD = '/auth/change-password';
const PASSWORD_REQUIREMENTS = '/auth/password-requirements';
const REGISTER_USER = '/auth/register-user';
const USERS = '/users';
const COMPANIES = '/companies';
const COMPANY_POLICIES = '/company-policy';
const BASE_POLICY = '/base-policy';
const FRAMEWORKS = '/frameworks';
const CONTROLS = '/company-controls';
const COMMENTS = '/comments';
const TAGS = '/tags';
const ASSETS = '/assets';
const ASSIGNMENTS = '/tasks';
const ONBOARDING = '/onboarding';
const FILES = '/files';
const CROSSWALK_EVALUATION = '/crosswalk-evaluation';
const HISTORY = '/history';
const TRUST_HUB = '/trust-hub';

const PUBLIC_TRUST_HUB = '/public/trust-hub';
const PUBLIC_COMPANIES = '/public/companies';
const PUBLIC_CONTROLS = '/public/company-controls';

export {
	AUTH,
	LOGIN,
	LOGOUT,
	INITIATE_RESET_PASSWORD,
	USERS,
	CHANGE_PASSWORD,
	CONFIRM_RESET_PASSWORD,
	COMPANY_POLICIES,
	PASSWORD_REQUIREMENTS,
	COMPANIES,
	FRAMEWORKS,
	CONTROLS,
	TAGS,
	ASSETS,
	ASSIGNMENTS,
	REGISTER_USER,
	FILES,
	COMMENTS,
	ONBOARDING,
	BASE_POLICY,
	CROSSWALK_EVALUATION,
	HISTORY,
	TRUST_HUB,
	PUBLIC_TRUST_HUB,
	PUBLIC_COMPANIES,
	PUBLIC_CONTROLS,
};
