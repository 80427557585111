import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { ITrustHub } from "../../../../../services/store/slices/trust-hub.slice";
import { CollapsiblePanel, Input, SectionTitle, Select, Textarea } from "../../../../primitives";
import { TrustHubStatus } from "../../../../../utils/types";
import { TrustHubStatusOptions } from "../../../../../utils/helpers/constants";
import { emailValidationPattern, requireValidationPattern } from "../../../../../utils/helpers/common/form";

export interface EditTrustHubDetailsProps {
  errors: FieldErrors<ITrustHub>;
  getValues: () => Partial<ITrustHub>;
  register: UseFormRegister<Partial<ITrustHub>>;
  setValue: UseFormSetValue<Partial<ITrustHub>>;
  triggerHook: UseFormTrigger<Partial<ITrustHub>>
}

export const EditTrustHubDetails = ({ errors, register, setValue, triggerHook, getValues }: EditTrustHubDetailsProps) => {
  const trigger = <SectionTitle className="!my-0">Details</SectionTitle>;

  return (
    <CollapsiblePanel trigger={trigger}>
      <div className="flex gap-6 pt-6">
        <Select
          id="status"
          onValueChange={(value) => {
            setValue('status', value as TrustHubStatus)
            triggerHook('status');
          }}
          withErrorStyle={!!errors.status}
          error={!!errors.status ? 'Please select status' : ''}
          value={getValues()?.status}
          className="flex-1"
          options={TrustHubStatusOptions}
          label="Status"
        />
        <Input
          {...register('email', {
            ...requireValidationPattern(),
            ...emailValidationPattern(),
          })}
          withErrorStyle={!!errors.email}
          error={!!errors.email ? 'Please enter valid email' : ''}
          className="flex-1"
          type="text"
          label="Email"
          placeholder="Enter email"
        />
      </div>

      <div className="mt-6">
        <Textarea
          {...register('description', {
            ...requireValidationPattern()
          })}
          onValueChange={(value) => setValue('description', value)}
          withErrorStyle={!!errors.description}
          error={!!errors.description ? 'Please enter company description' : ''}
          value={getValues()?.description}
          placeholder="Enter or paste company description here..."
          label="Company Description"
          maxLength={750}
        />
      </div>
    </CollapsiblePanel>
  );
}